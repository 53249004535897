import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { Store } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { saveAs } from "file-saver";
import * as fromPayments from "../../store/Payments";
import * as fromIndex from "../../../utils/main-const";
import { URLS } from "../../../utils/URLS";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"]
})
export class PaymentComponent implements OnInit {
  public french: any;
  pageNumbers: number;
  currentPage: number = 1;
  paymentsState;
  fromIndex;
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    public http: Http,
    private store: Store<{}>
  ) {}

  ngOnInit() {
    this.fromIndex = {
      bootcampId: fromIndex.bootcampId,
      particularSession: fromIndex.particularCourse
    };
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("payments").subscribe(val => {
      this.paymentsState = val;
      this.pageNumbers = Math.ceil(val.payments.totalNbr / 20);
    });
    this.store.select("statistics").subscribe(val => {});
    this.store.dispatch(
      new fromPayments.GetPaymentsListRequest({ page: this.currentPage })
    );
  }
  nextPage() {
    this.currentPage += 1;
    this.store.dispatch(
      new fromPayments.GetPaymentsListRequest({ page: this.currentPage })
    );
  }
  prevPage() {
    this.currentPage -= 1;
    this.store.dispatch(
      new fromPayments.GetPaymentsListRequest({ page: this.currentPage })
    );
  }
  downloadBill(payment) {
    this.httpClient
      .get(URLS.PAYMENTS + "/" + payment.id + "/bill", {
        headers: {
          Authorization: `Bearer ${this.cookieService.get("access_token")}`,
          "Content-Type": "application/pdf"
        },
        responseType: "blob"
      })
      .subscribe(data => {
        saveAs(data, `facture-${payment.nbr}.pdf`);
      });
  }
}
