import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Http } from "@angular/http";
import { NgbModal, NgbDatepickerI18n, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Params, Router } from "@angular/router";
import * as datepickerFormat from "../../shared/datepicker-format"
import * as fromPlanning from "../../store/Planning";
import * as fromClients from "../../store/Clients";
import * as fromIndex from "../../../utils/main-const";
import {
  isEmpty,
  dataURLtoFile,
  reformeDate
} from "../../../utils/main-const";
import { parseFromTimeZone } from "date-fns-timezone";
import { startOfWeek, lastDayOfWeek, addDays } from "date-fns";

@Component({
  selector: "app-coach-client-details",
  templateUrl: "./coach-client-details.component.html",
  styleUrls: ["./coach-client-details.component.scss"],
  providers: [datepickerFormat.I18n, { provide: NgbDatepickerI18n, useClass: datepickerFormat.CustomDatepickerI18n }, { provide: NgbDateParserFormatter, useClass: datepickerFormat.MyFormatter }]
})
export class CoachClientDetailsComponent implements OnInit {
  startBefore = startOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }).toISOString()
  endBefore = addDays(lastDayOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }), 1).toISOString()

  selectedClient;
  french;
  id;
  planningState;
  clientsState;
  startSchedule = "07:00";
  endSchedule = "22:00";
  startDate = "";
  validateParticularSessionShedule: boolean = false;
  coachId;
  clientToUpdate = {
    lname: "",
    fname: "",
    birthDate: {},
    phoneNumber: "",
    email: ""
  };
  userId;
  isEmptyAddress;

  constructor(
    private modalService: NgbModal,
    public http: Http,
    private route: ActivatedRoute,
    private store: Store<{}>
  ) {
    this.store.select("auth").subscribe(val => {
      this.coachId = val.loggedInUser.id;
    });
    this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    this.store.dispatch(new fromClients.initializeErrorMessage());
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.dispatch(new fromClients.GetUserDetailsRequest({ userId: this.id }))
    this.store.select("clients").subscribe(val => {
      this.selectedClient = val.userDetails
    });
    this.store.select("planning").subscribe(val => {
      this.planningState = val;
    });
    if (this.selectedClient.user) {
      if (isEmpty(this.selectedClient.user.address)) {
        this.isEmptyAddress = true
      }
      else {
        this.isEmptyAddress = false
      }
    }
    this.store.dispatch(new fromPlanning.initializeErrorMessage());
  }
  onChangeEndShedule() {
    if (Number(this.endSchedule.substr(0, 2)) - Number(this.startSchedule.substr(0, 2)) == 1) {
      this.validateParticularSessionShedule = true
    }
    else {
      this.validateParticularSessionShedule = false
    }
  }
  getParticularSessionCredit(credits) {
    return credits.filter(
      credit => credit.sessionTypeId == fromIndex.particularCourse
    );
  }
  openUpdateForm(content) {

    var dateObj = new Date(this.selectedClient.user.birthDate);
    var month = dateObj.getMonth() + 1; //months from 1-12
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();

    var birthDate = { year, month, day };
    this.clientToUpdate = {
      ...this.selectedClient.user,
      birthDate
    };
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.store.dispatch(new fromClients.initializeErrorMessage());
        },
        reason => {
          this.store.dispatch(new fromClients.initializeErrorMessage());
        }
      );
  }

  onSubmitUpdate($event) {
    var profileImage;
    if ($event.imageUrl != undefined) {
      profileImage = dataURLtoFile($event.imageUrl, "a.png");
    }
    $event.updateForm.value.birthDate.day = reformeDate(
      $event.updateForm.value.birthDate.day
    );
    $event.updateForm.value.birthDate.month = reformeDate(
      $event.updateForm.value.birthDate.month
    );
    $event.updateForm.value.birthDate =
      new Date($event.updateForm.value.birthDate.year +
        "-" +
        $event.updateForm.value.birthDate.month +
        "-" +
        $event.updateForm.value.birthDate.day).toISOString()

    if ($event.imageUrl != undefined) {
      this.store.dispatch(
        new fromClients.updateClientRequest({
          data: {
            ...$event.updateForm.value,
            profileImage,
            zipCode: $event.address.zipCode,
            street: $event.address.street,
            city: $event.address.city,
          },
          userId: this.selectedClient.user.id
        })
      );
    } else {
      this.store.dispatch(
        new fromClients.updateClientRequest({
          data: {
            ...$event.updateForm.value,
            zipCode: $event.address.zipCode,
            street: $event.address.street,
            city: $event.address.city,
          },
          userId: this.selectedClient.user.id
        })
      );
    }
  }
  openAddCours(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.store.dispatch(new fromPlanning.initializeErrorMessage());
          this.startSchedule = "07:00";
          this.endSchedule = "22:00";
        },
        reason => {
          this.store.dispatch(new fromPlanning.initializeErrorMessage());
          this.startSchedule = "07:00";
          this.endSchedule = "22:00";
        }
      );
  }
  onSubmitaddCours(form) {
    let usersIds = [this.id]
    if (form.value.startDate.month < 10) {
      form.value.startDate.month = "0" + form.value.startDate.month;
    }
    if (form.value.startDate.day < 10) {
      form.value.startDate.day = "0" + form.value.startDate.day;
    }
    const starts = parseFromTimeZone(
      `${form.value.startDate.year}-${form.value.startDate.month}-${
      form.value.startDate.day
      } ${form.value.startSchedule}:00`,
      { timeZone: "Europe/Paris" }
    ).toISOString();
    const ends = parseFromTimeZone(
      `${form.value.startDate.year}-${form.value.startDate.month}-${
      form.value.startDate.day
      } ${form.value.endSchedule}:00`,
      { timeZone: "Europe/Paris" }
    ).toISOString();
    this.store.dispatch(
      new fromPlanning.createSessionRequest({
        sessionTypeId: fromIndex.particularCourse,
        value: { coachId: this.coachId, starts, ends },
        usersIds,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }
}
