import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { Store } from "@ngrx/store";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as fromClients from "../../store/Clients";
import { isMobileDevice, dataURLtoFile } from "../../../utils/main-const";

@Component({
  selector: "app-coachs",
  templateUrl: "./coachs.component.html",
  styleUrls: ["./coachs.component.scss"]
})
export class CoachsComponent implements OnInit {
  isMobile: boolean;
  public french: any;
  clientsState;
  searchText = "";
  fileExisted: boolean = false;
  imageUrl = "";
  fileToUpload;
  userId;
  clientToUpdate = {
    lname: "",
    fname: "",
    birthDate: {},
    phoneNumber: "",
    email: ""
  };
  constructor(
    private modalService: NgbModal,
    public http: Http,
    private store: Store<{ clients: fromClients.State }>
  ) { }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.isMobile = isMobileDevice();
    this.store.dispatch(new fromClients.initializeErrorMessage());
    this.store.select("clients").subscribe(val => (this.clientsState = val));
    this.store.dispatch(new fromClients.GetCoachListRequest({}));
  }
  searchByText() {
    this.store.dispatch(new fromClients.GetCoachListRequest({ name: this.searchText }));
  }
  open(content) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      windowClass: "custom-modal"
    }).result.then(
      result => {
        this.store.dispatch(new fromClients.initializeErrorMessage());
      },
      reason => {
        this.store.dispatch(new fromClients.initializeErrorMessage());
      }
    );
  }

  uploadFile = () => {
    document.getElementById("file").click();
  };

  handleFileInput(files: FileList) {
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = e => {
      this.fileExisted = true;
      var image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = 200;
        canvas.height = 200;
        image.width = 200;
        image.height = 200;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, 200, 200);
        this.imageUrl = canvas.toDataURL("image/jpeg", 0.6);
      };
    };
    reader.readAsDataURL(files[0]);
  }

  dataURLtoFile(imageUrl, filename) {
    var arr = imageUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  takePhoto() {
    document.getElementById("camera").click();
  }
  onSubmit(form) {
    if (this.fileExisted) {
      var profileImage = this.dataURLtoFile(this.imageUrl, "a.png");
    }
    if (this.fileExisted) {
      this.store.dispatch(
        new fromClients.AddClientRequest({
          value: {
            ...form.value,
            profileImage,
            role: "coach"
          }
        })
      );
    } else {
      this.store.dispatch(
        new fromClients.AddClientRequest({
          value: { ...form.value, role: "coach" }
        })
      );
    }
  }

  openDeleteCoach(content, id) {
    this.userId = id;
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      windowClass: "custom-modal"
    });
  }
  deleteCoach() {
    this.store.dispatch(
      new fromClients.deleteClientRequest({ userId: this.userId, role: "coach" })
    );
  }

  openUpdateForm(content, coach) {
    this.userId = coach.id;
    var dateObj = new Date(coach.birthDate);
    var month = dateObj.getMonth() + 1; //months from 1-12
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();

    var birthDate = { year, month, day };
    this.clientToUpdate = {
      ...coach,
      // phoneNumber: coach.phoneNumber.length != 0 ? coach.phoneNumber[0] : "",
      birthDate
    };

    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      windowClass: "custom-modal"
    });
  }
  onSubmitUpdate($event) {
    var profileImage;
    if ($event.imageUrl != undefined) {
      profileImage = dataURLtoFile($event.imageUrl, "a.png");
    }
    // $event.updateForm.value.phoneNumber = $event.updateForm.value.phoneNumber;
    // if ($event.updateForm.value.birthDate.month < 10) {
    //   $event.updateForm.value.birthDate.month =
    //     "0" + $event.updateForm.value.birthDate.month;
    // }
    // if ($event.updateForm.value.birthDate.day < 10) {
    //   $event.updateForm.value.birthDate.day =
    //     "0" + $event.updateForm.value.birthDate.day;
    // }
    // $event.updateForm.value.birthDate =
    //   $event.updateForm.value.birthDate.year +
    //   "-" +
    //   $event.updateForm.value.birthDate.month +
    //   "-" +
    //   $event.updateForm.value.birthDate.day;

    if ($event.imageUrl != undefined) {
      this.store.dispatch(
        new fromClients.updateClientRequest({
          data: {
            ...$event.updateForm.value,
            profileImage
          },
          userId: this.userId,
          role: "coach"
        })
      );
    } else {
      this.store.dispatch(
        new fromClients.updateClientRequest({
          data: $event.updateForm.value,
          userId: this.userId,
          role: "coach"
        })
      );
    }
  }
}
