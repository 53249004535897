import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";

import { Store } from "@ngrx/store";
import * as fromClients from "../../store/Clients";
import * as fromIndex from "../../../utils/main-const";
import * as fromStatistics from "../../store/Statistics"

@Component({
  selector: "app-assistant-dashboard",
  templateUrl: "./assistant-dashboard.component.html",
  styleUrls: ["./assistant-dashboard.component.scss"]
})
export class AssistantDashboardComponent implements OnInit {
  public french: any;
  searchText = "";
  clientsState;
  statisticsState;

  constructor(public http: Http, private store: Store<{}>) { }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("clients").subscribe(val => (this.clientsState = val));
    this.store.select("statistics").subscribe(val => (this.statisticsState = val));

    this.store.dispatch(new fromStatistics.getStaticsRequest());
    this.store.dispatch(
      new fromClients.GetClientListInDayRequest({
        sessionTypeId: fromIndex.particularCourse
      })
    );
  }
}
