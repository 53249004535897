import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Http } from "@angular/http";

@Component({
  selector: "app-crad-payment",
  templateUrl: "./crad-payment.component.html",
  styleUrls: ["./crad-payment.component.scss"]
})
export class CradPaymentComponent implements OnInit {
  @Input() visitor: boolean;
  @Input() validateButtonDisabled: boolean = true;
  @Output() submitForm: EventEmitter<any> = new EventEmitter();
  @Output() closeForm: EventEmitter<any> = new EventEmitter();
  getTokenCardError = "kil";
  cardNumberValue;
  expirationDateValue;
  french;
  paymentsState;
  constructor(private store: Store<{}>, public http: Http) {}

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("payments").subscribe(val => {
      this.paymentsState = val;
    });
  }
  onChangeCardNumber(val) {
    let character = val.split("-").join("");
    if (character.length > 0) {
      character = character.match(new RegExp(".{1,4}", "g")).join("-");
    }

    this.cardNumberValue = character;
  }
  onChangeExpirationDate(val) {
    let character = val.split("/").join("");
    if (character.length > 0) {
      character = character.match(new RegExp(".{1,2}", "g")).join("/");
    }

    this.expirationDateValue = character;
  }
  onCancel() {
    this.closeForm.emit();
  }
  onSubmit(f) {
    this.submitForm.emit(f);
  }
}
