import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromClients from "../../store/Clients"
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-become-member",
  templateUrl: "./become-member.component.html",
  styleUrls: ["./become-member.component.css"]
})
export class BecomeMemberComponent implements OnInit {
  constructor(private store: Store<{}>) { }

  ngOnInit() { }
  onSubmitInformation($event) {
    this.store.dispatch(new fromClients.contactAdminsRequest($event.form.value))
  }
}
