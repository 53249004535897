import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Http } from "@angular/http";

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  public french: any;

  constructor(
    public http: Http,
  ) { }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
  }
  clickDelete() {
    this.delete.emit()
  }
  closeModal() {
    this.close.emit()
  }
}
