import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Http } from "@angular/http";
import { Store } from "@ngrx/store";

import * as fromIndex from "../../../utils/main-const";
import * as fromClients from "../../store/Clients";
import * as fromStatistics from "../../store/Statistics"

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class BoardTableComponent implements OnInit {
  public selectClients: string = "an-animate";
  public selectPayments: string = "an-animate";
  public french: any;
  clientsState;
  onLinePayment;
  onSpotPayment;
  searchText = "";
  selectedPaymentType = "En Ligne";
  statisticsState
  constructor(public http: Http, private store: Store<{}>) { }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("clients").subscribe(val => (this.clientsState = val));
    this.store.select("statistics").subscribe(val => (this.statisticsState = val));

    this.store.select("payments").subscribe(val => {
      this.onLinePayment = val.stripePayments;
      this.onSpotPayment = val.cashPaymentsList.concat(val.chequePaymentsList);
    });
    this.store.dispatch(new fromStatistics.getStaticsRequest())
    this.store.dispatch(
      new fromClients.GetClientListInDayRequest({
        sessionTypeId: fromIndex.particularCourse
      })
    );
  }
}
