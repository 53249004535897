import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Http } from "@angular/http";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromAuth from "../store/Auth";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  public french: any;
  loginErrorCode;
  loginForm: FormGroup;
  userType: string;
  constructor(
    public http: Http,
    private store: Store<{ auth: fromAuth.State }>,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromAuth.ReinitializeErrorCode());
    this.route.data.subscribe(v => (this.userType = v.userType));
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store
      .select("auth")
      .subscribe(val => (this.loginErrorCode = val.loginErrorCode));
  }

  onSubmit(f) {
    this.store.dispatch(
      new fromAuth.loginRequest({
        email: f.value.email,
        password: f.value.password,
        role: this.userType
      })
    );
  }
}
