// Session Id
export const bootcampId = "536f710fc55b2acc61000bc9",
  particularCourse = "536f710fc55b2acc61000bc1";



// is mobile function
export function isMobileDevice() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

// convert data url to file
export function dataURLtoFile(imageUrl, filename) {
  var arr = imageUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// reform date
export function reformeDate(date) {
  if (date < 10 && ("0" + date).length < 3) {
    return "0" + date;
  } else return date;
}

// check if object is empty
export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}