import { LOCALE_ID, Inject } from "@angular/core";
import {
  CalendarEventTitleFormatter,
  CalendarEvent,
  DateFormatterParams
} from "angular-calendar";
import { DatePipe, registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";

registerLocaleData(localeFr);

export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: "string") {
    super();
  }

  // you can override any of the methods defined in the parent class

  start;
  end;
  weekTooltip(event: CalendarEvent): string {
    return;
  }
  week(event: CalendarEvent): string {
    this.start = `${new DatePipe(this.locale).transform(
      event.start,
      "HH:mm",
      this.locale
    )}`;
    this.end = `${new DatePipe(this.locale).transform(
      event.end,
      "HH:mm",
      this.locale
    )}<br> ${event.title} `;
    return this.start + " - " + this.end;
  }
}
