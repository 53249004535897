const BASE_URL = "https://la-source-back.herokuapp.com/v1/";

const LOGIN = BASE_URL + "auth/login",
  REGISTER = BASE_URL + "auth/register",
  REFRESH_TOKEN = BASE_URL + "auth/refresh-token",
  CHECK_TOKEN = BASE_URL + "auth/check-token",
  UPDATE_PASSWORD = BASE_URL + "auth/update-password",
  RESET_PASSWORD = BASE_URL + "auth/reset-password",
  CHECK_EMAIL = BASE_URL + "auth/check-email",
  USERS = BASE_URL + "users",
  SLOTS = BASE_URL + "slots",
  SESSIONS = BASE_URL + "sessions",
  PAYMENTS = BASE_URL + "payments",
  THEMES = BASE_URL + "themes",
  STATISTICS = BASE_URL + "statistics",
  PACKS = BASE_URL + "packs",
  CONTACT = BASE_URL + "contact";
export const URLS = {
  LOGIN,
  REFRESH_TOKEN,
  CHECK_TOKEN,
  UPDATE_PASSWORD,
  RESET_PASSWORD,
  CHECK_EMAIL,
  USERS,
  SLOTS,
  SESSIONS,
  PAYMENTS,
  THEMES,
  STATISTICS,
  REGISTER,
  CONTACT,
  PACKS
};
