import { Injectable } from "@angular/core";

const ADMIN_MENUITEMS = [
  {
    name: "Tableau de bord",
    icon: "home.png",
    iconActive: "home-active.svg",
    route: "/admin/dashboard/boardtable"
  },
  {
    name: "Clients",
    icon: "clients.svg",
    iconActive: "clients-active.svg",
    route: "/admin/dashboard/clients"
  },
  {
    name: "Coachs",
    icon: "coachs.svg",
    iconActive: "coachs-active.svg",
    route: "/admin/dashboard/coachs"
  },
  {
    name: "Bootcamp",
    icon: "planning.svg",
    iconActive: "planning-active.svg",
    route: "/admin/dashboard/bootcamp"
  },
  {
    name: "Paiement",
    icon: "paiement.svg",
    iconActive: "paiement-active.svg",
    route: "/admin/dashboard/payment"
  },
  {
    name: "Planning",
    icon: "planning.svg",
    iconActive: "planning-active.svg",
    route: "/admin/dashboard/planning"
  }
];
const ASSISTANT_MENUITEMS = [
  {
    name: "Tableau de bord",
    icon: "home.png",
    iconActive: "home-active.svg",
    route: "/assistant/dashboard/boardtable"
  },
  {
    name: "Clients",
    icon: "clients.svg",
    iconActive: "clients-active.svg",
    route: "/assistant/dashboard/clients"
  },
  {
    name: "Paiement",
    icon: "paiement.svg",
    iconActive: "paiement-active.svg",
    route: "/assistant/dashboard/payment"
  },
  {
    name: "Planning Bootcamp",
    icon: "planning.svg",
    iconActive: "planning-active.svg",
    route: "/assistant/dashboard/bootcamp"
  }
];
const COACH_MENUITEMS = [
  {
    name: "Mon planning",
    icon: "planning.svg",
    iconActive: "planning-active.svg",
    route: "/coach/myplanning"
  },
  {
    name: "Clients",
    icon: "clients.svg",
    iconActive: "clients-active.svg",
    route: "/coach/clients"
  }
];
const MEMBER_MENUITEMS = [
  {
    name: "Tableau de bord",
    icon: "home.png",
    iconActive: "home-active.svg",
    route: "/member/boardtable"
  },
  {
    name: "Planning",
    icon: "planning.svg",
    iconActive: "planning-active.svg",
    route: "/member/planning"
  },
  {
    name: "Réserver bootcamp",
    icon: "planning.svg",
    iconActive: "planning-active.svg",
    route: "/member/planningBootcamp"
  }
];
const VISITOR_MENUITEMS = [
  {
    name: "Tableau de bord",
    icon: "home.png",
    iconActive: "home-active.svg",
    route: "/visitor/boardtable"
  },
  {
    name: "Planning bootcamp",
    icon: "planning.svg",
    iconActive: "planning-active.svg",
    route: "/visitor/planningBootcamp"
  }
];
@Injectable()
export class MenuItems {
  getAdminMenuItems() {
    return ADMIN_MENUITEMS;
  }
  getAssistantMenuItems() {
    return ASSISTANT_MENUITEMS;
  }
  getCoachMenuItems() {
    return COACH_MENUITEMS;
  }
  getMemberMenuItems() {
    return MEMBER_MENUITEMS;
  }
  getVisitorMenuOtems() {
    return VISITOR_MENUITEMS
  }
}
