import { Action } from "@ngrx/store";

export const LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAIL = "LOGIN_FAIL",
  LOGOUT = "LOGOUT",
  FINISH_LOGOUT = "FINISH_LOGOUT",
  REINITIALITE_ERROR_CODE = "REINITIALITE_ERROR_CODE",
  RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL",
  UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL",
  CHECK_TOKEN_REQUEST = "CHECK_TOKEN_REQUEST",
  CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS",
  CHECK_TOKEN_FAIL = "CHECK_TOKEN_FAIL",
  CHECK_EMAIL_REQUEST = "CHECK_EMAIL_REQUEST",
  CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS",
  CHECK_EMAIL_FAIL = "CHECK_EMAIL_FAIL";

// reinitialize error
export class ReinitializeErrorCode implements Action {
  readonly type = REINITIALITE_ERROR_CODE;
}
// check email
export class checkEmailRequest implements Action {
  readonly type = CHECK_EMAIL_REQUEST;
  constructor(public payload) {}
}
export class checkEmailSuccess implements Action {
  readonly type = CHECK_EMAIL_SUCCESS;
  constructor(public payload) {}
}
export class checkEmailFail implements Action {
  readonly type = CHECK_EMAIL_FAIL;
  constructor(public payload) {}
}
//  check token
export class checkTokenRequest implements Action {
  readonly type = CHECK_TOKEN_REQUEST;
  constructor(public payload) {}
}
export class checkTokenSuccess implements Action {
  readonly type = CHECK_TOKEN_SUCCESS;
  constructor(public payload) {}
}
export class checkTokenFail implements Action {
  readonly type = CHECK_TOKEN_FAIL;
  constructor(public payload) {}
}

// update password
export class updatePasswordRequest implements Action {
  readonly type = UPDATE_PASSWORD_REQUEST;
  constructor(public payload) {}
}
export class updatePasswordSuccess implements Action {
  readonly type = UPDATE_PASSWORD_SUCCESS;
}
export class updatePasswordFail implements Action {
  readonly type = UPDATE_PASSWORD_FAIL;
  constructor(public payload) {}
}
// reset password
export class resetPasswordRequest implements Action {
  readonly type = RESET_PASSWORD_REQUEST;
  constructor(public payload) {}
}
export class resetPasswordSuccess implements Action {
  readonly type = RESET_PASSWORD_SUCCESS;
}
export class resetPasswordFail implements Action {
  readonly type = RESET_PASSWORD_FAIL;
  constructor(public payload) {}
}
// login
export class loginRequest implements Action {
  readonly type = LOGIN_REQUEST;

  constructor(
    public payload: { email: string; password: string; role: string }
  ) {}
}
export class loginsuccess implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(public payload) {}
}

export class loginFail implements Action {
  readonly type = LOGIN_FAIL;

  constructor(public payload) {}
}
export class Logout implements Action {
  readonly type = LOGOUT;
  constructor(public payload) {}
}
export class FinishLogout implements Action {
  readonly type = FINISH_LOGOUT;
  constructor(public payload) {}
}

export interface State {
  loginErrorCode: number;
  loggedInUser: any;
  isLoggedIn: boolean;
  updatePasswordProgress: string;
  updatePasswordError: any;
  resetPasswordProgress: string;
  resetPasswordError: any;
  checkTokenProgress: string;
  checkTokenError: any;
  checkedClient;
  checkEmailProgress: string;
  checkEmailError: any;
  checkEmailResponse: any;
}

const initialState: State = {
  loginErrorCode: -1,
  loggedInUser: {},
  isLoggedIn: false,
  updatePasswordError: -1,
  updatePasswordProgress: "",
  resetPasswordProgress: "",
  resetPasswordError: -1,
  checkTokenProgress: "",
  checkTokenError: -1,
  checkedClient: {},
  checkEmailProgress: "",
  checkEmailError: -1,
  checkEmailResponse: -1
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_EMAIL_REQUEST:
      return {
        ...state,
        checkEmailProgress: "loading"
      };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        checkEmailProgress: "ok",
        checkEmailResponse: action.payload.exists
      };
    case CHECK_EMAIL_FAIL:
      return {
        ...state,
        checkEmailError: action.payload.error,
        checkEmailProgress: "error"
      };
    case CHECK_TOKEN_REQUEST:
      return {
        ...state,
        checkTokenProgress: "loading"
      };
    case CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        checkTokenProgress: "ok",
        checkedClient: action.payload.checkedClient
      };
    case CHECK_TOKEN_FAIL:
      return {
        ...state,
        checkTokenError: action.payload.error,
        checkTokenProgress: "error"
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordProgress: "loading"
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordProgress: "ok"
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordError: action.payload.error,
        resetPasswordProgress: "error"
      };
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        updatePasswordProgress: "loading"
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordProgress: "ok"
      };
    case UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        updatePasswordError: action.payload.error,
        updatePasswordProgress: "error"
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loginErrorCode: 505
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginErrorCode: 200,
        loggedInUser: action.payload.user,
        isLoggedIn: true
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loginErrorCode: action.payload.error
      };
    case REINITIALITE_ERROR_CODE:
      return {
        ...state,
        loginErrorCode: -1,
        updatePasswordError: -1,
        updatePasswordProgress: "",
        resetPasswordProgress: "",
        resetPasswordError: -1,
        checkTokenProgress: "",
        checkTokenError: -1,
        checkEmailError: -1,
        checkEmailResponse: -1
      };
    case FINISH_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export type authActions = loginRequest | loginFail | Logout;
