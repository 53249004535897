import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Http } from "@angular/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Params, Router } from "@angular/router";
import * as fromClients from "../../store/Clients";
import * as fromPayments from "../../store/Payments";
import * as fromAuth from "../../store/Auth";
import { isEmpty, dataURLtoFile } from "../../../utils/main-const";
import * as fromIndex from "../../../utils/main-const";

@Component({
  selector: "app-client-details",
  templateUrl: "./client-details.component.html",
  styleUrls: ["./client-details.component.scss"]
})
export class ClientDetailsComponent implements OnInit {
  public french: any;
  pageNumbers: number;
  currentPage: number = 1;
  defaultpaymentMode = "cash";
  defaultPack;
  // packSelected = false;
  authState;
  clientsState;
  paymentsState;
  fileToUpload;
  fileExisted: boolean = false;
  imageUrl = "";
  particularPacks;
  id: string;
  selectedClient;
  packId;
  userId;
  fromIndex;
  clientToUpdate = {
    lname: "",
    fname: "",
    birthDate: {},
    phoneNumber: "",
    email: ""
  };
  role;
  isEmptyAddress;
  constructor(
    private modalService: NgbModal,
    public http: Http,
    private route: ActivatedRoute,
    private store: Store<{}>
  ) {
    this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    this.fromIndex = fromIndex;
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("auth").subscribe(val => {
      this.role = val.loggedInUser.role;
      this.authState = val;
    });
    this.store.dispatch(new fromAuth.ReinitializeErrorCode());
    this.store.dispatch(
      new fromClients.GetUserDetailsRequest({ userId: this.id })
    );
    this.store.dispatch(new fromPayments.GetPacksListRequest());
    this.store.dispatch(
      new fromPayments.GetPaymentsListRequest({
        userId: this.id,
        page: this.currentPage
      })
    );
    this.store.dispatch(new fromPayments.initializeErrorMessage());
    this.store.select("payments").subscribe(val => {
      this.pageNumbers = Math.ceil(val.payments.totalNbr / 20);
      this.paymentsState = val;
      this.particularPacks = val.packsList.filter(
        pack => pack.sessionType.id != fromIndex.bootcampId
      );
    });
    this.store.select("clients").subscribe(val => {
      this.selectedClient = val.userDetails;
      if (val.userDetails.user) {
        if (isEmpty(val.userDetails.user.address)) {
          this.isEmptyAddress = true;
        } else {
          this.isEmptyAddress = false;
        }
      }
    });
  }
  nextPage() {
    this.currentPage += 1;
    this.store.dispatch(
      new fromPayments.GetPaymentsListRequest({
        userId: this.id,
        page: this.currentPage
      })
    );
  }
  prevPage() {
    this.currentPage -= 1;
    this.store.dispatch(
      new fromPayments.GetPaymentsListRequest({
        userId: this.id,
        page: this.currentPage
      })
    );
  }
  getParticularSessionCredit(credits) {
    return credits.filter(
      credit => credit.sessionTypeId == fromIndex.particularCourse
    );
  }
  selectPack(pack) {
    this.defaultPack = this.particularPacks.find(function(element) {
      return element.id == pack;
    });
  }
  open(content) {
    this.defaultPack = this.particularPacks[0];
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.store.dispatch(new fromPayments.initializeErrorMessage());
        },
        reason => {
          this.store.dispatch(new fromPayments.initializeErrorMessage());
        }
      );
  }

  openUpdateForm(content) {
    this.userId = this.selectedClient.user.id;
    var dateObj = new Date(this.selectedClient.user.birthDate);
    var month = dateObj.getMonth() + 1; //months from 1-12
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();

    var birthDate = { year, month, day };
    this.clientToUpdate = {
      ...this.selectedClient.user,
      birthDate
    };
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      windowClass: "custom-modal"
    });
  }
  openResendMail(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.store.dispatch(new fromAuth.ReinitializeErrorCode());
        },
        reason => {
          this.store.dispatch(new fromAuth.ReinitializeErrorCode());
        }
      );
  }
  sendMail() {
    this.store.dispatch(
      new fromAuth.resetPasswordRequest({
        value: { email: this.selectedClient.user.email }
      })
    );
  }
  onSubmitUpdate($event) {
    $event.updateForm.value.birthDate.day = fromIndex.reformeDate(
      $event.updateForm.value.birthDate.day
    );
    $event.updateForm.value.birthDate.month = fromIndex.reformeDate(
      $event.updateForm.value.birthDate.month
    );
    let coachIds = [];
    var profileImage;
    if ($event.imageUrl != undefined) {
      profileImage = dataURLtoFile($event.imageUrl, "a.png");
    }
    $event.updateForm.value.birthDate = new Date(
      $event.updateForm.value.birthDate.year +
        "-" +
        $event.updateForm.value.birthDate.month +
        "-" +
        $event.updateForm.value.birthDate.day
    ).toISOString();
    for (let i = 0; i < $event.coachsSelected.length; i++) {
      coachIds.push($event.coachsSelected[i].id);
    }
    if ($event.imageUrl != undefined) {
      this.store.dispatch(
        new fromClients.updateClientRequest({
          data: {
            ...$event.updateForm.value,
            profileImage,
            zipCode: $event.address.zipCode,
            street: $event.address.street,
            city: $event.address.city,
            coachIds
          },
          userId: this.userId
        })
      );
    } else {
      this.store.dispatch(
        new fromClients.updateClientRequest({
          data: {
            ...$event.updateForm.value,
            zipCode: $event.address.zipCode,
            street: $event.address.street,
            city: $event.address.city,
            coachIds
          },
          userId: this.userId
        })
      );
    }
  }

  onSubmit(form) {
    this.store.dispatch(
      new fromPayments.createManualPaymentRequest({
        value: {
          ...form.value,
          userId: this.id
        },
        page: this.currentPage
      })
    );
  }
}
