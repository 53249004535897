import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromAuth from "./store/Auth";
import { SwUpdate } from "@angular/service-worker";

import { from } from "rxjs";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title;
  constructor(
    private store: Store<{ auth: fromAuth.State }>,
    updates: SwUpdate
  ) {
    updates.available.subscribe(event => {
      //this.update = true;
      updates.activateUpdate().then(() => document.location.reload());
    });
  }
  ngOnInit() {}
}
