import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Http } from "@angular/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { parseFromTimeZone } from "date-fns-timezone";
import { startOfWeek, lastDayOfWeek, addDays } from "date-fns";
import * as fromPlanning from "../../store/Planning";
import * as fromClients from "../../store/Clients";
import * as fromIndex from "../../../utils/main-const";

@Component({
  selector: "app-admin-planning",
  templateUrl: "./admin-planning.component.html",
  styleUrls: ["./admin-planning.component.scss"]
})
export class AdminPlanningComponent implements OnInit {
  public selectProperty: string = "an-animate";
  fenceForm: FormGroup;
  french;
  clients = ["r", "f", "t"];
  clientsState;
  selectedCoach;
  coachId;
  events;
  startBefore = startOfWeek(
    parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }),
    { weekStartsOn: 1 }
  ).toISOString();
  endBefore = addDays(
    lastDayOfWeek(
      parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }),
      { weekStartsOn: 1 }
    ),
    1
  ).toISOString();
  coachs;
  slotId;
  sessionId;
  popupContent: string = "list";
  selectedBootcamp;
  clientsList;
  particularCoursPopupContent: string = "consult";
  healthChecked = false;
  healthCheckbox = false;
  courseStart;
  courseEnd;
  status;
  constructor(
    private store: Store<{}>,
    private modalService: NgbModal,
    public http: Http
  ) {
    this.store.dispatch(new fromClients.GetCoachListRequest({}));
    this.store.dispatch(
      new fromPlanning.changeStartEndDates({
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.dispatch(new fromPlanning.getThemesListRequest());
    this.store.select("planning").subscribe(val => {
      // this.events = val.planningList;
      this.events = val.slots;
      this.startBefore = val.startBefore;
      this.endBefore = val.endBefore;
    });
    this.store.select("clients").subscribe(val => {
      this.coachs = val.coachs;
      this.clientsState = val;
      this.selectedCoach = val.coachs[0];
    });
  }
  ngAfterViewInit() {
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        coachId: this.selectedCoach.id,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }
  getParticularSessionCredit(credits) {
    return credits.filter(
      credit => credit.sessionTypeId == fromIndex.particularCourse
    );
  }
  openDeleteSlot(content, $event) {
    this.slotId = $event.id;
    this.coachId = $event.coachId;
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      windowClass: "custom-modal"
    });
  }
  deleteSlot() {
    this.store.dispatch(
      new fromPlanning.deleteSessionRequest({
        sessionId: this.slotId,
        coachId: this.coachId,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }
  initForm(clients) {
    let bootcampClientsList = new FormArray([]);

    for (let i = 0; i < clients.length; i++) {
      bootcampClientsList.push(
        new FormGroup({
          presence: new FormControl(true)
        })
      );
    }
    this.fenceForm = new FormGroup({
      clients: bootcampClientsList
    });
  }

  togglehealthCheckbox(e) {
    this.healthChecked = e.target.checked;
  }
  openParticularCours(content, $event) {
    this.courseStart = $event.start;
    this.courseEnd = $event.end;
    this.clientsList = $event.users;
    this.sessionId = $event.id;
    this.coachId = $event.coachId;
    this.status = $event.status;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.particularCoursPopupContent = "consult";
          this.healthCheckbox = false;
          this.healthChecked = false;
        },
        reason => {
          this.particularCoursPopupContent = "consult";
          this.healthCheckbox = false;
          this.healthChecked = false;
        }
      );
  }
  delete(sessionId) {
    this.store.dispatch(
      new fromPlanning.cancelSessionRequest({
        sessionId,
        coachId: this.coachId,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }
  closeParticularSessionBack() {
    this.particularCoursPopupContent = "consult";
  }
  fenceParticularCours() {
    this.particularCoursPopupContent = "cloture";
  }
  openlistClients(content, $event) {
    this.sessionId = $event.id;
    this.initForm($event.users);
    this.clientsList = $event.users;
    this.selectedBootcamp = $event;
    this.coachId = $event.coachId;

    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.popupContent = "list";
        },
        reason => {
          this.popupContent = "list";
        }
      );
  }
  onSubmitParticularCoursCloture(form) {
    let healthInfo;
    let data = {
      sessionRecap: form.value.recap,
      presences: [
        {
          userId: this.clientsList[0].user.id,
          presence: form.value.presence,
          healthInfo: {
            weight: form.value.weight,
            height: form.value.height,
            IMC: form.value.IMC,
            fatMass: form.value.fatMass,
            muscularMass: form.value.muscularMass,
            abdominalFatMassIndex: form.value.abdominalFatMassIndex,
            hydrationRate: form.value.hydrationRate
          }
        }
      ]
    };

    for (const key of Object.keys(data.presences[0].healthInfo)) {
      if (
        data.presences[0].healthInfo[key] != "" &&
        data.presences[0].healthInfo[key] != undefined
      ) {
        healthInfo = {
          ...healthInfo,
          [key]: data.presences[0].healthInfo[key]
        };
      }
    }

    if (form.value.recap != "" && healthInfo != undefined) {
      this.store.dispatch(
        new fromPlanning.closeSessionRequest({
          slotId: this.sessionId,
          coachId: this.coachId,
          data: {
            sessionRecap: form.value.recap,
            presences: [
              {
                userId: this.clientsList[0].user.id,
                presence: form.value.presence,
                healthInfo
              }
            ]
          },
          startBefore: this.startBefore,
          endBefore: this.endBefore
        })
      );
    } else if (form.value.recap == "" && healthInfo != undefined) {
      this.store.dispatch(
        new fromPlanning.closeSessionRequest({
          slotId: this.sessionId,
          coachId: this.coachId,
          data: {
            presences: [
              {
                userId: this.clientsList[0].user.id,
                presence: form.value.presence,
                healthInfo
              }
            ]
          },
          startBefore: this.startBefore,
          endBefore: this.endBefore
        })
      );
    } else if (form.value.recap != "" && healthInfo == undefined) {
      this.store.dispatch(
        new fromPlanning.closeSessionRequest({
          slotId: this.sessionId,
          coachId: this.coachId,
          data: {
            sessionRecap: form.value.recap,
            presences: [
              {
                userId: this.clientsList[0].user.id,
                presence: form.value.presence
              }
            ]
          },
          startBefore: this.startBefore,
          endBefore: this.endBefore
        })
      );
    } else if (form.value.recap == "" && healthInfo == undefined) {
      this.store.dispatch(
        new fromPlanning.closeSessionRequest({
          slotId: this.sessionId,
          coachId: this.coachId,
          data: {
            presences: [
              {
                userId: this.clientsList[0].user.id,
                presence: form.value.presence
              }
            ]
          },
          startBefore: this.startBefore,
          endBefore: this.endBefore
        })
      );
    }
    // let healthInfo;
    // let data = {
    //   sessionRecap: form.value.recap,
    //   presences: [
    //     {
    //       userId: this.clientsList[0].id,
    //       presence: form.value.presence,
    //       healthInfo: {
    //         weight: form.value.weight,
    //         height: form.value.height,
    //         IMC: form.value.IMC,
    //         fatMass: form.value.fatMass,
    //         muscularMass: form.value.muscularMass,
    //         abdominalFatMassIndex: form.value.abdominalFatMassIndex,
    //         hydrationRate: form.value.hydrationRate
    //       }
    //     }
    //   ]
    // };
    // for (const key of Object.keys(data.presences[0].healthInfo)) {
    //   if (data.presences[0].healthInfo[key] != "" && data.presences[0].healthInfo[key] != undefined) {
    //     healthInfo = { ...healthInfo, [key]: data.presences[0].healthInfo[key] }
    //   }
    // }

    // if (form.value.recap != "" && healthInfo != undefined) {
    //   this.store.dispatch(
    //     new fromPlanning.closeSessionRequest({
    //       slotId: this.sessionId,
    //       coachId: this.coachId,
    //       data: {
    //         sessionRecap: form.value.recap,
    //         presences: [
    //           {
    //             userId: this.clientsList[0].id,
    //             presence: form.value.presence,
    //             healthInfo
    //           }
    //         ]
    //       },
    //       startBefore: this.startBefore,
    //       endBefore: this.endBefore
    //     })
    //   );
    // }
    // else if (form.value.recap == "" && healthInfo != undefined) {
    //   this.store.dispatch(
    //     new fromPlanning.closeSessionRequest({
    //       slotId: this.sessionId,
    //       coachId: this.coachId,
    //       data: {
    //         presences: [
    //           {
    //             userId: this.clientsList[0].id,
    //             presence: form.value.presence,
    //             healthInfo
    //           }
    //         ]
    //       },
    //       startBefore: this.startBefore,
    //       endBefore: this.endBefore
    //     })
    //   );
    // }
    // else if (form.value.recap != "" && healthInfo == undefined) {
    //   this.store.dispatch(
    //     new fromPlanning.closeSessionRequest({
    //       slotId: this.sessionId,
    //       coachId: this.coachId,
    //       data: {
    //         sessionRecap: form.value.recap,
    //         presences: [
    //           {
    //             userId: this.clientsList[0].id,
    //             presence: form.value.presence,
    //           }
    //         ]
    //       },
    //       startBefore: this.startBefore,
    //       endBefore: this.endBefore
    //     })
    //   );
    // }
    // else if (form.value.recap == "" && healthInfo == undefined) {
    //   this.store.dispatch(
    //     new fromPlanning.closeSessionRequest({
    //       slotId: this.sessionId,
    //       coachId: this.coachId,
    //       data: {
    //         presences: [
    //           {
    //             userId: this.clientsList[0].id,
    //             presence: form.value.presence,
    //           }
    //         ]
    //       },
    //       startBefore: this.startBefore,
    //       endBefore: this.endBefore
    //     })
    //   );
    // }
  }
  closeBootcampBack() {
    this.popupContent = "list";
  }
  fence() {
    this.popupContent = "cloture";
  }
  //   onSubmitCloture() {
  //     let presences = []
  //     for (let i = 0; i < this.clientsList.length; i++) {
  //       presences.push({ ...this.fenceForm.value.clients[i], userId: this.clientsList[i].id })
  //     }
  //     this.store.dispatch(
  //       new fromPlanning.closeSessionRequest({
  //         slotId: this.sessionId,
  //         coachId: this.coachId,
  //         data: {
  //           presences
  //         },
  //         startBefore: this.startBefore,
  //         endBefore: this.endBefore
  //       })
  //     );
  //   }
  onSubmitCloture() {
    let presences = [];

    for (let i = 0; i < this.clientsList.length; i++) {
      presences.push({
        ...this.fenceForm.value.clients[i],
        userId: this.clientsList[i].user.id
      });
    }
    this.store.dispatch(
      new fromPlanning.closeSessionRequest({
        slotId: this.sessionId,
        coachId: this.coachId,
        data: {
          presences
        },
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }
}
