import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Http } from "@angular/http";
import { parseFromTimeZone } from "date-fns-timezone";
import { startOfWeek, lastDayOfWeek, addDays } from "date-fns";
import * as fromPlanning from "../../store/Planning";
import * as fromIndex from "../../../utils/main-const";

@Component({
  selector: "app-assistant-bootcamp",
  templateUrl: "./assistant-bootcamp.component.html",
  styleUrls: ["./assistant-bootcamp.component.scss"]
})
export class AssistantBootcampComponent implements OnInit {
  events;
  selectedBootcamp;
  french;
  startBefore = startOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }).toISOString()
  endBefore = addDays(lastDayOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }), 1).toISOString()

  constructor(
    public http: Http,
    private store: Store<{}>,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.dispatch(new fromPlanning.getThemesListRequest())
    this.store.select("planning").subscribe(val => {
      // this.events = val.bootcampList;
      this.events = val.slots;
    });
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        sessionTypeId: fromIndex.bootcampId,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }
  changeWeek($event) {

    let values = {
      startBefore: parseFromTimeZone($event.startBefore,
        { timeZone: "Europe/Paris" }
      ).toISOString(),
      endBefore: parseFromTimeZone($event.endBefore,
        { timeZone: "Europe/Paris" }
      ).toISOString()
    }
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        sessionTypeId: fromIndex.bootcampId,
        startBefore: values.startBefore,
        endBefore: values.endBefore
      })
    );

  }
  clickBootcamp(content, $event) {
    this.selectedBootcamp = $event;

    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      windowClass: "custom-modal"
    });
  }
}
