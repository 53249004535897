import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { NgbModal, NgbDatepickerI18n, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import * as fromClients from "../../store/Clients";
import { isMobileDevice } from "../../../utils/main-const";
import * as fromIndex from "../../../utils/main-const";
import * as datepickerFormat from "../../shared/datepicker-format"

@Component({
  selector: "app-coach-clients",
  templateUrl: "./coach-clients.component.html",
  styleUrls: ["./coach-clients.component.scss"],
  providers: [datepickerFormat.I18n, { provide: NgbDatepickerI18n, useClass: datepickerFormat.CustomDatepickerI18n }, { provide: NgbDateParserFormatter, useClass: datepickerFormat.MyFormatter }]
})
export class CoachClientsComponent implements OnInit {
  public french: any;
  pageNumbers: number;
  currentPage: number = 1;
  fileExisted: boolean = false;
  isMobile: boolean;
  addClientStep = "first";
  imageUrl = "";
  searchText = "";
  clientsState;
  planningState;
  userId;
  coachId;
  fromIndex;
  options = { types: ['address'], componentRestrictions: { country: 'fr' } };
  address = '';
  zipCode;
  street;
  city
  constructor(
    private modalService: NgbModal,
    public http: Http,
    private store: Store<{}>
  ) {
    this.store.select("auth").subscribe(val => {
      this.coachId = val.loggedInUser.id;
    });
    this.store.select("planning").subscribe(val => {
      this.planningState = val;
    });
  }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.isMobile = isMobileDevice();
    this.store.select("statistics").subscribe(val => {
      this.pageNumbers = Math.ceil(val.statistics.clientsNbr / 20)
    });
    this.store.select("clients").subscribe(val => (this.clientsState = val));
    this.store.dispatch(new fromClients.GetClientListRequest({ page: this.currentPage, name: this.searchText }));
    this.store.dispatch(new fromClients.initializeErrorMessage());
    this.store.dispatch(new fromClients.ReinitializeUserDetails())
  }
  searchByText() {
    this.currentPage = 1;
    this.store.dispatch(new fromClients.GetClientListRequest({ page: this.currentPage, name: this.searchText }));
  }
  nextPage() {
    this.currentPage += 1
    this.store.dispatch(new fromClients.GetClientListRequest({ page: this.currentPage, name: this.searchText }));
  }
  prevPage() {
    this.currentPage -= 1
    this.store.dispatch(new fromClients.GetClientListRequest({ page: this.currentPage, name: this.searchText }));
  }
  handleAddressChange(address: Address) {
    let zipCode = address.address_components.filter(
      address => address.types[0] == "postal_code"
    );
    let streetNumber = address.address_components.filter(
      address => address.types[0] == "street_number"
    );
    let route = address.address_components.filter(
      address => address.types[0] == "route"
    );
    let city = address.address_components.filter(
      address => address.types[0] == "locality"
    );
    // this.address = address.formatted_address
    // this.zipCode = zipCode[0].long_name;
    // this.street = streetNumber[0].long_name + " " + route[0].long_name;
    // this.city = city[0].long_name

    this.zipCode = (zipCode.length != 0 && zipCode[0].long_name) ? zipCode[0].long_name : "";
    this.street = ((streetNumber.length != 0 && streetNumber[0].long_name) ? streetNumber[0].long_name : "") +
      " " + (route.length != 0 && route[0].long_name ? route[0].long_name : "");
    this.city = (city.length != 0 && city[0].long_name) ? city[0].long_name : ""
  }
  getParticularSessionCredit(credits) {
    return credits.filter(
      credit => credit.sessionTypeId == fromIndex.particularCourse
    );
  }
  open(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.addClientStep = "first";
          this.fileExisted = false;
          this.store.dispatch(new fromClients.initializeErrorMessage());
        },
        reason => {
          this.addClientStep = "first";
          this.store.dispatch(new fromClients.initializeErrorMessage());
          this.fileExisted = false;
        }
      );
  }
  uploadFile = () => {
    document.getElementById("file").click();
  };

  handleFileInput(files: FileList) {
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = e => {
      this.fileExisted = true;
      var image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = 200;
        canvas.height = 200;
        image.width = 200;
        image.height = 200;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, 200, 200);
        this.imageUrl = canvas.toDataURL("image/jpeg", 0.6);
      };
    };
    reader.readAsDataURL(files[0]);
  }

  dataURLtoFile(imageUrl, filename) {
    var arr = imageUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  takePhoto() {
    document.getElementById("camera").click();
  }
  nextStep() {
    this.addClientStep = "second";
  }
  addClientBack() {
    this.addClientStep = "first";
  }
  onSubmit(form) {
    if (this.fileExisted) {
      var profileImage = this.dataURLtoFile(this.imageUrl, "a.png");
    }
    // let birthDate = (form.value.birthDate =
    //   form.value.birthDate.year +
    //   "-" +
    //   form.value.birthDate.month +
    //   "-" +
    //   form.value.birthDate.day);
    form.value.birthDate.day = fromIndex.reformeDate(form.value.birthDate.day);
    form.value.birthDate.month = fromIndex.reformeDate(form.value.birthDate.month);
    form.value.birthDate =
      new Date(form.value.birthDate.year +
        "-" +
        form.value.birthDate.month +
        "-" +
        form.value.birthDate.day).toISOString()

    if (this.fileExisted) {
      this.store.dispatch(
        new fromClients.AddClientRequest({
          value: {
            ...form.value,
            zipCode: this.zipCode,
            street: this.street,
            city: this.city,
            profileImage
          },
          page: this.currentPage
        })
      );
    } else {
      this.store.dispatch(
        new fromClients.AddClientRequest({
          value: {
            ...form.value,
            zipCode: this.zipCode,
            street: this.street,
            city: this.city
          },
          page: this.currentPage
        })
      );
    }
  }
}
