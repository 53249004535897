import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import * as fromPlanning from "../../store/Planning";
import * as fromIndex from "../../../utils/main-const";
import * as fromClients from "../../store/Clients";

@Component({
  selector: "app-visitor-dashboard",
  templateUrl: "./visitor-dashboard.component.html",
  styleUrls: ["./visitor-dashboard.component.scss"]
})
export class VisitorDashboardComponent implements OnInit {
  french;
  finishedBootcampList;
  bookedBootcampList;
  themes;
  id;
  userDetails;

  constructor(
    private store: Store<{}>,
    public http: Http,
    private router: Router
  ) {
    this.store.select("auth").subscribe(val => {
      this.id = val.loggedInUser.id;
    });
    this.store.dispatch(new fromPlanning.getThemesListRequest());
  }
  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.dispatch(
      new fromClients.GetUserDetailsRequest({ userId: this.id })
    );
    this.store.dispatch(
      new fromPlanning.getSessionsSubscriptionsListRequest({
        userId: this.id
      })
    );
    this.store.select("clients").subscribe(val => {
      this.userDetails = val.userDetails;
    });
    this.store.select("planning").subscribe(val => {
      // this.finishedBootcampList = val.finishedBootcampList;
      // this.bookedBootcampList = val.bookedBootcampList;
      this.finishedBootcampList = val.slots.filter(
        slot => slot.session.status == "finished"
      );
      this.bookedBootcampList = val.slots.filter(
        slot => slot.session.status == "booked"
      );
      this.themes = val.themes;
    });
    // this.store.dispatch(
    //   new fromPlanning.getFinishedBootcampRequest({
    //     sessionTypeId: fromIndex.bootcampId
    //   })
    // );
    // this.store.dispatch(
    //   new fromPlanning.getBookedBootcampRequest({
    //     sessionTypeId: fromIndex.bootcampId
    //   })
    // );
  }
  bookBootcamp() {
    this.router.navigate(["visitor/planningBootcamp"]);
  }
  getThemeImage(theme) {
    return this.themes.filter(element => element.name == theme)[0] &&
      this.themes.filter(element => element.name == theme)[0].img
      ? this.themes.filter(element => element.name == theme)[0].img
      : "";
  }
}
