import { Action } from "@ngrx/store";

// INITIALIZE ERROR MESSAGE
export const INITIALIZE_ERROR_MESSAGE = "INITIALIZE_ERROR_MESSAGE";
export class initializeErrorMessage implements Action {
  type = INITIALIZE_ERROR_MESSAGE;
}
// GET PAYMENTS LIST
export const GET_PAYMENTS_LIST_REQUEST = "GET_PAYMENTS_LIST_REQUEST",
  GET_PAYMENTS_LIST_SUCCESS = "GET_PAYMENTS_LIST_SUCCESS",
  GET_PAYMENTS_LIST_FAIL = "GET_PAYMENTS_LIST_FAIL";

export class GetPaymentsListRequest implements Action {
  readonly type = GET_PAYMENTS_LIST_REQUEST;
  constructor(public payload) { }
}

export class GetPaymentsListSuccess implements Action {
  readonly type = GET_PAYMENTS_LIST_SUCCESS;
  constructor(public payload) { }
}

export class GetPaymentsListFail implements Action {
  readonly type = GET_PAYMENTS_LIST_FAIL;
  constructor(public payload) { }
}
// GET CASH PAYMENTS LIST
export const GET_CASH_PAYMENTS_LIST_SUCCESS = "GET_CASH_PAYMENTS_LIST_SUCCESS";
export class getCashPaymentsListSuccess implements Action {
  readonly type = GET_CASH_PAYMENTS_LIST_SUCCESS;
  constructor(public payload) { }
}
// GET CHEQUE PAYMENTS LIST
export const GET_CHEQUE_PAYMENTS_LIST_SUCCESS =
  "GET_CHEQUE_PAYMENTS_LIST_SUCCESS";
export class getChequePaymentsListSuccess implements Action {
  readonly type = GET_CHEQUE_PAYMENTS_LIST_SUCCESS;
  constructor(public payload) { }
}
// GET STRIPE PAYMENTS LIST
export const GET_STRIPE_PAYMENTS_LIST_SUCCESS =
  "GET_STRIPE_PAYMENTS_LIST_SUCCESS";
export class getStripePaymentsListSuccess implements Action {
  readonly type = GET_STRIPE_PAYMENTS_LIST_SUCCESS;
  constructor(public payload) { }
}
// CREATE MANUAL PAYMENT
export const CREATE_MANUAL_PAYMENT_REQUEST = "CREATE_MANUAL_PAYMENT_REQUEST",
  CREATE_MANUAL_PAYMENT_SUCCESS = "CREATE_MANUAL_PAYMENT_SUCCESS",
  CREATE_MANUAL_PAYMENT_FAIL = "CREATE_MANUAL_PAYMENT_FAIL";

export class createManualPaymentRequest implements Action {
  readonly type = CREATE_MANUAL_PAYMENT_REQUEST;
  constructor(public payload) { }
}

export class createManualPaymentSuccess implements Action {
  readonly type = CREATE_MANUAL_PAYMENT_SUCCESS;
}

export class createManualPaymentFail implements Action {
  readonly type = CREATE_MANUAL_PAYMENT_FAIL;
  constructor(public payload) { }
}

// CREATE STRIPE PAYMENT
export const CREATE_STRIPE_PAYMENT_REQUEST = "CREATE_STRIPE_PAYMENT_REQUEST",
  CREATE_STRIPE_PAYMENT_SUCCESS = "CREATE_STRIPE_PAYMENT_SUCCESS",
  CREATE_STRIPE_PAYMENT_FAIL = "CREATE_STRIPE_PAYMENT_FAIL";

export class createStripePaymentRequest implements Action {
  readonly type = CREATE_STRIPE_PAYMENT_REQUEST;
  constructor(public payload) { }
}

export class createStripePaymentSuccess implements Action {
  readonly type = CREATE_STRIPE_PAYMENT_SUCCESS;
}

export class createStripePaymentFail implements Action {
  readonly type = CREATE_STRIPE_PAYMENT_FAIL;
  constructor(public payload) { }
}
// CHANGE STRIPE TOKEN ERROR
export const CHANGE_STRIPE_TOKEN_ERROR = "CHANGE_STRIPE_TOKEN_ERROR";
export class changeStripeTokenError implements Action {
  readonly type = CHANGE_STRIPE_TOKEN_ERROR;
  constructor(public payload) { }
}
// GET PACKS LIST
export const GET_PACKS_LIST_REQUEST = "GET_PACKS_LIST_REQUEST",
  GET_PACKS_LIST_SUCCESS = "GET_PACKS_LIST_SUCCESS",
  GET_PACKS_LIST_FAIL = "GET_PACKS_LIST_FAIL";

export class GetPacksListRequest implements Action {
  readonly type = GET_PACKS_LIST_REQUEST;
}

export class GetPacksListSuccess implements Action {
  readonly type = GET_PACKS_LIST_SUCCESS;
  constructor(public payload) { }
}

export class GetPacksListFail implements Action {
  readonly type = GET_PACKS_LIST_FAIL;
  constructor(public payload) { }
}
export interface State {
  payments: Array<object>;
  getPaymentsListErrorCode: number;
  getPaymentsListProgress: string;
  createManualPaymentProgress: string;
  createManualPaymentError;
  createStripePaymentProgress: string;
  getTokenCardError;
  createStripePaymentError;
  cashPaymentsList;
  chequePaymentsList;
  stripePayments;
  getPacksListProgress;
  getPacksListError;
  packsList;
}

const initialState: State = {
  payments: [],
  getPaymentsListErrorCode: -1,
  getPaymentsListProgress: "",
  createManualPaymentProgress: "",
  createManualPaymentError: -1,
  createStripePaymentProgress: "",
  createStripePaymentError: -1,
  getTokenCardError: {},
  cashPaymentsList: [],
  chequePaymentsList: [],
  stripePayments: [],
  getPacksListProgress: "",
  getPacksListError: -1,
  packsList: []
};

export function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_ERROR_MESSAGE:
      return {
        ...state,
        getPaymentsListErrorCode: -1,
        getPaymentsListProgress: "",
        createManualPaymentProgress: "",
        createManualPaymentError: -1,
        createStripePaymentProgress: "",
        createStripePaymentError: -1,
        getPacksListError: -1,
        getPacksListProgress: "",
        getTokenCardError: {}
      }
    // GET PAYMENTS LIST
    case GET_PAYMENTS_LIST_REQUEST:
      return {
        ...state,
        getPaymentsListProgress: "loading"
      };
    case GET_PAYMENTS_LIST_SUCCESS:
      return {
        ...state,
        getPaymentsListProgress: "ok",
        payments: action.payload.payments
      };
    case GET_PAYMENTS_LIST_FAIL:
      return {
        ...state,
        getPaymentsListErrorCode: action.payload.error,
        getPaymentsListProgress: "error"
      };
    // GET CASH PAYMENTS LIST
    case GET_CASH_PAYMENTS_LIST_SUCCESS:
      return {
        ...state,
        cashPaymentsList: action.payload.payments
      };
    // GET CHEQUE PAYMENTS LIST
    case GET_CHEQUE_PAYMENTS_LIST_SUCCESS:
      return {
        ...state,
        chequePaymentsList: action.payload.payments
      };
    // GET STRIPE PAYMENTS LIST
    case GET_STRIPE_PAYMENTS_LIST_SUCCESS:
      return {
        ...state,
        stripePayments: action.payload.payments
      };
    // CREATE MANUAL PAYMENT
    case CREATE_MANUAL_PAYMENT_REQUEST:
      return {
        ...state,
        createManualPaymentProgress: "loading"
      };
    case CREATE_MANUAL_PAYMENT_SUCCESS:
      return {
        ...state,
        createManualPaymentProgress: "ok"
      };
    case CREATE_MANUAL_PAYMENT_FAIL:
      return {
        ...state,
        createManualPaymentError: action.payload.error,
        createManualPaymentProgress: "error"
      };
    // CREATE STRIPE PAYMENT
    case CREATE_STRIPE_PAYMENT_REQUEST:
      return {
        ...state,
        createStripePaymentProgress: "loading"
      };
    case CREATE_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        createStripePaymentProgress: "ok"
      };
    case CREATE_STRIPE_PAYMENT_FAIL:
      return {
        ...state,
        createStripePaymentError: action.payload.error,
        createStripePaymentProgress: "error"
      };
    // CHANGE ERROR TOKEN
    case CHANGE_STRIPE_TOKEN_ERROR:
      return {
        ...state,
        getTokenCardError: action.payload.getTokenCardError
      }
    // GET PACKS LIST
    case GET_PACKS_LIST_REQUEST:
      return {
        ...state,
        getPacksListProgress: "loading"
      };
    case GET_PACKS_LIST_SUCCESS:
      return {
        ...state,
        getPacksListProgress: "ok",
        packsList: action.payload.packsList
      };
    case GET_PACKS_LIST_FAIL:
      return {
        ...state,
        getPacksListError: action.payload.error,
        getPacksListProgress: "error"
      };
    default:
      return state;
  }
}
