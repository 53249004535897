import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Http } from "@angular/http";
import { of } from "rxjs/observable/of";
import { Actions, Effect } from "@ngrx/effects";
import "rxjs/add/operator/map";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/mergeMap";
import { Store } from "@ngrx/store";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from 'ngx-toastr';
import { convertToTimeZone } from "date-fns-timezone";

import { URLS } from "../../utils/URLS";
import * as clientsActions from "../store/Clients";
import * as fromUI from "../store/UI";
import * as fromMuscules from "../store/Muscles";
import * as statitisticsActions from "../store/Statistics"

interface getList {
  data: any;
}
@Injectable()
export class ClientsEffects {
  french;
  token = this.cookieService.get("access_token");
  constructor(
    public http: Http,
    public toastr: ToastrService,
    private modalService: NgbModal,
    private actions$: Actions,
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private store: Store<{ clients: clientsActions.State }>
  ) {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
  }

  //get list all clients
  @Effect()
  GetClientsList = this.actions$
    .ofType(clientsActions.GET_CLIENT_LIST_REQUEST)
    .map((action: clientsActions.GetClientListRequest) => action.payload)
    .switchMap(payload => {
      var url = URLS.USERS + "?perPage=20&page=" + payload.page;
      if (payload.coachId) {
        url = url + "&coachId=" + payload.coachId;
      }
      if (payload.role) {
        url += "&role=user";
      }
      if (payload.name) {
        url += "&name=" + payload.name
      }
      return this.httpClient
        .get<getList>(url)
        .map(response => {
          return new clientsActions.GetClientListSuccess({
            clients: response.data
          });
        })
        .catch(error =>
          of(new clientsActions.GetClientListFail({ error: error.error }))
        );
    });
  //get clients in day list
  @Effect()
  GetClientsListInDay = this.actions$
    .ofType(clientsActions.GET_CLIENT_LIST_IN_DAY_REQUEST)
    .map((action: clientsActions.GetClientListInDayRequest) => action.payload)
    .switchMap(payload => {
      var url =
        URLS.STATISTICS + "/users-by-date?sessionTypeId=" + payload.sessionTypeId;
      return this.httpClient
        .get<getList>(url)
        .map(response => {
          return new clientsActions.GetClientListInDaySuccess({
            clients: response.data
          });
        })
        .catch(error =>
          of(new clientsActions.GetClientListInDayFail({ error: error.error }))
        );
    });
  //get coachs list
  @Effect()
  GetCoachsList = this.actions$
    .ofType(clientsActions.GET_COACH_LIST_REQUEST)
    .map((action: clientsActions.GetCoachListRequest) => action.payload)
    .switchMap((payload) => {
      let url = URLS.USERS + "?role=coach"
      if (payload.name) {
        url += "&name=" + payload.name
      }
      return this.httpClient
        .get<getList>(url)
        .map(response => {
          return new clientsActions.GetCoachListSuccess({
            coachs: response.data
          });
        })
        .catch(error =>
          of(new clientsActions.GetCoachListFail({ error: error.error }))
        );
    });
  //get user details
  @Effect()
  GetUserDetails = this.actions$
    .ofType(clientsActions.GET_USER_DETAILS_REQUEST)
    .map((action: clientsActions.GetUserDetailsRequest) => action.payload)
    .switchMap((payload) => {
      return this.httpClient
        .get<getList>(URLS.USERS + "/" + payload.userId)
        .map(response => {
          // muscularMass.forEach((element, i) => {
          //   let e =[element.createdAt,element.muscularMass] ;
          //   muscularMass[i] = e;
          // });
          if (response.data.healthInfo) {
            this.store.dispatch(new fromMuscules.dispatchChartData({ chartData: response.data.healthInfo }))
          }
          if (response.data.nextSession) {
            response.data = {
              ...response.data, nextSession: {
                ...response.data.nextSession,
                starts: convertToTimeZone(new Date(response.data.nextSession.starts), {
                  timeZone: "Europe/Paris"
                }) //response.data.nextSession.starts.substr(0, 19) 
              }
            }
          }
          return new clientsActions.GetUserDetailsSuccess({
            userDetails: response.data
          });
        })
        .catch(error =>
          of(new clientsActions.GetUserDetailsFail({ error: error.error }))
        );
    });
  //add client
  @Effect()
  AddClient = this.actions$
    .ofType(clientsActions.ADD_CLIENT_REQUEST)
    .map((action: clientsActions.AddClientRequest) => action.payload)
    .switchMap(payload => {
      var formData = new FormData();

      formData.append("email", payload.value.email);
      formData.append("fname", payload.value.fname);
      formData.append("lname", payload.value.lname);
      formData.append("phoneNumber", payload.value.phoneNumber);
      if (payload.value.zipCode && payload.value.zipCode != "" && payload.value.zipCode != "") {
        formData.append("zipCode", payload.value.zipCode);
      }
      if (payload.value.street && payload.value.street != "" && payload.value.street != undefined) {
        formData.append("street", payload.value.street);
      }
      if (payload.value.city && payload.value.city != "" && payload.value.city != undefined) {
        formData.append("city", payload.value.city);
      }
      if (payload.value.password) {
        formData.append("password", payload.value.password)
      }
      if (payload.value.profileImage) {
        formData.append("profileImage", payload.value.profileImage);
      }
      if (payload.value.role) {
        formData.append("role", payload.value.role);
      }
      if (payload.value.birthDate) {
        formData.append("birthDate", payload.value.birthDate);
      }
      if (payload.value.healthSupplements != "" && payload.value.healthSupplements != undefined) {
        formData.append("healthSupplements", payload.value.healthSupplements);
      }
      if (payload.value.goals != "" && payload.value.goals != undefined) {
        formData.append("goals", payload.value.goals);
      }
      if (payload.value.coachAdvises != "" && payload.value.coachAdvises != undefined) {
        formData.append("coachAdvises", payload.value.coachAdvises);
      }
      if (payload.value.coachIds && payload.value.coachIds.length != 0) {
        formData.append("coachIds", JSON.stringify(payload.value.coachIds));
      }
      return this.httpClient
        .post<getList>(URLS.USERS, formData)
        .map(() => {
          if (payload.coachId) {
            this.store.dispatch(
              new clientsActions.GetClientListRequest({
                coachId: payload.coachId,
                page: payload.page
              })
            );
          } else if (payload.value.role) {
            this.store.dispatch(new clientsActions.GetCoachListRequest({}));

          }
          else {
            this.store.dispatch(new clientsActions.GetClientListRequest({ page: payload.page }));
          }

          this.store.dispatch(new statitisticsActions.getStaticsRequest())

          this.modalService.dismissAll();
          if (payload.value.role) {
            this.toastr.success(this.french.successAddCoach);
          }
          else {
            this.toastr.success(this.french.successAddClient);
          }

          return new clientsActions.AddClientSuccess();
        })
        .catch(error =>
          of(new clientsActions.AddClientFail({ error: error.error }))
        );
    });

  //register visitor
  @Effect()
  RegisterVisitor = this.actions$
    .ofType(clientsActions.REGISTER_VISITOR_REQUEST)
    .map((action: clientsActions.registerVisitorRequest) => action.payload)
    .switchMap(payload => {
      this.store.dispatch(new clientsActions.initializeErrorMessage())
      return this.httpClient
        .post<getList>(URLS.REGISTER, payload)
        .map((response) => {
          this.cookieService.set(
            "access_token",
            response.data.token.accessToken,
            new Date().getDate() + 7,
            "/"
          );
          this.store.dispatch(
            new fromUI.changeReserveBootcampStepVisitor({
              reserveBootcampStepVisitor: "payment"
            })
          );
          return new clientsActions.registerVisitorSuccess({ registerAccessToken: response.data.token.accessToken });
        })
        .catch(error =>
          of(new clientsActions.registerVisitorFail({ error: error.error }))
        );
    });
  // contact admins
  @Effect()
  ContactAdmins = this.actions$
    .ofType(clientsActions.CONTACT_ADMINS_REQUEST)
    .map((action: clientsActions.contactAdminsRequest) => action.payload)
    .switchMap(payload => {

      return this.httpClient
        .post<getList>(URLS.CONTACT, payload)
        .map(() => {
          return new clientsActions.contactAdminsSuccess();
        })
        .catch(error =>
          of(new clientsActions.contactAdminsFail({ error: error.error }))
        );
    });
  // contact news letters
  @Effect()
  ContactNewsLetters = this.actions$
    .ofType(clientsActions.CONTACT_NEWS_LETTERS_REQUEST)
    .map((action: clientsActions.contactNewsLettersRequest) => action.payload)
    .switchMap(payload => {

      return this.httpClient
        .post<getList>(URLS.CONTACT + "/subscribe-to-news-letters", payload)
        .map(() => {
          return new clientsActions.contactNewsLettersSuccess();
        })
        .catch(error =>
          of(new clientsActions.contactNewsLettersFail({ error: error.error }))
        );
    });
  // delete client
  @Effect()
  DeleteClient = this.actions$
    .ofType(clientsActions.DELETE_CLIENT_REQUEST)
    .map((action: clientsActions.deleteClientRequest) => action.payload)
    .switchMap(data => {
      return this.httpClient
        .delete(URLS.USERS + "/" + data.userId)
        .map(() => {


          this.modalService.dismissAll();
          if (data.role) {
            this.store.dispatch(new clientsActions.GetCoachListRequest({}));
            this.toastr.success(this.french.successDeleteCoach);
          }
          else {
            this.store.dispatch(new clientsActions.GetClientListRequest({ page: data.page }));
            this.toastr.success(this.french.successDeleteClient);
          }
          this.store.dispatch(new statitisticsActions.getStaticsRequest())
          return new clientsActions.deleteClientSuccess();
        })
        .catch(error =>
          of(new clientsActions.deleteClientFail({ error: error.error }))
        );
    });
  // update client
  @Effect()
  UpdateClient = this.actions$
    .ofType(clientsActions.UPDATE_CLIENT_REQUEST)
    .map((action: clientsActions.updateClientRequest) => action.payload)
    .switchMap(payload => {
      var formData = new FormData();


      if (payload.data.email != "" && payload.data.email != undefined) {
        formData.append("email", payload.data.email);
      }
      if (payload.data.password != "" && payload.data.password != undefined) {
        formData.append("password", payload.data.password)
      }
      if (payload.data.fname != "" && payload.data.fname != undefined) {
        formData.append("fname", payload.data.fname);
      }

      if (payload.data.lname != "" && payload.data.lname != undefined) {
        formData.append("lname", payload.data.lname);
      }

      if (payload.data.phoneNumber != "" && payload.data.phoneNumber != undefined) {
        formData.append("phoneNumber", payload.data.phoneNumber);
      }

      if (payload.data.zipCode && payload.data.zipCode != "" && payload.data.zipCode != undefined) {
        formData.append("zipCode", payload.data.zipCode);
      }

      if (payload.data.street && payload.data.street != "" && payload.data.street != undefined) {
        formData.append("street", payload.data.street);
      }

      if (payload.data.city && payload.data.city != "" && payload.data.city != undefined) {
        formData.append("city", payload.data.city);
      }
      if (payload.data.profileImage) {
        formData.append("profileImage", payload.data.profileImage);
      }
      if (payload.data.birthDate) {
        formData.append(
          "birthDate", payload.data.birthDate
        );
      }
      if (payload.data.healthSupplements != "" && payload.data.healthSupplements != undefined) {
        formData.append("healthSupplements", payload.data.healthSupplements);
      }
      if (payload.data.goals != "" && payload.data.goals != undefined) {
        formData.append("goals", payload.data.goals);
      }
      if (payload.data.coachAdvises != "" && payload.data.coachAdvises != undefined) {
        formData.append("coachAdvises", payload.data.coachAdvises);
      }
      if (payload.data.coachIds && payload.data.coachIds.length != 0) {
        formData.append("coachIds", JSON.stringify(payload.data.coachIds));
      }
      return this.httpClient
        .patch<getList>(URLS.USERS + "/" + payload.userId, formData)
        .map(() => {
          if (payload.coachId) {
            this.store.dispatch(
              new clientsActions.GetClientListRequest({
                coachId: payload.coachId,
                page: payload.page
              })
            );
          } else if (payload.role) {
            this.store.dispatch(new clientsActions.GetCoachListRequest({}));
            this.modalService.dismissAll();
            this.toastr.success(this.french.successUpdateCoach);
          }
          else {
            // this.store.dispatch(new clientsActions.GetClientListRequest({ page: payload.page }));
            this.modalService.dismissAll();
            this.toastr.success(this.french.successUpdateClient);
            this.store.dispatch(new clientsActions.GetUserDetailsRequest({ userId: payload.userId }))
          }

          return new clientsActions.updateClientSuccess();
        })
        .catch(error =>
          of(new clientsActions.updateClientFail({ error: error.error }))
        );
    });
  //get users number
  @Effect()
  GetUsersNumber = this.actions$
    .ofType(clientsActions.GET_USERS_NUMBER_REQUEST)
    .switchMap(() => {
      return this.httpClient
        .get<getList>(URLS.USERS + "/count")
        .map(response => {
          return new clientsActions.GetUsersNumberSuccess({
            usersNumber: response.data
          });
        })
        .catch(error => of(new clientsActions.GetUsersNumberFail({ error: error.error })));
    });
  @Effect()
  GetCoachsNumber = this.actions$
    .ofType(clientsActions.GET_COACH_NUMBER_REQUEST)
    .switchMap(() => {
      return this.httpClient
        .get<getList>(URLS.USERS + "/count?role=coach")
        .map(response => {
          return new clientsActions.GetCoachNumberSuccess({
            coachsNumber: response.data
          });
        })
        .catch(error => of(new clientsActions.GetCoachNumberFail({ error: error.error })));
    });
}
