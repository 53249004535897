import { ActionReducerMap } from "@ngrx/store";

import * as fromAuth from "./Auth";
import * as fromClients from "./Clients";
import * as fromMuscles from "./Muscles";
import * as fromPlanning from "./Planning";
import * as fromPayments from "./Payments";
import * as fromUI from "./UI";
import * as fromStatistics from "./Statistics"

export interface AppState {
  auth: fromAuth.State;
  clients: fromClients.State;
  muscles: fromMuscles.State;
  planning: fromPlanning.State;
  payments: fromPayments.State;
  UI: fromUI.State;
  statistics: fromStatistics.State
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  clients: fromClients.clientsReducer,
  muscles: fromMuscles.musclesReducer,
  planning: fromPlanning.planningReducer,
  payments: fromPayments.paymentsReducer,
  UI: fromUI.UIReducer,
  statistics: fromStatistics.statisticsReducer
};
