import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { of } from "rxjs/observable/of";
import { Actions, Effect } from "@ngrx/effects";
import "rxjs/add/operator/map";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/mergeMap";

import { URLS } from "../../utils/URLS";
import * as statisticsActions from "../store/Statistics";

interface getList {
    data: any;
}

@Injectable()
export class StatisticsEffects {
    constructor(
        private actions$: Actions,
        private httpClient: HttpClient,
    ) { }

    //get list slots
    @Effect()
    GetStatistics = this.actions$
        .ofType(statisticsActions.GET_STATISTICS_REQUEST)
        .switchMap(payload => {
            return this.httpClient
                .get<getList>(URLS.STATISTICS)
                .map(response => {

                    return new statisticsActions.getStaticsSuccess({
                        statistics: response.data
                    });
                })
                .catch(error =>
                    of(new statisticsActions.getStaticsFail({ error: error.error }))
                );
        });
}