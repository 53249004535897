import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { StoreModule, ActionReducer, MetaReducer } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "./../environments/environment";
import { localStorageSync } from "ngrx-store-localstorage";
// import { Routes, RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { SharedModule } from "./shared/shared.module";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { WeekPipe } from "./shared/planning/week.pipe";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from "ngx-perfect-scrollbar";
import { HttpModule } from "@angular/http";
import { EffectsModule } from "@ngrx/effects";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { reducers } from "./store/reducers";
import { AdminComponent } from "./admin/admin.component";
import { BreadcrumbsComponent } from "./shared/breadcrumbs/breadcrumbs.component";
import { MenuItems } from "./shared/menu-items/menu-items";
import { ClientsComponent } from "./admin/clients/clients.component";
import { PlanningComponent } from "./shared/planning/planning.component";
import { MainAdminComponent } from "./admin/main-admin/main-admin.component";
import { CoachsComponent } from "./admin/coachs/coachs.component";
import { PaymentComponent } from "./admin/payment/payment.component";
import { BootcampComponent } from "./admin/bootcamp/bootcamp.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BoardTableComponent } from "./admin/dashboard/dashboard.component";
import { AssistantClientsComponent } from "./assistant/assistant-clients/assistant-clients.component";
import { AssistantPaymentsComponent } from "./assistant/assistant-payments/assistant-payments.component";
import { AssistantBootcampComponent } from "./assistant/assistant-bootcamp/assistant-bootcamp.component";
import { AssistantComponent } from "./assistant/assistant.component";
import { MainAssistantComponent } from "./assistant/main-assistant/main-assistant.component";
import { ClientDetailsComponent } from "./assistant/client-details/client-details.component";
import { CoachClientsComponent } from "./coach/coach-clients/coach-clients.component";
import { CoachPlanningComponent } from "./coach/coach-planning/coach-planning.component";
import { MainCoachComponent } from "./coach/main-coach/main-coach.component";
import { GeneralBarComponent } from "./shared/general-bar/general-bar.component";
import { CoachComponent } from "./coach/coach.component";
import { MemberComponent } from "./member/member.component";
import { MemberBoardTableComponent } from "./member/member-board-table/member-board-table.component";
import { MemberPlanningComponent } from "./member/member-planning/member-planning.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { ClickOutsideModule } from "ng-click-outside";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { AuthEffects } from "./effects/auth.effects";
import { ClientsEffects } from "./effects/clients.effects";
import { PaymentsEffects } from "./effects/payments.effects";
import { HomeComponent } from "./home/home.component";
import { AuthGuard } from "./auth/auth.guard";
import { FilterPipe } from "./shared/pipes/filter.pipe";
import { PlanningEffects } from "./effects/planning.effects";
import { AdminPlanningComponent } from "./admin/admin-planning/admin-planning.component";
import { AssistantDashboardComponent } from "./assistant/assistant-dashboard/assistant-dashboard.component";
import { TokenInterceptor } from "./auth/token-interceptor.service";
import { UpdateClientModalComponent } from "./shared/update-client-modal/update-client-modal.component";
import { CoachClientDetailsComponent } from "./coach/coach-client-details/coach-client-details.component";
import { ChartComponent } from "./shared/chart/chart.component";
import { LandingPlanningComponent } from "./home/landing-planning/landing-planning.component";
import { CradPaymentComponent } from "./shared/crad-payment/crad-payment.component";
import { BecomeMemberComponent } from "./home/become-member/become-member.component";
import { VisitorFormComponent } from "./home/visitor-form/visitor-form.component";
import { SessionCardComponent } from "./shared/session-card/session-card.component";
import { AdminClientDetailsComponent } from "./admin/admin-client-details/admin-client-details.component";
import { DeleteModalComponent } from "./shared/delete-modal/delete-modal.component";
import { RoundProgressModule } from "angular-svg-round-progressbar";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { PlanningBootcampComponent } from "./member/planning-bootcamp/planning-bootcamp.component";
import { VisitorComponent } from "./visitor/visitor.component";
import { VisitorDashboardComponent } from "./visitor/visitor-dashboard/visitor-dashboard.component";
import { VisitorPlanningBootcampComponent } from "./visitor/visitor-planning-bootcamp/visitor-planning-bootcamp.component";
import { SetPasswordComponent } from "./set-password/set-password.component";
import { StatisticsEffects } from "./effects/statistics.effects";
import { ServiceWorkerModule } from "@angular/service-worker";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { ToastrModule } from "ngx-toastr";
import { ChartModule } from "angular-highcharts";
import { CheckPasswordDirective } from "./shared/checkPassword.directive";
import { GeneralConditionComponent } from './general-condition/general-condition.component';

registerLocaleData(localeFr, "fr");
export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["auth", "clients"],
    rehydrate: true
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BoardTableComponent,
    AdminComponent,
    BreadcrumbsComponent,
    ClientsComponent,
    CoachsComponent,
    BootcampComponent,
    PaymentComponent,
    PlanningComponent,
    MainAdminComponent,
    AssistantClientsComponent,
    AssistantPaymentsComponent,
    AssistantBootcampComponent,
    AssistantComponent,
    MainAssistantComponent,
    WeekPipe,
    ClientDetailsComponent,
    CoachClientsComponent,
    CoachPlanningComponent,
    MainCoachComponent,
    GeneralBarComponent,
    CoachComponent,
    MemberComponent,
    MemberBoardTableComponent,
    MemberPlanningComponent,
    ChartComponent,
    SpinnerComponent,
    HomeComponent,
    FilterPipe,
    AdminPlanningComponent,
    AssistantDashboardComponent,
    UpdateClientModalComponent,
    CoachClientDetailsComponent,
    LandingPlanningComponent,
    CradPaymentComponent,
    BecomeMemberComponent,
    VisitorFormComponent,
    SessionCardComponent,
    AdminClientDetailsComponent,
    DeleteModalComponent,
    PlanningBootcampComponent,
    VisitorComponent,
    VisitorDashboardComponent,
    VisitorPlanningBootcampComponent,
    SetPasswordComponent,
    CheckPasswordDirective,
    GeneralConditionComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    EffectsModule,
    RoundProgressModule,
    GooglePlaceModule,
    ChartModule,
    ToastrModule.forRoot(),
    EffectsModule.forRoot([
      AuthEffects,
      ClientsEffects,
      PaymentsEffects,
      PlanningEffects,
      StatisticsEffects
    ]),
    // SharedModule,
    HttpModule,
    HttpClientModule,
    ClickOutsideModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    // StoreModule.forRoot(reducers),
    StoreModule.forRoot(reducers, { metaReducers }),
    PerfectScrollbarModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    })
  ],
  providers: [
    MenuItems,
    AuthGuard,
    CookieService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "fr-CA"
    }
  ],
  exports: [PerfectScrollbarModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
