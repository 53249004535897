import { Component, OnInit } from '@angular/core';
import { MenuItems } from "../shared/menu-items/menu-items";

@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.scss']
})
export class VisitorComponent implements OnInit {

  constructor(public menuItems: MenuItems) { }

  ngOnInit() {
  }

}
