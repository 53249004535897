import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AdminComponent } from "./admin/admin.component";
import { ClientsComponent } from "./admin/clients/clients.component";
import { MainAdminComponent } from "./admin/main-admin/main-admin.component";
import { CoachsComponent } from "./admin/coachs/coachs.component";
import { PaymentComponent } from "./admin/payment/payment.component";
import { BootcampComponent } from "./admin/bootcamp/bootcamp.component";
import { BoardTableComponent } from "./admin/dashboard/dashboard.component";
import { AssistantComponent } from "./assistant/assistant.component";
import { AssistantClientsComponent } from "./assistant/assistant-clients/assistant-clients.component";
import { AssistantBootcampComponent } from "./assistant/assistant-bootcamp/assistant-bootcamp.component";
import { AssistantPaymentsComponent } from "./assistant/assistant-payments/assistant-payments.component";
import { MainAssistantComponent } from "./assistant/main-assistant/main-assistant.component";
import { ClientDetailsComponent } from "./assistant/client-details/client-details.component";
import { CoachClientsComponent } from "./coach/coach-clients/coach-clients.component";
import { CoachComponent } from "./coach/coach.component";
import { CoachPlanningComponent } from "./coach/coach-planning/coach-planning.component";
import { MemberComponent } from "./member/member.component";
import { MemberBoardTableComponent } from "./member/member-board-table/member-board-table.component";
import { MemberPlanningComponent } from "./member/member-planning/member-planning.component";
import { PlanningBootcampComponent } from "./member/planning-bootcamp/planning-bootcamp.component";
import { HomeComponent } from "./home/home.component";
import { AuthGuard } from "./auth/auth.guard";
import { AdminPlanningComponent } from "./admin/admin-planning/admin-planning.component";
import { AssistantDashboardComponent } from "./assistant/assistant-dashboard/assistant-dashboard.component";
import { CoachClientDetailsComponent } from "./coach/coach-client-details/coach-client-details.component";
import { VisitorComponent } from "./visitor/visitor.component";
import { VisitorDashboardComponent } from "./visitor/visitor-dashboard/visitor-dashboard.component";
import { VisitorPlanningBootcampComponent } from "./visitor/visitor-planning-bootcamp/visitor-planning-bootcamp.component";
import { SetPasswordComponent } from "./set-password/set-password.component";
import { GeneralConditionComponent } from "./general-condition/general-condition.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "generalCondition",
    component: GeneralConditionComponent
  },
  {
    path: "setPassword",
    component: SetPasswordComponent
  },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        // component: MainAdminComponent,
        // redirectTo:"boardtable",
        children: [
          {
            path: "boardtable",
            component: BoardTableComponent
          },
          {
            path: "clients",
            children: [
              {
                path: "",
                component: ClientsComponent
              },
              {
                path: ":id",
                component: ClientDetailsComponent
              }
            ]
          },
          {
            path: "coachs",
            component: CoachsComponent
          },
          {
            path: "bootcamp",
            component: BootcampComponent
          },
          {
            path: "payment",
            component: PaymentComponent
          },
          {
            path: "planning",
            component: AdminPlanningComponent
          }
        ]
      }
    ]
  },
  {
    path: "loginadmin",
    component: LoginComponent,
    data: { userType: "admin" }
  },
  {
    path: "assistant",
    component: AssistantComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        // component: MainAssistantComponent,
        // redirectTo:"boardtable",
        children: [
          {
            path: "boardtable",
            component: AssistantDashboardComponent
          },
          {
            path: "clients",
            // component: AssistantClientsComponent,
            children: [
              {
                path: "",
                component: AssistantClientsComponent
              },
              {
                path: ":id",
                component: ClientDetailsComponent
              }
            ]
          },
          {
            path: "bootcamp",
            component: AssistantBootcampComponent
          },
          {
            path: "payment",
            component: AssistantPaymentsComponent
          }
        ]
      }
    ]
  },
  {
    path: "loginassistant",
    component: LoginComponent,
    data: { userType: "assistant" }
  },
  {
    path: "coach",
    component: CoachComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "clients",
        children: [
          {
            path: "",
            component: CoachClientsComponent
          },
          {
            path: ":id",
            component: CoachClientDetailsComponent
          }
        ]
      },
      {
        path: "myplanning",
        component: CoachPlanningComponent
      }
    ]
  },
  {
    path: "logincoach",
    component: LoginComponent,
    data: { userType: "coach" }
  },
  {
    path: "member",
    component: MemberComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "boardtable",
        component: MemberBoardTableComponent
      },
      {
        path: "planning",
        component: MemberPlanningComponent
      },
      {
        path: "planningBootcamp",
        component: PlanningBootcampComponent
      }
    ]
  },
  {
    path: "loginclient",
    component: LoginComponent,
    data: { userType: "user" }
  },
  {
    path: "visitor",
    component: VisitorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "boardtable",
        component: VisitorDashboardComponent
      },
      {
        path: "planningBootcamp",
        component: VisitorPlanningBootcampComponent
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
