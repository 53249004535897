import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest
} from "@angular/common/http";
import { Router } from "@angular/router";
import { of } from "rxjs/observable/of";
import { Actions, Effect } from "@ngrx/effects";
import "rxjs/add/operator/map";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/mergeMap";
import { CookieService } from "ngx-cookie-service";
import { URLS } from "../../utils/URLS";
import * as authActions from "../store/Auth";
import { Store } from "@ngrx/store";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Http } from "@angular/http";
import * as fromUI from "../store/UI";

interface loginResponse {
  data: any;
}
interface checkEmailResponse {
  exists: boolean;
}
@Injectable()
export class AuthEffects {
  french: any;
  constructor(
    public http: Http,
    private actions$: Actions,
    private store: Store<{}>,
    private httpClient: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private modalService: NgbModal,
    public toastr: ToastrService
  ) {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
  }
  @Effect()
  authLogin = this.actions$
    .ofType(authActions.LOGIN_REQUEST)

    .map((action: authActions.loginRequest) => action.payload)
    .switchMap(authData => {
      console.log(authData);

      return this.httpClient
        .post<loginResponse>(URLS.LOGIN, authData)
        .map(response => {
          const expirationDate = new Date().getDate() + 7
          const { accessToken, refreshToken, expiresIn } = response.data.token
          const { email } = response.data.user
          this.cookieService.set("access_token", accessToken, expirationDate, "/", '', false, 'Lax');
          this.cookieService.set("refresh_token", refreshToken, expirationDate, "/", '', false, 'Lax');
          this.cookieService.set("email1", email, expirationDate, "/", '', false, 'Lax');
          this.cookieService.set("expires_In", expiresIn, expirationDate, "/", '', false, 'Lax');
          console.log(this.cookieService.getAll());

          switch (authData.role) {
            case "admin":
              this.router.navigate(["/admin/dashboard/boardtable"]);
              break;
            case "user":
              {
                if (response.data.user.userType == "member")
                  this.router.navigate(["/member/boardtable"]);
                else this.router.navigate(["/visitor/boardtable"]);
              }
              break;
            case "assistant":
              this.router.navigate(["/assistant/dashboard/boardtable"]);
              break;
            case "coach":
              this.router.navigate(["/coach/myplanning"]);
              break;
          }
          return new authActions.loginsuccess({ user: response.data.user });
        })
        .catch(error =>
          of(new authActions.loginFail({ error: error.error.code }))
        );
    });
  @Effect()
  checkToken = this.actions$
    .ofType(authActions.CHECK_TOKEN_REQUEST)

    .map((action: authActions.checkTokenRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<loginResponse>(URLS.CHECK_TOKEN, payload)
        .map(response => {
          return new authActions.checkTokenSuccess({
            checkedClient: response.data
          });
        })
        .catch(error =>
          of(new authActions.checkTokenFail({ error: error.error.code }))
        );
    });
  // reset password
  @Effect()
  resetPassword = this.actions$
    .ofType(authActions.RESET_PASSWORD_REQUEST)

    .map((action: authActions.resetPasswordRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<loginResponse>(URLS.RESET_PASSWORD, payload.value)
        .map(response => {
          this.modalService.dismissAll();
          this.toastr.success(this.french.resendMailSuccess);
          return new authActions.resetPasswordSuccess();
        })
        .catch(error =>
          of(new authActions.resetPasswordFail({ error: error.error.code }))
        );
    });
  // check email
  @Effect()
  checkEmail = this.actions$
    .ofType(authActions.CHECK_EMAIL_REQUEST)

    .map((action: authActions.checkEmailRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<checkEmailResponse>(URLS.CHECK_EMAIL, payload.value)
        .map(response => {
          // this.modalService.dismissAll();
          // this.toastr.success(this.french.resendMailSuccess);
          if (response.exists == false) {
            this.store.dispatch(
              new fromUI.changeReserveBootcampStepVisitor({
                reserveBootcampStepVisitor: "payment"
              })
            );
          }

          return new authActions.checkEmailSuccess({ exists: response.exists });
        })
        .catch(error =>
          of(new authActions.checkEmailFail({ error: error.error }))
        );
    });
  // update password
  @Effect()
  updatePassword = this.actions$
    .ofType(authActions.UPDATE_PASSWORD_REQUEST)

    .map((action: authActions.updatePasswordRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<loginResponse>(URLS.UPDATE_PASSWORD, payload.value)
        .map(response => {
          this.cookieService.set(
            "access_token",
            response.data.token.accessToken,
            new Date().getDate() + 7,
            "/", '', false, 'Lax'
          );
          this.cookieService.set(
            "refresh_token",
            response.data.token.refreshToken,
            new Date().getDate() + 7,
            "/", '', false, 'Lax'
          );
          this.cookieService.set(
            "email1",
            response.data.user.email,
            new Date().getDate() + 7,
            "/", '', false, 'Lax'
          );
          this.cookieService.set(
            "expires_In",
            response.data.token.expiresIn,
            new Date().getDate() + 7,
            "/", '', false, 'Lax'
          );
          switch (response.data.user.role) {
            case "admin":
              this.router.navigate(["/admin/dashboard/boardtable"]);
              break;
            case "user":
              {
                if (response.data.user.userType == "member")
                  this.router.navigate(["/member/boardtable"]);
                else this.router.navigate(["/visitor/boardtable"]);
              }
              break;
            case "assistant":
              this.router.navigate(["/assistant/dashboard/boardtable"]);
              break;
            case "coach":
              this.router.navigate(["/coach/myplanning"]);
              break;
          }
          this.store.dispatch(
            new authActions.loginsuccess({ user: response.data.user })
          );
          return new authActions.updatePasswordSuccess();
        })
        .catch(error =>
          of(new authActions.updatePasswordFail({ error: error.error.code }))
        );
    });
  @Effect()
  Logout = this.actions$
    .ofType(authActions.LOGOUT)
    .map((action: authActions.Logout) => action.payload)
    .switchMap(payload => {
      this.cookieService.deleteAll("/");
      switch (payload.role) {
        case "admin":
          this.router.navigate(["/loginadmin"]);
          break;
        case "user":
          this.router.navigate(["/"]);

          break;
        case "assistant":
          this.router.navigate(["/loginassistant"]);
          break;
        case "coach":
          this.router.navigate(["/logincoach"]);
      }
      return of(new authActions.FinishLogout({}));
    });
  // @Effect()
  // logout = this.actions$.ofType(authActions.LOGOUT).switchMap(() => {
  //   this.cookieService.deleteAll("/");
  //   this.store.select("auth").subscribe(val => {
  // switch (val.loggedInUser.role) {
  //   case "admin":
  //     this.router.navigate(["/loginadmin"]);
  //     break;
  //   case "user":
  //     this.router.navigate(["/"]);

  //     break;
  //   case "assistant":
  //     this.router.navigate(["/loginassistant"]);
  //     break;
  //   case "coach":
  // this.router.navigate(["/logincoach"]);
  // }
  //   });
  //   return new authActions.FinishLogout({});
  // });
}
