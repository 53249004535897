import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Http } from "@angular/http";
import { Store } from "@ngrx/store";
import * as fromAuth from "../store/Auth"
@Component({
  selector: "app-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.scss"]
})
export class SetPasswordComponent implements OnInit {
  french;
  token: string;
  password = "kkkk";
  email;
  authState;
  constructor(
    private route: ActivatedRoute,
    public http: Http,
    private store: Store<{}>
  ) {
    this.route.queryParams.subscribe(params => {
      this.token = params.token ? params.token : ""
    })
  }

  ngOnInit() {
    this.email = this.token.split(".")[0] + "." + this.token.split(".")[1]
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("auth").subscribe(val => {
      this.authState = val;
    });
    this.store.dispatch(new fromAuth.checkTokenRequest({ token: this.token }))
  }
  onSubmit(form) {
    this.store.dispatch(new fromAuth.updatePasswordRequest({ value: { token: this.token, password: form.value.passwords.password }, email: this.email }))
  }
}
