import { Component, OnInit } from "@angular/core";
import { MenuItems } from "../shared/menu-items/menu-items";

@Component({
  selector: "app-member",
  templateUrl: "./member.component.html",
  styleUrls: ["./member.component.css"]
})
export class MemberComponent implements OnInit {
  constructor(public menuItems: MenuItems) {}

  ngOnInit() {}
}
