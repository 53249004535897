// import { Component } from "@angular/core";
import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { Http } from "@angular/http";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
  animations: [
    trigger("notificationBottom", [
      state(
        "an-off, void",
        style({
          overflow: "visible",
          padding: "20px",
          height: AUTO_STYLE
        })
      ),
      state(
        "an-animate",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      transition("an-off <=> an-animate", [animate("400ms ease-in-out")])
    ]),
    trigger("slideInOut", [
      state(
        "in",
        style({
          width: "280px"
          // transform: 'translate3d(0, 0, 0)'
        })
      ),
      state(
        "out",
        style({
          width: "0"
          // transform: 'translate3d(100%, 0, 0)'
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out"))
    ]),
    trigger("mobileHeaderNavRight", [
      state(
        "nav-off, void",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      state(
        "nav-on",
        style({
          height: AUTO_STYLE
        })
      ),
      transition("nav-off <=> nav-on", [animate("400ms ease-in-out")])
    ]),
    trigger("fadeInOutTranslate", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("400ms ease-in-out", style({ opacity: 1 }))
      ]),
      transition(":leave", [
        style({ transform: "translate(0)" }),
        animate("400ms ease-in-out", style({ opacity: 0 }))
      ])
    ]),
    trigger("mobileMenuTop", [
      state(
        "no-block, void",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      state(
        "yes-block",
        style({
          height: AUTO_STYLE
        })
      ),
      transition("no-block <=> yes-block", [animate("400ms ease-in-out")])
    ])
  ]
})
export class BreadcrumbsComponent implements OnInit {
  @Input()
  loggedInUser: any;
  @Input()
  member: boolean;
  public french: any;
  public profileNotification: string = "an-animate";
  public liveNotification: string;
  public liveNotificationClass: string;
  public innerChatSlideInOut: string;
  public chatSlideInOut: string;
  public profileNotificationClass: string = "";

  tempState = [];
  breadcrumbs: [
    {
      label: "routes.snapshot.data.title";
      icon: "icon";
      caption: "caption";
      status: true;
      url: "url";
    }
  ];
  // Array<Object>;
  // constructor(private router: Router, private route: ActivatedRoute) {
  //   this.router.events
  //     .filter(event => event instanceof NavigationEnd)
  //     .subscribe(() => {
  //       this.breadcrumbs = [];
  //       this.tempState = [];
  //       let currentRoute = this.route.root,
  //         url = "";
  //       do {
  //         const childrenRoutes = currentRoute.children;
  //         currentRoute = null;
  //         childrenRoutes.forEach(routes => {
  //           if (routes.outlet === "primary") {
  //             const routeSnapshot = routes.snapshot;
  //             url +=
  //               "/" + routeSnapshot.url.map(segment => segment.path).join("/");
  //             if (routes.snapshot.data.title !== undefined) {
  //               let status = true;
  //               if (routes.snapshot.data.status !== undefined) {
  //                 status = routes.snapshot.data.status;
  //               }

  //               let icon = false;
  //               if (routes.snapshot.data.icon !== undefined) {
  //                 icon = routes.snapshot.data.icon;
  //               }

  //               let caption = false;
  //               if (routes.snapshot.data.caption !== undefined) {
  //                 caption = routes.snapshot.data.caption;
  //               }

  //               if (!this.tempState.includes(routes.snapshot.data.title)) {
  //                 this.tempState.push(routes.snapshot.data.title);
  //                 this.breadcrumbs.push({
  //                   label: routes.snapshot.data.title,
  //                   icon: icon,
  //                   caption: caption,
  //                   status: status,
  //                   url: url
  //                 });
  //               }
  //             }
  //             currentRoute = routes;
  //           }
  //         });
  //       } while (currentRoute);
  //     });
  // }
  constructor(public http: Http) {}
  ngOnInit() {
    this.setBackgroundPattern("theme1");
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
  }
  toggleProfileNotification() {
    this.profileNotification =
      this.profileNotification === "an-off" ? "an-animate" : "an-off";
  }
  setBackgroundPattern(pattern: string) {
    document.querySelector("body").setAttribute("themebg-pattern", pattern);
    // this.menuTitleTheme = this.freamType = this.activeItemTheme = this.headerTheme = pattern;
  }
  // toggleLiveNotification() {
  //   if (this.profileNotification === "an-animate") {
  //     this.toggleProfileNotification();
  //   }

  //   this.liveNotification =
  //     this.liveNotification === "an-off" ? "an-animate" : "an-off";
  //   this.liveNotificationClass =
  //     this.liveNotification === "an-animate" ? "show" : "";

  //   if (
  //     this.liveNotification === "an-animate" &&
  //     this.innerChatSlideInOut === "in"
  //   ) {
  //     this.toggleInnerChat();
  //   }
  //   if (
  //     this.liveNotification === "an-animate" &&
  //     this.chatSlideInOut === "in"
  //   ) {
  //     this.toggleChat();
  //   }
  // }

  // if (this.liveNotification === "an-animate") {
  //   this.toggleLiveNotification();
  // }

  // this.profileNotificationClass =
  //   this.profileNotification === "an-animate" ? "show" : "";

  // if (
  //   this.profileNotification === "an-animate" &&
  //   this.innerChatSlideInOut === "in"
  // ) {
  //   this.toggleInnerChat();
  // }
  // if (
  //   this.profileNotification === "an-animate" &&
  //   this.chatSlideInOut === "in"
  // ) {
  //   this.toggleChat();
  // }
  // }
  // toggleChat() {
  //   if (this.innerChatSlideInOut === "in") {
  //     this.innerChatSlideInOut = "out";
  //   } else {
  //     this.chatSlideInOut = this.chatSlideInOut === "out" ? "in" : "out";
  //   }
  // }
  // toggleInnerChat() {
  //   this.innerChatSlideInOut =
  //     this.innerChatSlideInOut === "out" ? "in" : "out";
  // }
  // notificationOutsideClick(ele: string) {
  //   if (ele === "live" && this.liveNotification === "an-animate") {
  //     this.toggleLiveNotification();
  //   } else if (ele === "profile" && this.profileNotification === "an-animate") {
  //     this.toggleProfileNotification();
  //   }
  // }
}
