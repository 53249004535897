import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Http } from "@angular/http";
import { of } from "rxjs/observable/of";
import { Actions, Effect } from "@ngrx/effects";
import "rxjs/add/operator/map";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/mergeMap";
import { Store } from "@ngrx/store";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { URLS } from "../../utils/URLS";
import * as planningActions from "../store/Planning";
import * as clientsActions from "../store/Clients";
import { addHours, startOfDay } from "date-fns";
import * as fromIndex from "../../utils/main-const";
import { convertToTimeZone } from "date-fns-timezone";

interface getList {
  data: any;
}

@Injectable()
export class PlanningEffects {
  french;
  constructor(
    public http: Http,
    public toastr: ToastrService,
    private modalService: NgbModal,
    private actions$: Actions,
    private httpClient: HttpClient,
    private store: Store<{}>
  ) {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
  }

  //get list themes
  @Effect()
  GetThemesList = this.actions$
    .ofType(planningActions.GET_THEMES_LIST_REQUEST)
    .switchMap(payload => {
      return this.httpClient
        .get<getList>(URLS.THEMES)
        .map(response => {
          return new planningActions.getThemesListSuccess({
            themes: response.data
          });
        })
        .catch(error =>
          of(new planningActions.getThemesListFail({ error: error.error }))
        );
    });
  //get list slots
  @Effect()
  GetSlotsList = this.actions$
    .ofType(planningActions.GET_SLOTS_LIST_REQUEST)
    .map((action: planningActions.getSlotsListRequest) => action.payload)
    .switchMap(payload => {
      let url = URLS.SLOTS;
      if (payload.coachId) {
        url +=
          "?coachId=" +
          payload.coachId +
          "&includeSubs=true&perPage=200&endBefore=" +
          payload.endBefore +
          "&startBefore=" +
          payload.startBefore;
      } else {
        url +=
          "?sessionTypeId=" +
          payload.sessionTypeId +
          "&includeSubs=true&perPage=200&endBefore=" +
          payload.endBefore +
          "&startBefore=" +
          payload.startBefore;
      }
      return this.httpClient
        .get<getList>(url)
        .map(response => {
          response.data.forEach((element, i) => {
            const e = {
              start: convertToTimeZone(new Date(element.starts), {
                timeZone: "Europe/Paris"
              }),
              end: convertToTimeZone(new Date(element.ends), {
                timeZone: "Europe/Paris"
              }),
              title:
                element.status == "booked"
                  ? element.session.sessionTypeId == fromIndex.bootcampId
                    ? "bootcamp"
                    : "cours-particulier"
                  : element.status == "finished"
                  ? "finished"
                  : "libre",
              coachImage: element.coach.img,
              coachId: element.coach.id,
              coachName: `${element.coach.lname} ${element.coach.fname}`,
              sessionTypeId: element.sessionTypeId,
              id: element.id,
              status: element.status,
              users: element.subscriptions,
              session: element.session ? element.session : {},
              type:
                element.status == "booked"
                  ? element.session.sessionTypeId == fromIndex.bootcampId
                    ? "bootcamp"
                    : "cours-particulier"
                  : element.status == "finished"
                  ? "finished"
                  : "libre"
            };
            response.data[i] = e;
          });
          return new planningActions.getSlotsListSuccess({
            slots: response.data
          });
        })
        .catch(error =>
          of(new planningActions.getSlotsListFail({ error: error.error }))
        );
    });
  //get finished bootcamp
  @Effect()
  GetFinishedBootcamp = this.actions$
    .ofType(planningActions.GET_FINISHED_BOOTCAMP_REQUEST)
    .map((action: planningActions.getFinishedBootcampRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .get<getList>(
          URLS.SLOTS +
            "?sessionTypeId=" +
            payload.sessionTypeId +
            "&status=finished&perPage=200"
        )
        .map(response => {
          response.data.forEach((element, i) => {
            let e = {
              start: convertToTimeZone(new Date(element.starts), {
                timeZone: "Europe/Paris"
              }),
              end: convertToTimeZone(new Date(element.ends), {
                timeZone: "Europe/Paris"
              }),
              session: element.session
            };
            response.data[i] = e;
          });
          return new planningActions.getFinishedBootcampSuccess({
            finishedBootcampList: response.data
          });
        })
        .catch(error =>
          of(
            new planningActions.getFinishedBootcampFail({ error: error.error })
          )
        );
    });
  //get booked bootcamp
  @Effect()
  GetBookedBootcamp = this.actions$
    .ofType(planningActions.GET_BOOKED_BOOTCAMP_REQUEST)
    .map((action: planningActions.getBookedBootcampRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .get<getList>(
          URLS.SLOTS +
            "?sessionTypeId=" +
            payload.sessionTypeId +
            "&status=booked&perPage=200"
        )
        .map(response => {
          response.data.forEach((element, i) => {
            let e = {
              start: convertToTimeZone(new Date(element.starts), {
                timeZone: "Europe/Paris"
              }),
              end: convertToTimeZone(new Date(element.ends), {
                timeZone: "Europe/Paris"
              }),
              session: element.session
            };
            response.data[i] = e;
          });
          return new planningActions.getBookedBootcampSuccess({
            bookedBootcampList: response.data
          });
        })
        .catch(error =>
          of(new planningActions.getBookedBootcampFail({ error: error.error }))
        );
    });
  // add slot by coach
  @Effect()
  AddSlot = this.actions$
    .ofType(planningActions.ADD_SLOT_REQUEST)
    .map((action: planningActions.addSlotRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<getList>(URLS.SLOTS, payload.value)
        .map(() => {
          this.store.dispatch(
            new planningActions.getSlotsListRequest({
              coachId: payload.coachId,
              startBefore: payload.startBefore,
              endBefore: payload.endBefore
            })
          );
          this.modalService.dismissAll();
          this.toastr.success(this.french.successAddDisponibility);
          return new planningActions.addSlotSuccess();
        })
        .catch(error =>
          of(new planningActions.addSlotFail({ error: error.error }))
        );
    });
  // delete session
  @Effect()
  DeleteSession = this.actions$
    .ofType(planningActions.DELETE_SESSION_REQUEST)
    .map((action: planningActions.deleteSessionRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .delete<getList>(URLS.SLOTS + "/" + payload.sessionId)
        .map(() => {
          this.store.dispatch(
            new planningActions.getSlotsListRequest({
              coachId: payload.coachId,
              startBefore: payload.startBefore,
              endBefore: payload.endBefore
            })
          );

          this.modalService.dismissAll();
          this.toastr.success(this.french.successDeleteSession);
          return new planningActions.deleteSessionSuccess();
        })
        .catch(error =>
          of(new planningActions.deleteSessionFail({ error: error.error }))
        );
    });
  // cancel session
  @Effect()
  CancelSession = this.actions$
    .ofType(planningActions.CANCEL_SESSION_REQUEST)
    .map((action: planningActions.cancelSessionRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<getList>(URLS.SLOTS + "/" + payload.sessionId + "/cancel", {})
        .map(() => {
          this.store.dispatch(
            new planningActions.getSlotsListRequest({
              coachId: payload.coachId,
              startBefore: payload.startBefore,
              endBefore: payload.endBefore
            })
          );

          this.modalService.dismissAll();
          this.toastr.success(this.french.successDeleteSession);
          return new planningActions.cancelSessionSuccess();
        })
        .catch(error =>
          of(new planningActions.cancelSessionFail({ error: error.error }))
        );
    });
  // create session
  @Effect()
  CreateSession = this.actions$
    .ofType(planningActions.CREATE_SESSION_REQUEST)
    .map((action: planningActions.createSessionRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<getList>(URLS.SLOTS + "/reserve", {
          sessionTypeId: payload.sessionTypeId,
          starts: payload.value.starts,
          ends: payload.value.ends,
          coachId: payload.value.coachId,
          theme: payload.value.theme,
          desc: payload.value.desc,
          usersIds: payload.usersIds ? payload.usersIds : []
        })
        .map(response => {
          if (payload.usersIds) {
            this.store.dispatch(
              new planningActions.getSlotsListRequest({
                coachId: payload.value.coachId,
                startBefore: payload.startBefore,
                endBefore: payload.endBefore
              })
            );
            this.store.dispatch(
              new clientsActions.GetUserDetailsRequest({
                userId: payload.usersIds[0]
              })
            );
            // this.store.dispatch(
            //   new planningActions.subscribeUserToSessionRequest({
            //     slotId: response.data.id,
            //     userId: payload.usersIds[0]
            //   })
            // );
          } else {
            this.store.dispatch(
              new planningActions.getSlotsListRequest({
                sessionTypeId: fromIndex.bootcampId,
                startBefore: payload.startBefore,
                endBefore: payload.endBefore
              })
            );
          }
          this.modalService.dismissAll();
          this.toastr.success(this.french.successCreateSession);
          return new planningActions.createSessionSuccess();
        })
        .catch(error =>
          of(new planningActions.createSessionFail({ error: error.error }))
        );
    });
  // close session
  @Effect()
  CloseSession = this.actions$
    .ofType(planningActions.CLOSE_SESSION_REQUEST)
    .map((action: planningActions.closeSessionRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<getList>(
          URLS.SLOTS + "/" + payload.slotId + "/close",
          payload.data
        )
        .map(response => {
          this.modalService.dismissAll();
          this.toastr.success(this.french.successCloseSession);
          this.store.dispatch(
            new planningActions.getSlotsListRequest({
              coachId: payload.coachId,
              startBefore: payload.startBefore,
              endBefore: payload.endBefore
            })
          );
          return new planningActions.closeSessionSuccess();
        })
        .catch(error =>
          of(new planningActions.closeSessionFail({ error: error.error }))
        );
    });

  // Subscribe user to session
  @Effect()
  SubscribeUserToSession = this.actions$
    .ofType(planningActions.SUBSCRIBE_USER_TO_SESSION_REQUEST)
    .map(
      (action: planningActions.subscribeUserToSessionRequest) => action.payload
    )
    .switchMap(payload => {
      return this.httpClient
        .post<getList>(
          URLS.USERS + "/" + payload.userId + "/sessions-subscriptions",
          { slotId: payload.slotId }
        )
        .map(() => {
          this.modalService.dismissAll();
          this.toastr.success(this.french.successSubscribeUserToSession);
          return new planningActions.subscribeUserToSessionSuccess();
        })
        .catch(error =>
          of(
            new planningActions.subscribeUserToSessionFail({
              error: error.error
            })
          )
        );
    });
  // get sessions subscriptions
  @Effect()
  GetSessionsSubscription = this.actions$
    .ofType(planningActions.GET_SESSIONS_SUBSCRIPTIONS_LIST_REQUEST)
    .map(
      (action: planningActions.getSessionsSubscriptionsListRequest) =>
        action.payload
    )
    .switchMap(payload => {
      return this.httpClient
        .get<getList>(
          URLS.USERS + "/" + payload.userId + "/sessions-subscriptions"
        )
        .map(response => {
          response.data.forEach((element, i) => {
            let e = {
              start: convertToTimeZone(new Date(element.slot.starts), {
                timeZone: "Europe/Paris"
              }),
              end: convertToTimeZone(new Date(element.slot.ends), {
                timeZone: "Europe/Paris"
              }),
              title: element.sessionType.name,
              coachImage: element.coach.img,
              sessionTypeId: element.sessionType.id,
              // status: element.status,
              users: [],
              session: element.slot,
              type:
                element.sessionType.name == "Bootcamp"
                  ? "bootcamp"
                  : "cours-particulier"
            };
            response.data[i] = e;
          });
          return new planningActions.getSessionsSubscriptionsListSuccess({
            sessions: response.data
          });
        })
        .catch(error =>
          of(
            new planningActions.getSessionsSubscriptionsListFail({
              error: error.error
            })
          )
        );
    });
}
