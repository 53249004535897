import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { of } from "rxjs/observable/of";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { URLS } from "../../utils/URLS";
import { AuthService } from "./auth.service";

interface refreshResponse {
  data: any;
}

@Injectable()
export class AuthGuard implements CanActivate {
  isLoggedIn;
  refreshToken = this.cookieService.get("refresh_token");
  email = this.cookieService.get("email1");
  expires_In = this.cookieService.get("expires_In")
  constructor(
    private router: Router,
    private store: Store<{}>,
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private authService: AuthService
  ) {
    this.store.select("auth").subscribe(val => {
      this.isLoggedIn = val.isLoggedIn;
    });
  }
  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   return this.store.select("auth").map(val => {
  //     if (!val.isLoggedIn) {
  //       this.router.navigate(["/"]);
  //       return false;
  //     } else return true;
  //   });
  // }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService
      .isAuthenticated(this.isLoggedIn, this.email, this.refreshToken, this.expires_In)
      .map(isLoggedIn => {
        if (isLoggedIn) {
          return true;
        } else {
          this.router.navigate([""]);
          return false;
        }
      });
  }
}
