import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbDatepickerI18n, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { Http } from "@angular/http";
import * as fromPlanning from "../../store/Planning";
import { Store } from "@ngrx/store";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import XDate from "xdate";
import * as fromIndex from "../../../utils/main-const";
import * as datepickerFormat from "../../shared/datepicker-format"
import { parseFromTimeZone } from "date-fns-timezone";
import { startOfWeek, lastDayOfWeek, addDays } from "date-fns";

@Component({
  selector: "app-coach-planning",
  templateUrl: "./coach-planning.component.html",
  styleUrls: ["./coach-planning.component.scss"],
  providers: [datepickerFormat.I18n, { provide: NgbDatepickerI18n, useClass: datepickerFormat.CustomDatepickerI18n }, { provide: NgbDateParserFormatter, useClass: datepickerFormat.MyFormatter }]
})
export class CoachPlanningComponent implements OnInit {
  public french: any;
  fenceForm: FormGroup;
  startBefore = startOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }).toISOString()
  endBefore = addDays(lastDayOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }), 1).toISOString()
  clients = ["r", "f", "t"];
  particularCoursPopupContent: string = "consult";
  popupContent: string = "list";
  clientsList;
  planningState;
  events;
  courseStart;
  courseEnd;
  status;
  defaultStartShedule: any = "07:00";
  defaultEndShedule: any = "22:00";
  coachId;
  selectedBootcamp;
  healthCheckbox = false;
  healthChecked = false;
  slotId;
  constructor(
    public http: Http,
    private modalService: NgbModal,
    private store: Store<{}>
  ) {
    this.store.select("auth").subscribe(val => {
      this.coachId = val.loggedInUser.id;
    });
    this.store.select("planning").subscribe(val => {
      this.planningState = val;
    });
  }

  ngOnInit() {
    this.store.dispatch(new fromPlanning.initializeErrorMessage());
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("planning").subscribe(val => {
      // this.events = val.planningList;
      this.events = val.slots;
    });
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        coachId: this.coachId,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
    this.store.dispatch(new fromPlanning.getThemesListRequest())
    // this.store.select("auth").subscribe(val => (this.loggedInUser = val));
  }
  changeWeek($event) {
    let values = {
      startBefore: parseFromTimeZone($event.startBefore,
        { timeZone: "Europe/Paris" }
      ).toISOString(),
      endBefore: parseFromTimeZone($event.endBefore,
        { timeZone: "Europe/Paris" }
      ).toISOString()
    }
    this.startBefore = values.startBefore;
    this.endBefore = values.endBefore
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        coachId: this.coachId,
        startBefore: values.startBefore,
        endBefore: values.endBefore
      })
    );

  }
  getParticularSessionCredit(credits) {
    return credits.filter(
      credit => credit.sessionTypeId == fromIndex.particularCourse
    );
  }
  openDeleteSlot(content, $event) {
    this.slotId = $event.id;
    this.coachId = $event.coachId;
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      windowClass: "custom-modal"
    });
  }
  deleteSlot() {
    this.store.dispatch(
      new fromPlanning.deleteSessionRequest({
        sessionId: this.slotId,
        coachId: this.coachId,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }
  newDispo(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.store.dispatch(new fromPlanning.initializeErrorMessage());
        },
        reason => {
          this.store.dispatch(new fromPlanning.initializeErrorMessage());
        }
      );
  }
  initForm(clients) {
    let bootcampClientsList = new FormArray([]);

    for (let i = 0; i < clients.length; i++) {
      bootcampClientsList.push(
        new FormGroup({
          presence: new FormControl(true)
        })
      );
    }
    this.fenceForm = new FormGroup({
      clients: bootcampClientsList
    });
  }
  onSubmit(form) {
    form.value.startDate.month = fromIndex.reformeDate(form.value.startDate.month);
    form.value.startDate.day = fromIndex.reformeDate(form.value.startDate.day);
    form.value.endDate.month = fromIndex.reformeDate(form.value.endDate.month);
    form.value.endDate.day = fromIndex.reformeDate(form.value.endDate.day);
    const startDate = parseFromTimeZone(
      `${form.value.startDate.year}-${form.value.startDate.month}-${
      form.value.startDate.day
      } ${form.value.startDateStartSchedule}:00`,
      { timeZone: "Europe/Paris" }
    ).toISOString();
    const endDate = parseFromTimeZone(
      `${form.value.endDate.year}-${form.value.endDate.month}-${
      form.value.endDate.day
      } ${form.value.endDateEndSchedule}:00`,
      { timeZone: "Europe/Paris" }
    ).toISOString();
    this.store.dispatch(
      new fromPlanning.addSlotRequest({
        coachId: this.coachId,
        value: { startDate, endDate },
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }

  openlistClients(content, $event) {
    this.initForm($event.users);
    this.slotId = $event.id;
    this.clientsList = $event.users;
    this.selectedBootcamp = $event;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.popupContent = "list";
        },
        reason => {
          this.popupContent = "list";
        }
      );
  }
  openParticularCours(content, $event) {
    this.courseStart = $event.start;
    this.courseEnd = $event.end;
    this.clientsList = $event.users;
    this.slotId = $event.id;
    this.status = $event.status
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.particularCoursPopupContent = "consult";
          this.healthCheckbox = false;
          this.healthChecked = false;
        },
        reason => {
          this.particularCoursPopupContent = "consult";
          this.healthCheckbox = false;
          this.healthChecked = false;
        }
      );
  }
  closeParticularSessionBack() {
    this.particularCoursPopupContent = "consult";
  }
  closeBootcampBack() {
    this.popupContent = "list";
  }
  fence() {
    this.popupContent = "cloture";
  }
  togglehealthCheckbox(e) {
    this.healthChecked = e.target.checked;
  }
  fenceParticularCours() {
    this.particularCoursPopupContent = "cloture";
  }
  onSubmitCloture() {
    let presences = []

    for (let i = 0; i < this.clientsList.length; i++) {
      presences.push({ ...this.fenceForm.value.clients[i], userId: this.clientsList[i].user.id })
    }
    this.store.dispatch(
      new fromPlanning.closeSessionRequest({
        slotId: this.slotId,
        coachId: this.coachId,
        data: {
          presences
        },
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }

  // consultCardClient() {
  //   this.particularCoursPopupContent = "card";
  // }
  onSubmitParticularCoursCloture(form) {
    let healthInfo;
    let data = {
      sessionRecap: form.value.recap,
      presences: [
        {
          userId: this.clientsList[0].user.id,
          presence: form.value.presence,
          healthInfo: {
            weight: form.value.weight,
            height: form.value.height,
            IMC: form.value.IMC,
            fatMass: form.value.fatMass,
            muscularMass: form.value.muscularMass,
            abdominalFatMassIndex: form.value.abdominalFatMassIndex,
            hydrationRate: form.value.hydrationRate
          }
        }
      ]
    };

    for (const key of Object.keys(data.presences[0].healthInfo)) {
      if (data.presences[0].healthInfo[key] != "" && data.presences[0].healthInfo[key] != undefined) {
        healthInfo = { ...healthInfo, [key]: data.presences[0].healthInfo[key] }
      }
    }

    if (form.value.recap != "" && healthInfo != undefined) {
      this.store.dispatch(
        new fromPlanning.closeSessionRequest({
          slotId: this.slotId,
          coachId: this.coachId,
          data: {
            sessionRecap: form.value.recap,
            presences: [
              {
                userId: this.clientsList[0].user.id,
                presence: form.value.presence,
                healthInfo
              }
            ]
          },
          startBefore: this.startBefore,
          endBefore: this.endBefore
        })
      );
    }
    else if (form.value.recap == "" && healthInfo != undefined) {
      this.store.dispatch(
        new fromPlanning.closeSessionRequest({
          slotId: this.slotId,
          coachId: this.coachId,
          data: {
            presences: [
              {
                userId: this.clientsList[0].user.id,
                presence: form.value.presence,
                healthInfo
              }
            ]
          },
          startBefore: this.startBefore,
          endBefore: this.endBefore
        })
      );
    }
    else if (form.value.recap != "" && healthInfo == undefined) {
      this.store.dispatch(
        new fromPlanning.closeSessionRequest({
          slotId: this.slotId,
          coachId: this.coachId,
          data: {
            sessionRecap: form.value.recap,
            presences: [
              {
                userId: this.clientsList[0].user.id,
                presence: form.value.presence,
              }
            ]
          },
          startBefore: this.startBefore,
          endBefore: this.endBefore
        })
      );
    }
    else if (form.value.recap == "" && healthInfo == undefined) {
      this.store.dispatch(
        new fromPlanning.closeSessionRequest({
          slotId: this.slotId,
          coachId: this.coachId,
          data: {
            presences: [
              {
                userId: this.clientsList[0].user.id,
                presence: form.value.presence,
              }
            ]
          },
          startBefore: this.startBefore,
          endBefore: this.endBefore
        })
      );
    }
  }
}
