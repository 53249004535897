import { Component, OnInit, ViewChild } from "@angular/core";
import { Http } from "@angular/http";
import {
  NgbModal,
  NgbDatepickerI18n,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, NgForm } from "@angular/forms";
import { Store } from "@ngrx/store";
import * as datepickerFormat from "../../shared/datepicker-format";
import * as fromClients from "../../store/Clients";
import * as fromPlanning from "../../store/Planning";
import * as fromIndex from "../../../utils/main-const";
import { parseFromTimeZone } from "date-fns-timezone";
import { startOfWeek, lastDayOfWeek, addDays } from "date-fns";

@Component({
  selector: "app-bootcamp",
  templateUrl: "./bootcamp.component.html",
  styleUrls: ["./bootcamp.component.scss"],
  providers: [
    datepickerFormat.I18n,
    {
      provide: NgbDatepickerI18n,
      useClass: datepickerFormat.CustomDatepickerI18n
    },
    { provide: NgbDateParserFormatter, useClass: datepickerFormat.MyFormatter }
  ]
})
export class BootcampComponent implements OnInit {
  @ViewChild("f") addBootcampForm: NgForm;
  public french: any;
  events;
  startBefore = startOfWeek(
    parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }),
    { weekStartsOn: 1 }
  ).toISOString();
  endBefore = addDays(
    lastDayOfWeek(
      parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }),
      { weekStartsOn: 1 }
    ),
    1
  ).toISOString();
  clientsState;
  clientsList;
  startSchedule = "07:00";
  EndSchedule = "22:00";
  themeValue = "";
  coach;
  added: true;
  bootcampSelected;
  selectedCoachId;
  coachId;
  planningState;
  constructor(
    private modalService: NgbModal,
    public http: Http,
    private store: Store<{}>
  ) {
    this.store.dispatch(new fromClients.GetCoachListRequest({}));

    // this.store.select("auth").subscribe(val => {
    //   this.coachId = val.loggedInUser.id;
    // });
  }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("clients").subscribe(val => {
      this.clientsState = val;
      this.selectedCoachId = val.coachs.length && val.coachs[0].id;
    });
    this.store.dispatch(new fromPlanning.getThemesListRequest());
    this.store.select("planning").subscribe(val => {
      // this.events = val.bootcampList;
      this.themeValue = val.themes.length > 0 ? val.themes[0].name : "";
      this.events = val.slots;
      this.planningState = val;
    });
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        sessionTypeId: fromIndex.bootcampId,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }
  changeWeek($event) {
    let values = {
      startBefore: parseFromTimeZone($event.startBefore, {
        timeZone: "Europe/Paris"
      }).toISOString(),
      endBefore: parseFromTimeZone($event.endBefore, {
        timeZone: "Europe/Paris"
      }).toISOString()
    };
    this.startBefore = values.startBefore;
    this.endBefore = values.endBefore;
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        sessionTypeId: fromIndex.bootcampId,
        startBefore: values.startBefore,
        endBefore: values.endBefore
      })
    );
  }
  open(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.store.dispatch(new fromPlanning.initializeErrorMessage());
        },
        reason => {
          this.store.dispatch(new fromPlanning.initializeErrorMessage());
        }
      );
  }

  onSubmit(form: NgForm) {
    form.value.date.day = fromIndex.reformeDate(form.value.date.day);
    form.value.date.month = fromIndex.reformeDate(form.value.date.month);
    const starts = parseFromTimeZone(
      `${form.value.date.year}-${form.value.date.month}-${
        form.value.date.day
      } ${form.value.startSchedule}:00`,
      { timeZone: "Europe/Paris" }
    ).toISOString();

    const ends = parseFromTimeZone(
      `${form.value.date.year}-${form.value.date.month}-${
        form.value.date.day
      } ${form.value.EndSchedule}:00`,
      { timeZone: "Europe/Paris" }
    ).toISOString();

    this.store.dispatch(
      new fromPlanning.createSessionRequest({
        sessionTypeId: fromIndex.bootcampId,
        value: {
          coachId: form.value.coachId,
          starts,
          ends,
          desc: form.value.desc,
          theme: form.value.theme
        },
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }

  openBootcampCard(content, $event) {
    this.bootcampSelected = $event;
    this.clientsList = $event.users;

    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      windowClass: "custom-modal"
    });
  }
}
