import { Action } from "@ngrx/store";
interface Client {
  birthDate: string;
  createdAt: string;
  credits: any;
  email: string;
  fname: string;
  id: string;
  lname: string;
  moreInfo: any;
  phoneNumber: any;
  picture: string;
  role: string;
  userType: string;
}

// INITIALIZE ERROR MESSAGE
export const INITIALIZE_ERROR_MESSAGE = "INITIALIZE_ERROR_MESSAGE";
export class initializeErrorMessage implements Action {
  type = INITIALIZE_ERROR_MESSAGE;
}

// CLIENTS LIST IN DAY
export const GET_CLIENT_LIST_IN_DAY_REQUEST = "GET_CLIENT_LIST_IN_DAY_REQUEST";
export const GET_CLIENT_LIST_IN_DAY_SUCCESS = "GET_CLIENT_LIST_IN_DAY_SUCCESS";
export const GET_CLIENT_LIST_IN_DAY_FAIL = "GET_CLIENT_LIST_IN_DAY_FAIL";

export class GetClientListInDayRequest implements Action {
  readonly type = GET_CLIENT_LIST_IN_DAY_REQUEST;
  constructor(public payload) { }
}
export class GetClientListInDaySuccess implements Action {
  type = GET_CLIENT_LIST_IN_DAY_SUCCESS;
  constructor(public payload) { }
}
export class GetClientListInDayFail implements Action {
  type = GET_CLIENT_LIST_IN_DAY_FAIL;
  constructor(public payload) { }
}
//LIST CLIENT
export const GET_CLIENT_LIST_REQUEST = "GET_CLIENT_LIST_REQUEST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_FAIL = "GET_CLIENT_LIST_FAIL";

export class GetClientListRequest implements Action {
  readonly type = GET_CLIENT_LIST_REQUEST;
  constructor(public payload) { }
}
export class GetClientListSuccess implements Action {
  type = GET_CLIENT_LIST_SUCCESS;
  constructor(public payload) { }
}
export class GetClientListFail implements Action {
  type = GET_CLIENT_LIST_FAIL;
  constructor(public payload) { }
}
// USERS NUMBER
export const GET_USERS_NUMBER_REQUEST = "GET_USERS_NUMBER_REQUEST";
export const GET_USERS_NUMBER_SUCCESS = "GET_USERS_NUMBER_SUCCESS";
export const GET_USERS_NUMBER_FAIL = "GET_USERS_NUMBER_FAIL";
export class GetUsersNumberRequest implements Action {
  readonly type = GET_USERS_NUMBER_REQUEST;
}
export class GetUsersNumberSuccess implements Action {
  readonly type = GET_USERS_NUMBER_SUCCESS;
  constructor(public payload) { }
}
export class GetUsersNumberFail implements Action {
  readonly type = GET_USERS_NUMBER_FAIL;
  constructor(public payload) { }
}
// COACH NUMBER
export const GET_COACH_NUMBER_REQUEST = "GET_COACH_NUMBER_REQUEST";
export const GET_COACH_NUMBER_SUCCESS = "GET_COACH_NUMBER_SUCCESS";
export const GET_COACH_NUMBER_FAIL = "GET_COACH_NUMBER_FAIL";
export class GetCoachNumberRequest implements Action {
  readonly type = GET_COACH_NUMBER_REQUEST;
}
export class GetCoachNumberSuccess implements Action {
  readonly type = GET_COACH_NUMBER_SUCCESS;
  constructor(public payload) { }
}
export class GetCoachNumberFail implements Action {
  readonly type = GET_COACH_NUMBER_FAIL;
  constructor(public payload) { }
}
//LIST COACH
export const GET_COACH_LIST_REQUEST = "GET_COACH_LIST_REQUEST";
export const GET_COACH_LIST_SUCCESS = "GET_COACH_LIST_SUCCESS";
export const GET_COACH_LIST_FAIL = "GET_COACH_LIST_FAIL";

export class GetCoachListRequest implements Action {
  readonly type = GET_COACH_LIST_REQUEST;
  constructor(public payload) { }
}
export class GetCoachListSuccess implements Action {
  type = GET_COACH_LIST_SUCCESS;
  constructor(public payload) { }
}
export class GetCoachListFail implements Action {
  type = GET_COACH_LIST_FAIL;
  constructor(public payload) { }
}

// Add client
export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAIL = "ADD_CLIENT_FAIL";

export class AddClientRequest implements Action {
  readonly type = ADD_CLIENT_REQUEST;
  constructor(public payload) { }
}
export class AddClientSuccess implements Action {
  readonly type = ADD_CLIENT_SUCCESS;
}
export class AddClientFail implements Action {
  readonly type = ADD_CLIENT_FAIL;
  constructor(public payload) { }
}

// get user details
export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export class GetUserDetailsRequest implements Action {
  readonly type = GET_USER_DETAILS_REQUEST;
  constructor(public payload) { }
}
export class GetUserDetailsSuccess implements Action {
  type = GET_USER_DETAILS_SUCCESS;
  constructor(public payload) { }
}
export class GetUserDetailsFail implements Action {
  type = GET_USER_DETAILS_FAIL;
  constructor(public payload) { }
}
//Delete client
export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST",
  DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";

export class deleteClientRequest implements Action {
  readonly type = DELETE_CLIENT_REQUEST;
  constructor(public payload) { }
}
export class deleteClientSuccess implements Action {
  readonly type = DELETE_CLIENT_SUCCESS;
}
export class deleteClientFail implements Action {
  readonly type = DELETE_CLIENT_FAIL;
  constructor(public payload) { }
}

// update client
export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST",
  UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";

export class updateClientRequest implements Action {
  readonly type = UPDATE_CLIENT_REQUEST;
  constructor(public payload) { }
}
export class updateClientSuccess implements Action {
  readonly type = UPDATE_CLIENT_SUCCESS;
}
export class updateClientFail implements Action {
  readonly type = UPDATE_CLIENT_FAIL;
  constructor(public payload) { }
}
// Reinitialize user details 
export const REINITIALITE_USER_DETAILS = "REINITIALITE_USER_DETAILS";
export class ReinitializeUserDetails implements Action {
  readonly type = REINITIALITE_USER_DETAILS;
}
// register visitor
export const REGISTER_VISITOR_REQUEST = "REGISTER_VISITOR_REQUEST",
  REGISTER_VISITOR_SUCCESS = "REGISTER_VISITOR_SUCCESS",
  REGISTER_VISITOR_FAIL = "REGISTER_VISITOR_FAIL";

export class registerVisitorRequest implements Action {
  readonly type = REGISTER_VISITOR_REQUEST;
  constructor(public payload) { }
}
export class registerVisitorSuccess implements Action {
  readonly type = REGISTER_VISITOR_SUCCESS;
  constructor(public payload) { }
}
export class registerVisitorFail implements Action {
  readonly type = REGISTER_VISITOR_FAIL;
  constructor(public payload) { }
}

// contact admins
export const CONTACT_ADMINS_REQUEST = "CONTACT_ADMINS_REQUEST",
  CONTACT_ADMINS_SUCCESS = "CONTACT_ADMINS_SUCCESS",
  CONTACT_ADMINS_FAIL = "CONTACT_ADMINS_FAIL";

export class contactAdminsRequest implements Action {
  readonly type = CONTACT_ADMINS_REQUEST;
  constructor(public payload) { }
}
export class contactAdminsSuccess implements Action {
  readonly type = CONTACT_ADMINS_SUCCESS;
}
export class contactAdminsFail implements Action {
  readonly type = CONTACT_ADMINS_FAIL;
  constructor(public payload) { }
}
export const CONTACT_NEWS_LETTERS_REQUEST = "CONTACT_NEWS_LETTERS_REQUEST",
  CONTACT_NEWS_LETTERS_SUCCESS = "CONTACT_NEWS_LETTERS_SUCCESS",
  CONTACT_NEWS_LETTERS_FAIL = "CONTACT_NEWS_LETTERS_FAIL";

// news letters
export class contactNewsLettersRequest implements Action {
  readonly type = CONTACT_NEWS_LETTERS_REQUEST;
  constructor(public payload) { }
}
export class contactNewsLettersSuccess implements Action {
  readonly type = CONTACT_NEWS_LETTERS_SUCCESS;
}
export class contactNewsLettersFail implements Action {
  readonly type = CONTACT_NEWS_LETTERS_FAIL;
  constructor(public payload) { }
}
export interface State {
  clients: Client[];
  getClientListErrorCode: number;
  getClientListProgress: string;
  getClientListInDayError;
  getUsersNumberError;
  getCoachsNumberError;
  getClientListInDayProgress: string;
  getCoachListErrorCode: number;
  getCoachListProgress: string;
  coachs: Array<object>;
  addClientProgress: string;
  addClientErrorCode: number;
  deleteClientProgress: string;
  deleteClientError;
  updateClientProgress;
  updateClientError;
  usersNumber: number;
  coachsNumber: number;
  registerVisitorProgress;
  registerVisitorError;
  contactAdminsProgress;
  contactAdminsError;
  registerAccessToken;
  getUserDetailsProgress;
  getUserDetailsError;
  userDetails,
  contactNewsLettersProgress,
  contactNewsLettersError
}

const initialState: State = {
  getCoachsNumberError: -1,
  getUsersNumberError: -1,
  getClientListErrorCode: -1,
  getClientListProgress: "",
  getClientListInDayError: -1,
  getClientListInDayProgress: "",
  getCoachListErrorCode: -1,
  getCoachListProgress: "",
  addClientProgress: "",
  addClientErrorCode: -1,
  deleteClientProgress: "",
  deleteClientError: -1,
  updateClientError: -1,
  updateClientProgress: "",
  usersNumber: null,
  coachsNumber: null,
  clients: [],
  coachs: [],
  registerVisitorProgress: "",
  registerVisitorError: -1,
  contactAdminsError: -1,
  contactAdminsProgress: "",
  registerAccessToken: "",
  getUserDetailsProgress: "",
  getUserDetailsError: -1,
  userDetails: {},
  contactNewsLettersProgress: "",
  contactNewsLettersError: -1
}

export function clientsReducer(state = initialState, action) {
  switch (action.type) {
    // initialize error message
    case INITIALIZE_ERROR_MESSAGE:
      return {
        ...state,
        addClientProgress: "",
        addClientErrorCode: -1,
        deleteClientProgress: "",
        deleteClientError: -1,
        updateClientError: -1,
        updateClientProgress: "",
        getCoachListErrorCode: -1,
        getCoachListProgress: "",
        getClientListErrorCode: -1,
        getClientListProgress: "",
        contactAdminsProgress: "",
        contactAdminsError: -1,
        registerVisitorError: -1,
        registerVisitorProgress: "",
        contactNewsLettersError: -1,
        contactNewsLettersProgress: ""
      };
    // reinitialize user details
    case REINITIALITE_USER_DETAILS:
      return {
        ...state,
        userDetails: {}
      }
    // add client
    case ADD_CLIENT_REQUEST:
      return {
        ...state,
        addClientProgress: "loading"
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        addClientProgress: "ok"
      };
    case ADD_CLIENT_FAIL:
      return {
        ...state,
        addClientErrorCode: action.payload.error,
        addClientProgress: "error"
      };
    // get user details
    case GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        getUserDetailsProgress: "loading"
      }
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        getUserDetailsProgress: "ok",
        userDetails: action.payload.userDetails
      }
    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        getUserDetailsError: action.payload.error,
        getUserDetailsProgress: "error"
      }
    // delete client
    case DELETE_CLIENT_REQUEST:
      return {
        ...state,
        deleteClientProgress: "loading"
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        deleteClientProgress: "ok"
      };
    case DELETE_CLIENT_FAIL:
      return {
        ...state,
        deleteClientProgress: "error",
        deleteClientError: action.payload.error
      };

    // update client
    case UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        updateClientProgress: "loading"
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        updateClientProgress: "ok"
      };
    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        updateClientError: action.payload.error,
        updateClientProgress: "error"
      };
    //  get list client
    case GET_CLIENT_LIST_REQUEST:
      return {
        ...state,
        getClientListProgress: "loading"
      };
    case GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        getClientListProgress: "ok",
        clients: action.payload.clients
      };
    case GET_CLIENT_LIST_FAIL:
      return {
        ...state,
        getClientListErrorCode: action.payload.error,
        getClientListProgress: "error"
      };
    // CLIENTS LIST IN DAY
    case GET_CLIENT_LIST_IN_DAY_REQUEST:
      return {
        ...state,
        getClientListInDayProgress: "loading"
      };
    case GET_CLIENT_LIST_IN_DAY_SUCCESS:
      return {
        ...state,
        getClientListInDayProgress: "ok",
        clients: action.payload.clients
      };
    case GET_CLIENT_LIST_IN_DAY_FAIL:
      return {
        ...state,
        getClientListInDayError: action.payload.error,
        getClientListInDayProgress: "error"
      };
    //USERS NUMBER
    case GET_USERS_NUMBER_REQUEST:
      return { ...state };
    case GET_USERS_NUMBER_SUCCESS:
      return {
        ...state,
        usersNumber: action.payload.usersNumber
      };
    case GET_USERS_NUMBER_FAIL:
      return { ...state, getUsersNumberError: action.payload.error };
    // coachs number
    case GET_COACH_NUMBER_REQUEST:
      return { ...state };
    case GET_COACH_NUMBER_SUCCESS:
      return {
        ...state,
        coachsNumber: action.payload.coachsNumber
      };
    case GET_COACH_NUMBER_FAIL:
      return { ...state, getCoachsNumberError: action.payload.error };
    // coach list
    case GET_COACH_LIST_REQUEST:
      return {
        ...state,
        getCoachListProgress: "loading"
      };
    case GET_COACH_LIST_SUCCESS:
      return {
        ...state,
        getCoachListProgress: "ok",
        coachs: action.payload.coachs
      };
    case GET_COACH_LIST_FAIL:
      return {
        ...state,
        getCoachListProgress: "error",
        getCoachListErrorCode: action.payload.error
      };
    // register visitor
    case REGISTER_VISITOR_REQUEST:
      return {
        ...state,
        registerVisitorProgress: "loading"
      };
    case REGISTER_VISITOR_SUCCESS:
      return {
        ...state,
        registerVisitorProgress: "ok",
        registerAccessToken: action.payload.registerAccessToken
      }
    case REGISTER_VISITOR_FAIL:
      return {
        ...state,
        registerVisitorError: action.payload.error,
        registerVisitorProgress: "error"
      }
    // contact admins
    case CONTACT_ADMINS_REQUEST:
      return {
        ...state,
        contactAdminsProgress: "loading"
      };
    case CONTACT_ADMINS_SUCCESS:
      return {
        ...state,
        contactAdminsProgress: "ok"
      }
    case CONTACT_ADMINS_FAIL:
      return {
        ...state,
        contactAdminsError: action.payload.error,
        contactAdminsProgress: "error"
      }
    case CONTACT_NEWS_LETTERS_REQUEST:
      return {
        ...state,
        contactNewsLettersProgress: "loading"
      }
    case CONTACT_NEWS_LETTERS_SUCCESS:
      return {
        ...state,
        contactNewsLettersProgress: "ok"
      }
    case CONTACT_NEWS_LETTERS_FAIL:
      return {
        ...state,
        contactNewsLettersProgress: "error",
        contactNewsLettersError: action.payload.error
      }
    default:
      return state;
  }
}
