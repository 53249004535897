import { Component, OnInit, Input } from "@angular/core";
import { Http } from "@angular/http";
import { Store } from "@ngrx/store";
import * as fromPlanning from "../../store/Planning";

@Component({
  selector: "app-session-card",
  templateUrl: "./session-card.component.html",
  styleUrls: ["./session-card.component.scss"]
})
export class SessionCardComponent implements OnInit {
  @Input() bootcampSelected;
  french;
  sessionImageUrl;
  themes;
  constructor(public http: Http,
    private store: Store<{}>
  ) {
    this.store.dispatch(new fromPlanning.getThemesListRequest())
  }

  ngOnInit() {
    this.store.select("planning").subscribe(val => {
      this.themes = val.themes
    });    
    this.sessionImageUrl = (this.themes.filter(theme => theme.name == this.bootcampSelected.session.theme)[0] &&
    this.themes.filter(theme => theme.name == this.bootcampSelected.session.theme)[0].img ) ?
    this.themes.filter(theme => theme.name == this.bootcampSelected.session.theme)[0].img : ""
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
  }
}
