import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Http } from "@angular/http";
import { of } from "rxjs/observable/of";
import { Actions, Effect } from "@ngrx/effects";
import "rxjs/add/operator/map";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/mergeMap";
import { CookieService } from "ngx-cookie-service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { ToastrService } from 'ngx-toastr';

import { URLS } from "../../utils/URLS";
import * as paymentsActions from "../store/Payments";
import * as clientsActions from "../store/Clients";
import * as fromUI from "../store/UI";
import * as fromPlanning from "../store/Planning"
import * as fromIndex from "../../utils/main-const";


interface getList {
  data: any;
}

@Injectable()
export class PaymentsEffects {
  french;
  token = this.cookieService.get("access_token");
  constructor(
    public http: Http,
    public toastr: ToastrService,
    private modalService: NgbModal,
    private actions$: Actions,
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private store: Store<{}>
  ) {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
  }

  //get list payments
  @Effect()
  GetPaymentsList = this.actions$
    .ofType(paymentsActions.GET_PAYMENTS_LIST_REQUEST)
    .map((action: paymentsActions.GetPaymentsListRequest) => action.payload)
    .mergeMap(payload => {
      let url = URLS.PAYMENTS;
      if (payload.userId) {
        url += "?userId=" + payload.userId + "&perPage=20&page=" + payload.page;
      }
      else {
        url += "?perPage=20&page=" + payload.page;
      }
      return this.httpClient
        .get<getList>(url)
        .map(response => {
          return new paymentsActions.GetPaymentsListSuccess({
            payments: response.data
          });
        })
        .catch(error =>
          of(new paymentsActions.GetPaymentsListFail({ error: error.error }))
        );
    });

  // create manual payment
  @Effect()
  CreateManualPayment = this.actions$
    .ofType(paymentsActions.CREATE_MANUAL_PAYMENT_REQUEST)
    .map((action: paymentsActions.createManualPaymentRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<getList>(URLS.PAYMENTS + "/manual", payload.value)
        .map(response => {
          this.store.dispatch(new clientsActions.GetUserDetailsRequest({ userId: payload.value.userId }))
          this.store.dispatch(new paymentsActions.GetPaymentsListRequest({ userId: payload.value.userId, page: payload.page }));
          this.modalService.dismissAll();
          this.toastr.success(this.french.successManualPayment);
          return new paymentsActions.createManualPaymentSuccess();
        })
        .catch(error =>
          of(
            new paymentsActions.createManualPaymentFail({ error: error.error })
          )
        );
    });
  // create stripe payment
  @Effect()
  CreateStripePayment = this.actions$
    .ofType(paymentsActions.CREATE_STRIPE_PAYMENT_REQUEST)
    .map((action: paymentsActions.createStripePaymentRequest) => action.payload)
    .switchMap(payload => {
      return this.httpClient
        .post<getList>(URLS.PAYMENTS + "/buy-session-online", payload.value)
        .map(response => {
          this.store.dispatch(
            new fromUI.changeReserveBootcampStep({ reserveBootcampStep: "final" })
          );
          this.store.dispatch(
            new fromUI.changeReserveBootcampStepVisitor({
              reserveBootcampStepVisitor: "final"
            })
          );
          if (payload.startBefore && payload.endBefore) {
            this.store.dispatch(
              new fromPlanning.getSlotsListRequest({
                sessionTypeId: fromIndex.bootcampId,
                startBefore: payload.startBefore,
                endBefore: payload.endBefore
              })
            )
          }

          return new paymentsActions.createStripePaymentSuccess();
          // return [new fromUI.changeReserveBootcampStep({ reserveBootcampStep: "final" }),
          // new fromUI.changeReserveBootcampStepVisitor({ reserveBootcampStepVisitor: "final" })
          // ]
        })
        .catch(error =>
          of(
            new paymentsActions.createStripePaymentFail({ error: error.error })
          )
        );
    });
  //get  packs list
  @Effect()
  GetPacksList = this.actions$
    .ofType(paymentsActions.GET_PACKS_LIST_REQUEST)
    .switchMap(() => {
      return this.httpClient
        .get<getList>(URLS.PACKS)
        .map(response => {
          return new paymentsActions.GetPacksListSuccess({
            packsList: response.data
          });
        })
        .catch(error => of(new paymentsActions.GetPacksListFail({ error: error.error })));
    });
}
