import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Http } from "@angular/http";
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { NgbDatepickerI18n, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { isMobileDevice } from "../../../utils/main-const";
import * as datepickerFormat from "../../shared/datepicker-format"
import * as fromClients from "../../store/Clients";

@Component({
  selector: "app-update-client-modal",
  templateUrl: "./update-client-modal.component.html",
  styleUrls: ["./update-client-modal.component.scss"],
  providers: [datepickerFormat.I18n, { provide: NgbDatepickerI18n, useClass: datepickerFormat.CustomDatepickerI18n }, { provide: NgbDateParserFormatter, useClass: datepickerFormat.MyFormatter }]
})
export class UpdateClientModalComponent implements OnInit {
  @Output() onSubmitUpdate: EventEmitter<any> = new EventEmitter();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Input() clientToUpdate;
  @Input() coach = false;
  @Input() editingResponsible;
  @Input() userType;

  isMobile: boolean;
  french;
  clientsState;
  fileExisted: boolean = false;
  imageUrl;
  coachsSelected = [];
  showCoachsList = false
  updateClientStep = "first";
  address;
  options = { types: ['address'], componentRestrictions: { country: 'fr' } };
  postalCodeArray;
  street;
  zipCode;
  city

  constructor(private store: Store<{}>, public http: Http) { }

  ngOnInit() {
    this.coachsSelected = this.clientToUpdate.coachs;
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.dispatch(new fromClients.GetCoachListRequest({}));
    this.store.select("clients").subscribe(val => {
      if (val.userDetails.user) {

        this.clientToUpdate.address = (val.userDetails.user.address.street ? val.userDetails.user.address.street : "") +
          (val.userDetails.user.address.zipCode ? ("," + val.userDetails.user.address.zipCode) : "") +
          (val.userDetails.user.address.city ? ("," + val.userDetails.user.address.city) : "");
        this.street = val.userDetails.user.address.street;
        this.zipCode = val.userDetails.user.address.zipCode;
        this.city = val.userDetails.user.address.city
      }
      this.clientsState = val
    });
    this.isMobile = isMobileDevice();
  }
  handleAddressChange(address: Address) {
    let zipCode = address.address_components.filter(
      address => address.types[0] == "postal_code"
    );
    let streetNumber = address.address_components.filter(
      address => address.types[0] == "street_number"
    );
    let route = address.address_components.filter(
      address => address.types[0] == "route"
    );
    let city = address.address_components.filter(
      address => address.types[0] == "locality"
    );
    // this.address = address.formatted_address
    // this.zipCode = zipCode[0].long_name;
    // this.street = streetNumber[0].long_name + " " + route[0].long_name;
    // this.city = city[0].long_name

    this.zipCode = (zipCode.length != 0 && zipCode[0].long_name) ? zipCode[0].long_name : "";
    this.street = ((streetNumber.length != 0 && streetNumber[0].long_name) ? streetNumber[0].long_name : "") +
      " " + (route.length != 0 && route[0].long_name ? route[0].long_name : "");
    this.city = (city.length != 0 && city[0].long_name) ? city[0].long_name : ""
  }
  onClickOutside() {

    if (this.showCoachsList == true) { this.showCoachsList = false }
    else return;
  }
  nextStep() {
    this.updateClientStep = "second";
  }
  updateClientBack() {
    this.updateClientStep = "first";
  }
  selectCoach(coach) {
    this.coachsSelected.find((item) => item.id === coach.id) ?
      this.coachsSelected = this.coachsSelected :
      this.coachsSelected.push(coach);
    this.showCoachsList = false
  }
  openCoachList() {
    this.showCoachsList = true;
  }
  deleteCoach(coach) {
    this.coachsSelected = this.coachsSelected.filter((item) => item.id !== coach.id);
  }
  onSubmit(updateForm, imageUrl) {
    this.onSubmitUpdate.emit({
      updateForm, imageUrl, coachsSelected: this.coachsSelected,
      address: { zipCode: this.zipCode, street: this.street, city: this.city }
    });
  }
  cancel() {
    this.closeModal.emit();
  }

  uploadFile = () => {
    document.getElementById("file").click();
  };
  takePhoto() {
    document.getElementById("camera").click();
  }

  handleFileInput(files: FileList) {
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = e => {
      this.fileExisted = true;
      var image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = 200;
        canvas.height = 200;
        image.width = 200;
        image.height = 200;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, 200, 200);
        this.imageUrl = canvas.toDataURL("image/jpeg", 0.6);
      };
    };
    reader.readAsDataURL(files[0]);
  }
}
