import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import * as fromStatistics from "../../store/Statistics"
import { Store } from "@ngrx/store";

@Component({
  selector: "app-main-coach",
  templateUrl: "./main-coach.component.html",
  styleUrls: ["./main-coach.component.scss"]
})
export class MainCoachComponent implements OnInit {
  public french: any;
  statisticsState;

  constructor(public http: Http,
    private store: Store<{}>
  ) { }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.dispatch(new fromStatistics.getStaticsRequest())
    this.store.select("statistics").subscribe(val => (this.statisticsState = val));

  }
}
