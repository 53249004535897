import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Injectable
} from "@angular/core";
import {
  DateFormatterParams,
  CalendarNativeDateFormatter,
  CalendarDateFormatter,
  CalendarEventTitleFormatter
} from "angular-calendar";
import { CustomEventTitleFormatter } from "./custom-event-title.provider";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { Http } from "@angular/http";
import { Store } from "@ngrx/store";
import { parseFromTimeZone } from "date-fns-timezone";
import { startOfWeek, lastDayOfWeek, addDays, format } from "date-fns";
import * as fromPayments from "../../store/Payments";
import * as fromClients from "../../store/Clients";
import * as fromPlanning from "../../store/Planning";
import * as fromIndex from "../../../utils/main-const";

registerLocaleData(localeFr);
@Injectable()
export class CustomDateFormatter extends CalendarNativeDateFormatter {
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: "short" })
      .format(date)
      .substr(0, 3);
  }
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat("fr", {
      hour: "numeric",
      minute: "numeric"
    }).format(date);
  }
  public weekViewColumnSubHeader({
    date,
    locale
  }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {
      day: "2-digit",
      month: "short"
    })
      .format(date)
      .substr(0, 6);
  }
}

@Component({
  selector: "app-landing-planning",
  templateUrl: "./landing-planning.component.html",
  styleUrls: ["./landing-planning.component.scss"],
  providers: [
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ]
})
export class LandingPlanningComponent implements OnInit {
  @Input() events;
  @Output() bootcampSide = new EventEmitter<any>();
  public french: any;
  startBefore = parseFromTimeZone(new Date().toString(), {
    timeZone: "Europe/Paris"
  }).toISOString();
  endBefore = addDays(
    lastDayOfWeek(
      parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }),
      { weekStartsOn: 1 }
    ),
    1
  ).toISOString();
  previousButtonDisabled: boolean = true;
  sessionImageUrl;
  themes;
  bootcampSelected;

  locale: string = "fr";
  width: number;
  viewDate: Date = new Date();
  view: string = "week";
  isMobile;
  constructor(public http: Http, private store: Store<{}>) {
    this.store.dispatch(new fromPayments.GetPacksListRequest());
    this.isMobile = fromIndex.isMobileDevice();
  }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });

    this.store.dispatch(new fromPayments.initializeErrorMessage());
    this.store.dispatch(new fromClients.initializeErrorMessage());
    this.store.dispatch(new fromPlanning.getThemesListRequest());
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        sessionTypeId: fromIndex.bootcampId,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
    this.store.select("planning").subscribe(val => {
      this.themes = val.themes;
    });
  }

  changeWeek(event) {
    this.startBefore = startOfWeek(
      parseFromTimeZone(new Date(event).toString(), {
        timeZone: "Europe/Paris"
      }),
      { weekStartsOn: 1 }
    ).toISOString();
    this.endBefore = addDays(
      lastDayOfWeek(
        parseFromTimeZone(new Date(event).toString(), {
          timeZone: "Europe/Paris"
        }),
        { weekStartsOn: 1 }
      ),
      1
    ).toISOString();

    if (
      format(new Date(), "MM/DD/YYYY") ==
        format(new Date(event), "MM/DD/YYYY") ||
      new Date().getTime() > new Date(event).getTime()
    ) {
      this.previousButtonDisabled = true;
      this.store.dispatch(
        new fromPlanning.getSlotsListRequest({
          sessionTypeId: fromIndex.bootcampId,
          startBefore: parseFromTimeZone(new Date().toString(), {
            timeZone: "Europe/Paris"
          }).toISOString(),
          endBefore: this.endBefore
        })
      );
    } else {
      this.previousButtonDisabled = false;
      this.store.dispatch(
        new fromPlanning.getSlotsListRequest({
          sessionTypeId: fromIndex.bootcampId,
          startBefore: this.startBefore,
          endBefore: this.endBefore
        })
      );
    }
  }
  openBootcampCard($event) {
    this.bootcampSelected = $event;
    this.sessionImageUrl =
      this.themes.filter(theme => theme.name == $event.session.theme)[0] &&
      this.themes.filter(theme => theme.name == $event.session.theme)[0].img
        ? this.themes.filter(theme => theme.name == $event.session.theme)[0].img
        : "";
    this.bootcampSide.emit({
      bootcampSelected: $event,
      sessionImageUrl: this.sessionImageUrl
    });
  }
}
