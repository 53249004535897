import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { parseFromTimeZone } from "date-fns-timezone";
import { startOfWeek, lastDayOfWeek, addDays } from "date-fns";
import * as fromPlanning from "../../store/Planning";
import * as fromIndex from "../../../utils/main-const";
import * as fromPayments from "../../store/Payments";
import * as fromUI from "../../store/UI";

@Component({
  selector: "app-visitor-planning-bootcamp",
  templateUrl: "./visitor-planning-bootcamp.component.html",
  styleUrls: ["./visitor-planning-bootcamp.component.scss"]
})
export class VisitorPlanningBootcampComponent implements OnInit {
  public french: any;
  public loggedInUser: object;
  startBefore = startOfWeek(
    parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }),
    { weekStartsOn: 1 }
  ).toISOString();
  endBefore = addDays(
    lastDayOfWeek(
      parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }),
      { weekStartsOn: 1 }
    ),
    1
  ).toISOString();
  validateButtonDisabled = false;
  sessionImageUrl;
  themes;
  bootcampSelected;
  events;
  end;
  start;
  equipment;
  hoursNumber;
  price;
  step;
  userId;
  sessionId;
  planningState;
  paymentsState;
  reserveBootcampStep = "infos";
  getTokenCardError = "kil";
  bootcampPack;

  constructor(
    private modalService: NgbModal,
    public http: Http,
    private store: Store<{}>
  ) {
    this.store.dispatch(new fromPayments.GetPacksListRequest());
  }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.dispatch(new fromPayments.initializeErrorMessage());
    this.store.select("auth").subscribe(val => {
      this.loggedInUser = val.loggedInUser;
    });
    this.store.select("planning").subscribe(val => {
      this.events = val.slots.filter(slot => slot.status != "finished");
      this.themes = val.themes;
      this.planningState = val;
    });
    this.store.select("UI").subscribe(val => {
      this.reserveBootcampStep = val.reserveBootcampStep;
    });
    this.store.select("payments").subscribe(val => {
      this.paymentsState = val;
      this.bootcampPack = val.packsList.filter(
        pack => pack.sessionType.id == fromIndex.bootcampId
      );
    });
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        sessionTypeId: fromIndex.bootcampId,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
    this.store.dispatch(new fromPlanning.getThemesListRequest());
  }
  changeWeek($event) {
    let values = {
      startBefore: parseFromTimeZone($event.startBefore, {
        timeZone: "Europe/Paris"
      }).toISOString(),
      endBefore: parseFromTimeZone($event.endBefore, {
        timeZone: "Europe/Paris"
      }).toISOString()
    };
    this.startBefore = values.startBefore;
    this.endBefore = values.endBefore;
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        sessionTypeId: fromIndex.bootcampId,
        startBefore: values.startBefore,
        endBefore: values.endBefore
      })
    );
  }
  open(content, $event) {
    this.bootcampSelected = $event;
    this.sessionImageUrl = this.themes.filter(
      theme => theme.name == $event.session.theme
    )[0].img;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        result => {
          this.store.dispatch(
            new fromUI.changeReserveBootcampStep({
              reserveBootcampStep: "infos"
            })
          );
          this.store.dispatch(new fromPayments.initializeErrorMessage());
        },
        reason => {
          this.store.dispatch(
            new fromUI.changeReserveBootcampStep({
              reserveBootcampStep: "infos"
            })
          );
          this.store.dispatch(new fromPayments.initializeErrorMessage());
        }
      );
  }
  confirm() {
    this.store.dispatch(
      new fromUI.changeReserveBootcampStep({ reserveBootcampStep: "payment" })
    );
  }
  back() {
    this.store.dispatch(
      new fromUI.changeReserveBootcampStep({
        reserveBootcampStep: "infos"
      })
    );
  }
  submitStripeForm(form) {
    this.validateButtonDisabled = true;
    (<any>window).Stripe.card.createToken(
      {
        number: form.value.cardNumber,
        exp_month: form.value.expirationDate.substr(0, 2),
        exp_year: form.value.expirationDate.substr(3, 2),
        cvc: form.value.CVV
      },
      (status: number, response: any) => {
        this.validateButtonDisabled = false;

        if (status === 200) {
          let token = response.id;

          this.store.dispatch(
            new fromPayments.createStripePaymentRequest({
              value: {
                packId: this.bootcampPack[0].id,
                slotId: this.bootcampSelected.id,
                stripeToken: response.id
              },
              startBefore: this.startBefore,
              endBefore: this.endBefore
            })
          );
        } else {
          this.store.dispatch(
            new fromPayments.changeStripeTokenError({
              getTokenCardError: response.error
            })
          );
        }
      }
    );
  }
}
