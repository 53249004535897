import { Component, OnInit, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Chart } from 'angular-highcharts';
import { Http } from "@angular/http";

var monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
  "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"]
})
export class ChartComponent implements OnInit {
  @Input() data;
  french;
  weightChart;
  muscularMassChart;
  fatMassChart;
  IMCchart;
  abdominalFatMassIndexChart;
  hydrationRateChart
  public muscles;

  constructor(private store: Store<{}>,
    public http: Http,
  ) {
    this.store.select("muscles").subscribe(val => {
      this.muscles = val;
      this.weightChart = new Chart(this.createChart(this.muscles.weight, "#B4A988","kg"));
      this.muscularMassChart = new Chart(this.createChart(this.muscles.muscularMass , "#404951","%"));
      this.fatMassChart = new Chart(this.createChart(this.muscles.fatMass, "rgba(64, 73, 81, 0.5)","%"));
      this.IMCchart = new Chart(this.createChart(this.muscles.IMC, "#B4A988",""));
      this.abdominalFatMassIndexChart = new Chart(this.createChart(this.muscles.abdominalFatMassIndex, "#404951",""));
      this.hydrationRateChart = new Chart(this.createChart(this.muscles.hydrationRate, "rgba(64, 73, 81, 0.5)","%"));
    });
  }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
  }
  createChart(data, chartColor,unit) {
    return {
      chart: {
        height: 170,
        backgroundColor: {
          linearGradient: [0, 0, 500, 3000],
          stops: [[0, "#FFFFFF"], [1, "#B4A988"]]
        },
        type: "spline"
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        gridLineWidth: 2,
        gridLineDashStyle: "longdash",
        type: "category",
        labels: {

          formatter: function () {
            return new Date(this.value).getDate() + "-" + monthNames[new Date(this.value).getMonth()];
          }
        },
      },
      yAxis: [
        {
          title: {
            text: ""
          },
          gridLineWidth: 0,
          visible: false
        }
      ],
      tooltip: {
        borderWidth: 0,
        useHTML: true,

        formatter: function () {

          return (
            '<div style="padding:10px;border-radius: 2px;text-align:center;background:#DADADA"><div style="color: #b4a988;font-size: 14px;" >' +
            new Date(this.point.name).getDate() + " " + monthNames[new Date(this.point.name).getMonth()] + '</div>' +
            this.point.y + unit +
            "</div></div>"
          );
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        spline: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 5
            }
          }
        },
        series: {
          marker: {
            enabled: false,
            symbol: "circle"
          }
        }
      },
      series: [
        {
          color: chartColor,
          data: data.reverse()
        },
      ]
    }
  }
}
