import { Component, OnInit } from "@angular/core";
import * as fromPlanning from "../../store/Planning";
import { Store } from "@ngrx/store";
import { Router } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Http } from "@angular/http";
import { parseFromTimeZone } from "date-fns-timezone";
import { startOfWeek, lastDayOfWeek, addDays } from "date-fns";
import * as fromUI from "../../store/UI";
import * as fromPayments from "../../store/Payments";
import * as fromIndex from "../../../utils/main-const";

@Component({
  selector: "app-member-planning",
  templateUrl: "./member-planning.component.html",
  styleUrls: ["./member-planning.component.scss"]
})
export class MemberPlanningComponent implements OnInit {
  public french: any;
  userId;
  events;
  startBefore = startOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }).toISOString()
  endBefore = addDays(lastDayOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }), 1).toISOString()
  constructor(
    public router: Router,
    public http: Http,
    private modalService: NgbModal,
    private store: Store<{}>
  ) {
    this.store.select("auth").subscribe(val => {
      this.userId = val.loggedInUser.id;
    });
  }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("planning").subscribe(val => {
      this.events = val.slots;
    });
    this.store.dispatch(new fromPlanning.getSessionsSubscriptionsListRequest({
      userId: this.userId,
      startBefore: this.startBefore,
      endBefore: this.endBefore
    }))
  }
  changeWeek($event) {
    let values = {
      startBefore: parseFromTimeZone($event.startBefore,
        { timeZone: "Europe/Paris" }
      ).toISOString(),
      endBefore: parseFromTimeZone($event.endBefore,
        { timeZone: "Europe/Paris" }
      ).toISOString()
    }
    this.store.dispatch(
      new fromPlanning.getSessionsSubscriptionsListRequest({
        userId: this.userId,
        startBefore: values.startBefore,
        endBefore: values.endBefore
      })
    );

  }


  reserveBootcamp() {
    this.router.navigate(['/member/planning/planningBootcamp'])
  }
}
