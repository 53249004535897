import { Action } from "@ngrx/store";

export const CHANGE_RESERVE_BOOTCAMP_STEP = "CHANGE_RESERVE_BOOTCAMP_STEP";

export class changeReserveBootcampStep implements Action {
  readonly type = CHANGE_RESERVE_BOOTCAMP_STEP;
  constructor(public payload) {}
}

export const CHANGE_RESERVE_BOOTCAMP_STEP_VISITOR =
  "CHANGE_RESERVE_BOOTCAMP_STEP_VISITOR";

export class changeReserveBootcampStepVisitor implements Action {
  readonly type = CHANGE_RESERVE_BOOTCAMP_STEP_VISITOR;
  constructor(public payload) {}
}

export interface State {
  reserveBootcampStep;
  reserveBootcampStepVisitor;
}
const initialState: State = {
  reserveBootcampStep: "infos",
  reserveBootcampStepVisitor: "themeInfos"
};

export function UIReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_RESERVE_BOOTCAMP_STEP:
      return {
        ...state,
        reserveBootcampStep: action.payload.reserveBootcampStep
      };
    case CHANGE_RESERVE_BOOTCAMP_STEP_VISITOR:
      return {
        ...state,
        reserveBootcampStepVisitor: action.payload.reserveBootcampStepVisitor
      };
    default:
      return state;
  }
}
