import { Action } from "@ngrx/store";



// INITIALIZE ERROR MESSAGE
export const INITIALIZE_ERROR_MESSAGE = "INITIALIZE_ERROR_MESSAGE";
export class initializeErrorMessage implements Action {
  type = INITIALIZE_ERROR_MESSAGE;
}
// ADD SLOT
export const ADD_SLOT_REQUEST = "ADD_SLOT_REQUEST",
  ADD_SLOT_SUCCESS = "ADD_SLOT_SUCCESS",
  ADD_SLOT_FAIL = "ADD_SLOT_FAIL";
export class addSlotRequest implements Action {
  readonly type = ADD_SLOT_REQUEST;
  constructor(public payload) { }
}
export class addSlotSuccess implements Action {
  readonly type = ADD_SLOT_SUCCESS;
}
export class addSlotFail implements Action {
  readonly type = ADD_SLOT_FAIL;
  constructor(public payload) { }
}

//GET SLOTS LIST
export const GET_SLOTS_LIST_REQUEST = "GET_SLOTS_LIST_REQUEST",
  GET_SLOTS_LIST_SUCCESS = "GET_SLOTS_LIST_SUCCESS",
  GET_SLOTS_LIST_FAIL = "GET_SLOTS_LIST_FAIL";
export class getSlotsListRequest implements Action {
  readonly type = GET_SLOTS_LIST_REQUEST;
  constructor(public payload) { }
}
export class getSlotsListSuccess implements Action {
  readonly type = GET_SLOTS_LIST_SUCCESS;
  constructor(public payload) { }
}
export class getSlotsListFail implements Action {
  readonly type = GET_SLOTS_LIST_FAIL;
  constructor(public payload) { }
}

//GET FINISHED BOOTCAMP
export const GET_FINISHED_BOOTCAMP_REQUEST = "GET_FINISHED_BOOTCAMP_REQUEST",
  GET_FINISHED_BOOTCAMP_SUCCESS = "GET_FINISHED_BOOTCAMP_SUCCESS",
  GET_FINISHED_BOOTCAMP_FAIL = "GET_FINISHED_BOOTCAMP_FAIL";
export class getFinishedBootcampRequest implements Action {
  readonly type = GET_FINISHED_BOOTCAMP_REQUEST;
  constructor(public payload) { }
}
export class getFinishedBootcampSuccess implements Action {
  readonly type = GET_FINISHED_BOOTCAMP_SUCCESS;
  constructor(public payload) { }
}
export class getFinishedBootcampFail implements Action {
  readonly type = GET_FINISHED_BOOTCAMP_FAIL;
  constructor(public payload) { }
}
//GET BOOKED BOOTCAMP
export const GET_BOOKED_BOOTCAMP_REQUEST = "GET_BOOKED_BOOTCAMP_REQUEST",
  GET_BOOKED_BOOTCAMP_SUCCESS = "GET_BOOKED_BOOTCAMP_SUCCESS",
  GET_BOOKED_BOOTCAMP_FAIL = "GET_BOOKED_BOOTCAMP_FAIL";
export class getBookedBootcampRequest implements Action {
  readonly type = GET_BOOKED_BOOTCAMP_REQUEST;
  constructor(public payload) { }
}
export class getBookedBootcampSuccess implements Action {
  readonly type = GET_BOOKED_BOOTCAMP_SUCCESS;
  constructor(public payload) { }
}
export class getBookedBootcampFail implements Action {
  readonly type = GET_BOOKED_BOOTCAMP_FAIL;
  constructor(public payload) { }
}
//GET THEMES LIST
export const GET_THEMES_LIST_REQUEST = "GET_THEMES_LIST_REQUEST",
  GET_THEMES_LIST_SUCCESS = "GET_THEMES_LIST_SUCCESS",
  GET_THEMES_LIST_FAIL = "GET_THEMES_LIST_FAIL";
export class getThemesListRequest implements Action {
  readonly type = GET_THEMES_LIST_REQUEST;
}
export class getThemesListSuccess implements Action {
  readonly type = GET_THEMES_LIST_SUCCESS;
  constructor(public payload) { }
}
export class getThemesListFail implements Action {
  readonly type = GET_THEMES_LIST_FAIL;
  constructor(public payload) { }
}
// GET SESSIONS SUBSCRIPTIONS LIST
export const GET_SESSIONS_SUBSCRIPTIONS_LIST_REQUEST = "GET_SESSIONS_SUBSCRIPTIONS_LIST_REQUEST",
  GET_SESSIONS_SUBSCRIPTIONS_LIST_SUCCESS = "GET_SESSIONS_SUBSCRIPTIONS_LIST_SUCCESS",
  GET_SESSIONS_SUBSCRIPTIONS_LIST_FAIL = "GET_SESSIONS_SUBSCRIPTIONS_LIST_FAIL";

export class getSessionsSubscriptionsListRequest implements Action {
  readonly type = GET_SESSIONS_SUBSCRIPTIONS_LIST_REQUEST;
  constructor(public payload) { }
}
export class getSessionsSubscriptionsListSuccess implements Action {
  readonly type = GET_SESSIONS_SUBSCRIPTIONS_LIST_SUCCESS;
  constructor(public payload) { }
}
export class getSessionsSubscriptionsListFail implements Action {
  readonly type = GET_SESSIONS_SUBSCRIPTIONS_LIST_FAIL;
  constructor(public payload) { }
}
// CREATE SESSION
export const CREATE_SESSION_REQUEST = "CREATE_SESSION_REQUEST",
  CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS",
  CREATE_SESSION_FAIL = "CREATE_SESSION_FAIL";

export class createSessionRequest implements Action {
  readonly type = CREATE_SESSION_REQUEST;
  constructor(public payload) { }
}
export class createSessionSuccess implements Action {
  readonly type = CREATE_SESSION_SUCCESS;
}
export class createSessionFail implements Action {
  readonly type = CREATE_SESSION_FAIL;
  constructor(public payload) { }
}
// DELETE SESSION
export const DELETE_SESSION_REQUEST = "DELETE_SESSION_REQUEST",
  DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS",
  DELETE_SESSION_FAIL = "DELETE_SESSION_FAIL";
export class deleteSessionRequest implements Action {
  readonly type = DELETE_SESSION_REQUEST;
  constructor(public payload) { }
}
export class deleteSessionSuccess implements Action {
  readonly type = DELETE_SESSION_SUCCESS;
}
export class deleteSessionFail implements Action {
  readonly type = DELETE_SESSION_FAIL;
  constructor(public payload) { }
}
// CANCEL SESSION
export const CANCEL_SESSION_REQUEST = "CANCEL_SESSION_REQUEST",
  CANCEL_SESSION_SUCCESS = "CANCEL_SESSION_SUCCESS",
  CANCEL_SESSION_FAIL = "CANCEL_SESSION_FAIL";
export class cancelSessionRequest implements Action {
  readonly type = CANCEL_SESSION_REQUEST;
  constructor(public payload) { }
}
export class cancelSessionSuccess implements Action {
  readonly type = CANCEL_SESSION_SUCCESS;
}
export class cancelSessionFail implements Action {
  readonly type = CANCEL_SESSION_FAIL;
  constructor(public payload) { }
}
// CLOSE SESSION
export const CLOSE_SESSION_REQUEST = "CLOSE_SESSION_REQUEST",
  CLOSE_SESSION_SUCCESS = "CLOSE_SESSION_SUCCESS",
  CLOSE_SESSION_FAIL = "CLOSE_SESSION_FAIL";
export class closeSessionRequest implements Action {
  readonly type = CLOSE_SESSION_REQUEST;
  constructor(public payload) { }
}
export class closeSessionSuccess implements Action {
  readonly type = CLOSE_SESSION_SUCCESS;
}
export class closeSessionFail implements Action {
  readonly type = CLOSE_SESSION_FAIL;
  constructor(public payload) { }
}
//MATCH SLOT TO SESSION
export const MATCH_SLOT_TO_SESSION_REQUEST = "MATCH_SLOT_TO_SESSION_REQUEST",
  MATCH_SLOT_TO_SESSION_SUCCESS = "MATCH_SLOT_TO_SESSION_SUCCESS",
  MATCH_SLOT_TO_SESSION_FAIL = "MATCH_SLOT_TO_SESSION_FAIL";
export class matchSlotToSessionRequest implements Action {
  readonly type = MATCH_SLOT_TO_SESSION_REQUEST;
  constructor(public payload) { }
}
export class matchSlotToSessionSuccess implements Action {
  type = MATCH_SLOT_TO_SESSION_SUCCESS;
}
export class matchSlotToSessionFail implements Action {
  readonly type = MATCH_SLOT_TO_SESSION_FAIL;
  constructor(public payload) { }
}

//SUBSCRIBE USER TO SESSION
export const SUBSCRIBE_USER_TO_SESSION_REQUEST =
  "SUBSCRIBE_USER_TO_SESSION_REQUEST",
  SUBSCRIBE_USER_TO_SESSION_SUCCESS = "SUBSCRIBE_USER_TO_SESSION_SUCCESS",
  SUBSCRIBE_USER_TO_SESSION_FAIL = "SUBSCRIBE_USER_TO_SESSION_FAIL";
export class subscribeUserToSessionRequest implements Action {
  readonly type = SUBSCRIBE_USER_TO_SESSION_REQUEST;
  constructor(public payload) { }
}
export class subscribeUserToSessionSuccess implements Action {
  type = SUBSCRIBE_USER_TO_SESSION_SUCCESS;
}
export class subscribeUserToSessionFail implements Action {
  readonly type = SUBSCRIBE_USER_TO_SESSION_FAIL;
  constructor(public payload) { }
}
// select coach
export const SELECT_COACH = "SELECT_COACH";
export class selectcoach implements Action {
  readonly type = SELECT_COACH;
  constructor(public payload) { }
}
// change startBefore and endBefore dates
export const CHANGE_START_END_DATES = "CHANGE_START_END_DATES";
export class changeStartEndDates implements Action {
  readonly type = CHANGE_START_END_DATES;
  constructor(public payload) { }
}
export interface State {
  addSlotProgress: string;
  addSlotError;
  deleteSessionError;
  deleteSessionProgress;
  cancelSessionError;
  cancelSessionProgress;
  closeSessionProgress;
  closeSessionError;
  getSlotsListError;
  getSlotsListProgress;
  slots;
  getThemesListProgress;
  getThemesListError;
  themes;
  createSessionProgress;
  createSessionError;
  matchSlotProgress;
  matchSlotError;
  getSessionsSubscriptionsListProgress;
  getSessionsSubscriptionsListError;
  subscribeUserToSessionError;
  subscribeUserToSessionProgress;
  getFinishedBootcampProgress;
  getFinishedBootcampError;
  finishedBootcampList;
  getBookedBootcampProgress;
  getBookedBootcampError;
  bookedBootcampList,
  selectedCoach,
  startBefore,
  endBefore
}

const initialState: State = {
  getSlotsListError: -1,
  getSlotsListProgress: "ok",
  slots: [],
  getThemesListProgress: "",
  getThemesListError: -1,
  themes: [],
  addSlotError: -1,
  addSlotProgress: "ok",
  deleteSessionError: -1,
  deleteSessionProgress: "",
  cancelSessionError: -1,
  cancelSessionProgress: "",
  closeSessionProgress: "",
  closeSessionError: -1,
  createSessionError: -1,
  createSessionProgress: "",
  matchSlotError: -1,
  matchSlotProgress: "",
  getSessionsSubscriptionsListProgress: "",
  getSessionsSubscriptionsListError: -1,
  subscribeUserToSessionError: -1,
  subscribeUserToSessionProgress: "",
  getFinishedBootcampProgress: "",
  getFinishedBootcampError: -1,
  finishedBootcampList: [],
  getBookedBootcampProgress: "",
  getBookedBootcampError: -1,
  bookedBootcampList: [],
  selectedCoach: "",
  startBefore: "",
  endBefore: ""
};

export function planningReducer(state = initialState, action) {
  switch (action.type) {
    // initialize error message
    case INITIALIZE_ERROR_MESSAGE:
      return {
        ...state,
        addSlotError: -1,
        addSlotProgress: "",
        createSessionError: -1,
        createSessionProgress: "",
        getSessionsListError: -1,
        subscribeUserToSessionError: -1,
        subscribeUserToSessionProgress: ""
      };
    //GET THEMES LIST
    case GET_THEMES_LIST_REQUEST:
      return {
        ...state,
        getThemesListProgress: "loading"
      };
    case GET_THEMES_LIST_SUCCESS:
      return {
        ...state,
        getThemesListProgress: "ok",
        themes: action.payload.themes
      };
    case GET_THEMES_LIST_FAIL:
      return {
        ...state,
        getThemesListError: action.payload.error,
        getThemesListProgress: "error"
      };
    //GET SLOTS LIST
    case GET_SLOTS_LIST_REQUEST:
      return {
        ...state,
        getSlotsListProgress: "loading"
      };
    case GET_SLOTS_LIST_SUCCESS:
      return {
        ...state,
        getSlotsListProgress: "ok",
        slots: action.payload.slots
      };
    case GET_SLOTS_LIST_FAIL:
      return {
        ...state,
        getSlotsListError: action.payload.error,
        getSlotsListProgress: "error"
      };
    //GET FINISHED BOOTCAMP
    case GET_FINISHED_BOOTCAMP_REQUEST:
      return {
        ...state,
        getFinishedBootcampProgress: "loading"
      };
    case GET_FINISHED_BOOTCAMP_SUCCESS:
      return {
        ...state,
        getFinishedBootcampProgress: "ok",
        finishedBootcampList: action.payload.finishedBootcampList
      };
    case GET_FINISHED_BOOTCAMP_FAIL:
      return {
        ...state,
        getFinishedBootcampError: action.payload.error,
        getFinishedBootcampProgress: "error"
      };
    //GET BOOKED BOOTCAMP
    case GET_BOOKED_BOOTCAMP_REQUEST:
      return {
        ...state,
        getBookedBootcampProgress: "loading"
      };
    case GET_BOOKED_BOOTCAMP_SUCCESS:
      return {
        ...state,
        getBookedBootcampProgress: "ok",
        bookedBootcampList: action.payload.bookedBootcampList
      };
    case GET_BOOKED_BOOTCAMP_FAIL:
      return {
        ...state,
        getBookedBootcampError: action.payload.error,
        getBookedBootcampProgress: "error"
      };
    // ADD SLOT
    case ADD_SLOT_REQUEST:
      return {
        ...state,
        addSlotProgress: "loading"
      };
    case ADD_SLOT_SUCCESS:
      return {
        ...state,
        addSlotProgress: "ok"
      };
    case ADD_SLOT_FAIL:
      return {
        ...state,
        addSlotError: action.payload.error,
        addSlotProgress: "error"
      };
    // DELETE SESSION
    case DELETE_SESSION_REQUEST:
      return {
        ...state,
        deleteSessionProgress: "loading"
      };
    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        deleteSessionProgress: "ok"
      };
    case DELETE_SESSION_FAIL:
      return {
        ...state,
        deleteSessionError: action.payload.error,
        deleteSessionProgress: "error"
      };
    // CANCEL SESSION
    case CANCEL_SESSION_REQUEST:
      return {
        ...state,
        cancelSessionProgress: "loading"
      };
    case CANCEL_SESSION_SUCCESS:
      return {
        ...state,
        cancelSessionProgress: "ok"
      };
    case CANCEL_SESSION_FAIL:
      return {
        ...state,
        cancelSessionError: action.payload.error,
        cancelSessionProgress: "error"
      };
    // CLOSE SESSION
    case CLOSE_SESSION_REQUEST:
      return {
        ...state,
        closeSessionProgress: "loading"
      };
    case CLOSE_SESSION_SUCCESS:
      return {
        ...state,
        closeSessionProgress: "ok"
      };
    case CLOSE_SESSION_FAIL:
      return {
        ...state,
        closeSessionError: action.payload.error,
        closeSessionProgress: "error"
      };
    //  GET SESSIONS SUBSCRIPTIONS LIST
    case GET_SESSIONS_SUBSCRIPTIONS_LIST_REQUEST:
      return {
        ...state,
        getSessionsSubscriptionsListProgress: "loading"
      };
    case GET_SESSIONS_SUBSCRIPTIONS_LIST_SUCCESS:
      return {
        ...state,
        getSessionsSubscriptionsListProgress: "ok",
        slots: action.payload.sessions
      };
    case GET_SESSIONS_SUBSCRIPTIONS_LIST_FAIL:
      return {
        ...state,
        getSessionsSubscriptionsListError: action.payload.error,
        getSessionsSubscriptionsListProgress: "error"
      };
    // CREATE SESSION
    case CREATE_SESSION_REQUEST:
      return {
        ...state,
        createSessionProgress: "loading"
      };
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        createSessionProgress: "ok"
      };
    case CREATE_SESSION_FAIL:
      return {
        ...state,
        createSessionError: action.payload.error,
        createSessionProgress: "error"
      };

    // MATCH SLOT TO SESSION
    case MATCH_SLOT_TO_SESSION_REQUEST:
      return {
        ...state,
        matchSlotProgress: "loading"
      };
    case MATCH_SLOT_TO_SESSION_SUCCESS:
      return {
        ...state,
        matchSlotProgress: "ok"
      };
    case MATCH_SLOT_TO_SESSION_FAIL:
      return {
        ...state,
        matchSlotError: action.payload.error,
        matchSlotProgress: "error"
      };
    // SUBSCRIBE USER TO SESSION
    case SUBSCRIBE_USER_TO_SESSION_REQUEST:
      return {
        ...state,
        subscribeUserToSessionProgress: "loading"
      };
    case SUBSCRIBE_USER_TO_SESSION_SUCCESS:
      return {
        ...state,
        subscribeUserToSessionProgress: "ok"
      };
    case SUBSCRIBE_USER_TO_SESSION_FAIL:
      return {
        ...state,
        subscribeUserToSessionError: action.payload.error,
        subscribeUserToSessionProgress: "error"
      };
    case SELECT_COACH:
      return {
        ...state,
        selectedCoach: action.payload.selectedCoach
      }
    case CHANGE_START_END_DATES:
      return {
        ...state,
        startBefore: action.payload.startBefore,
        endBefore: action.payload.endBefore
      }
    default:
      return state;
  }
}

