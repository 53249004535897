import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { Store } from "@ngrx/store";
import * as fromMuscles from "../../store/Muscles";
import * as fromClients from "../../store/Clients";

@Component({
  selector: "app-member-board-table",
  templateUrl: "./member-board-table.component.html",
  styleUrls: ["./member-board-table.component.scss"],
  animations: [
    trigger("periodChartSelect", [
      state(
        "an-off, void",
        style({
          overflow: "visible",
          height: AUTO_STYLE
        })
      ),
      state(
        "an-animate",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      transition("an-off <=> an-animate", [animate("400ms ease-in-out")])
    ])
  ]
})
export class MemberBoardTableComponent implements OnInit {
  public french: any;
  public selectPeriod: string = "an-animate";
  public periodChart;
  id: string;
  userDetails;
  hydrationRate ;
  current: number = 30;
  constructor(
    public http: Http,
    private store: Store<{}>
  ) {
    this.store.select("auth").subscribe(val => {
      this.id = val.loggedInUser.id;
    });
  }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
      this.periodChart = this.french.thisWeek;
    });
    this.store.dispatch(new fromClients.GetUserDetailsRequest({ userId: this.id }))
    this.store.select("clients").subscribe(val => {
      this.userDetails = val.userDetails;
      if (val.userDetails.healthInfo && val.userDetails.healthInfo[0].hydrationRate) {
        this.hydrationRate = val.userDetails.healthInfo[0].hydrationRate
      }
    });
  }

  toggleSelectPaymentsList() {
    this.selectPeriod =
      this.selectPeriod === "an-off" ? "an-animate" : "an-off";
  }
  selectPeriodChoiceWeek(value) {
    this.periodChart = value;
    this.store.dispatch(
      new fromMuscles.ToggleMonthMuscles({
        actualWeight: [
          ["LUN", 1],
          ["MAR", 2],
          ["MER", 4],
          ["JEU", 1],
          ["VEN", 6],
          ["SAM", 3],
          ["DIM", 6]
        ],
        burnedCalories: [
          ["LUN", 2],
          ["MAR", 1],
          ["MER", 3],
          ["JEU", 6],
          ["VEN", 1],
          ["SAM", 0],
          ["DIM", 6]
        ],
        averageWeight: [
          ["LUN", 0],
          ["MAR", 3],
          ["MER", 5],
          ["JEU", 2],
          ["VEN", 4],
          ["SAM", 1],
          ["DIM", 3]
        ]
      })
    );
  }
  selectPeriodChoiceMonth(value) {
    this.periodChart = value;
    this.store.dispatch(
      new fromMuscles.ToggleMonthMuscles({
        actualWeight: [
          ["01 oct", 1],
          ["02 oct", 2],
          ["03 oct", 4],
          ["04 oct", 1],
          ["05 oct", 6],
          ["06 oct", 3],
          ["07 oct", 6],
          ["08 oct", 10],
          ["09 oct", 15],
          ["10 oct", 20],
          ["11 oct", 33],
          ["12 oct", 9],
          ["13 oct", 12],
          ["14 oct", 16],
          ["15 oct", 20],
          ["16 oct", 6],
          ["17 oct", 15],
          ["18 oct", 13],
          ["19 oct", 23],
          ["20 oct", 10],
          ["21 oct", 12],
          ["22 oct", 22],
          ["23 oct", 12],
          ["24 oct", 36],
          ["25 oct", 12],
          ["26 oct", 14],
          ["27 oct", 3],
          ["28 oct", 6],
          ["29 oct", 24],
          ["30 oct", 23]
        ],
        burnedCalories: [
          ["01 oct", 75],
          ["02 oct", 12],
          ["03 oct", 15],
          ["04 oct", 26],
          ["05 oct", 61],
          ["06 oct", 35],
          ["07 oct", 16],
          ["08 oct", 35],
          ["09 oct", 13],
          ["10 oct", 3],
          ["11 oct", 32],
          ["12 oct", 53],
          ["13 oct", 73],
          ["14 oct", 35],
          ["15 oct", 16],
          ["16 oct", 45],
          ["17 oct", 54],
          ["18 oct", 78],
          ["19 oct", 87],
          ["20 oct", 15],
          ["21 oct", 6],
          ["22 oct", 26],
          ["23 oct", 69],
          ["24 oct", 49],
          ["25 oct", 59],
          ["26 oct", 28],
          ["27 oct", 15],
          ["28 oct", 6],
          ["29 oct", 23],
          ["30 oct", 10]
        ],
        averageWeight: [
          ["01 oct", 1],
          ["02 oct", 2],
          ["03 oct", 4],
          ["04 oct", 1],
          ["05 oct", 6],
          ["06 oct", 3],
          ["07 oct", 6],
          ["08 oct", 3],
          ["09 oct", 3],
          ["10 oct", 3],
          ["11 oct", 3],
          ["12 oct", 3],
          ["13 oct", 3],
          ["14 oct", 3],
          ["15 oct", 3],
          ["16 oct", 3],
          ["17 oct", 3],
          ["18 oct", 3],
          ["19 oct", 3],
          ["20 oct", 3],
          ["21 oct", 3],
          ["22 oct", 3],
          ["23 oct", 3],
          ["24 oct", 3],
          ["25 oct", 3],
          ["26 oct", 3],
          ["27 oct", 3],
          ["28 oct", 3],
          ["29 oct", 3],
          ["30 oct", 3]
        ]
      })
    );
  }
}
