import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import { AuthService } from "./auth.service";
import { Store } from "@ngrx/store";
import { URLS } from "../../utils/URLS";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isLoggedIn;
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private store: Store<{}>
  ) {
    this.store.select("auth").subscribe(val => {
      this.isLoggedIn = val.isLoggedIn;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.cookieService.get("access_token")}`
      }
    });

    return next.handle(request).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && request.url != URLS.LOGIN) {
            this.authService
              .isAuthenticated(
                this.isLoggedIn,
                this.cookieService.get("email1"),
                this.cookieService.get("refresh_token"),
                this.cookieService.get("expires_In")
              )
              .subscribe();
          }
        }
      }
    );
  }
}
