import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import * as fromClients from "../../store/Clients";
import { Store } from "@ngrx/store";
import * as fromStatistics from "../../store/Statistics"

@Component({
  selector: "app-main-admin",
  templateUrl: "./main-admin.component.html",
  styleUrls: ["./main-admin.component.scss"]
})
export class MainAdminComponent implements OnInit {
  public french: any;
  clientsState;
  statisticsState;
  constructor(
    public http: Http,
    private store: Store<{
      clients: fromClients.State;
    }>
  ) { }

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("clients").subscribe(val => (this.clientsState = val));
    this.store.select("statistics").subscribe(val => (this.statisticsState = val));
    // this.store.dispatch(new fromClients.GetUsersNumberRequest());
    // this.store.dispatch(new fromClients.GetCoachNumberRequest());
    this.store.dispatch(new fromStatistics.getStaticsRequest())
  }
}
