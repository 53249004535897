import { Action } from "@ngrx/store";

export const GET_STATISTICS_REQUEST = "GET_STATISTICS_REQUEST";
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";
export const GET_STATISTICS_FAIL = "GET_STATISTICS_FAIL";
export class getStaticsRequest implements Action {
    readonly type = GET_STATISTICS_REQUEST;
}
export class getStaticsSuccess implements Action {
    readonly type = GET_STATISTICS_SUCCESS;
    constructor(public payload) { }
}

export class getStaticsFail implements Action {
    readonly type = GET_STATISTICS_FAIL;
    constructor(public payload) { }
}
export interface State {
    getStaticsError;
    statistics
}

const initialState: State = {
    getStaticsError: -1,
    statistics: {}
};

export function statisticsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_STATISTICS_REQUEST:
            return {
                ...state
            };
        case GET_STATISTICS_SUCCESS:
            return {
                ...state,
                statistics: action.payload.statistics
            };
        case GET_STATISTICS_FAIL:
            return {
                ...state,
                getStaticsError: action.payload.error
            };
        default:
            return state;
    }
}

