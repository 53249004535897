import { Component, OnInit } from "@angular/core";
import { MenuItems } from "../shared/menu-items/menu-items";

@Component({
  selector: "app-assistant",
  templateUrl: "./assistant.component.html",
  styleUrls: ["./assistant.component.scss"]
})
export class AssistantComponent implements OnInit {
  constructor(public menuItems: MenuItems) {}

  ngOnInit() {}
}
