import { Action } from "@ngrx/store";

export const TOGGLE_WEEK_MUSCLES = "TOGGLE_WEEK_MUSCLES";
export const TOGGLE_MONTH_MUSCLES = "TOGGLE_MONTH_MUSCLES";

export class ToggleWeekMuscles implements Action {
  readonly type = TOGGLE_WEEK_MUSCLES;
  constructor(public payload) { }
}

export class ToggleMonthMuscles implements Action {
  readonly type = TOGGLE_MONTH_MUSCLES;
  constructor(public payload) { }
}

export const DISPATCH_CHART_DATA = "DISPATCH_CHART_DATA";
export class dispatchChartData implements Action {
  readonly type = DISPATCH_CHART_DATA;
  constructor(public payload) { }
}
export interface State {
  muscles: object;
  chartData,
  muscularMass,
  fatMass,
  weight,
  IMC,
  abdominalFatMassIndex,
  hydrationRate,
}

const initialState: State = {
  chartData: {},
  muscularMass: [],
  fatMass: [],
  weight: [],
  IMC: [],
  abdominalFatMassIndex: [],
  hydrationRate: [],
  muscles: {
    actualWeight: [
      ["2019-01-09", 1],
      ["2019-01-08", 2],
      ["2019-01-07", 4],
      ["2019-01-06", 1],
      ["2019-01-05", 6],
      ["2019-01-04", 3],
      ["2019-01-03", 6]
    ],
    burnedCalories: [
      ["2019-01-09", 2],
      ["2019-01-08", 1],
      ["2019-01-07", 3],
      ["2019-01-06", 6],
      ["2019-01-05", 1],
      ["2019-01-04", 0],
      ["2019-01-03", 6]
    ],
    averageWeight: [
      ["2019-01-09", 0],
      ["2019-01-08", 3],
      ["2019-01-07", 5],
      ["2019-01-06", 2],
      ["2019-01-05", 4],
      ["2019-01-04", 1],
      ["2019-01-03", 3]
    ]
  }
};

export function musclesReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_WEEK_MUSCLES:
      return {
        ...state,
        muscles: action.payload
      };
    case TOGGLE_MONTH_MUSCLES:
      return {
        ...state,
        muscles: action.payload
      };
    case DISPATCH_CHART_DATA:
      return {
        ...state,
        muscles: action.payload,
        chartData: action.payload.chartData.forEach((element, i) => {
          let date = element.createdAt
          let muscular = [date, element.muscularMass];
          let weight = [date, element.weight];
          let fat = [date, element.fatMass];
          let IMC = [date, element.IMC];
          let abdominalFatMassIndex = [date, element.abdominalFatMassIndex];
          let hydrationRate = [date, element.hydrationRate];

          state.muscularMass[i] = muscular;
          state.weight[i] = weight;
          state.fatMass[i] = fat;
          state.IMC[i] = IMC;
          state.abdominalFatMassIndex[i] = abdominalFatMassIndex;
          state.hydrationRate[i] = hydrationRate;

        })
      }
    default:
      return state;
  }
}

export type authActions = ToggleWeekMuscles | ToggleMonthMuscles;
