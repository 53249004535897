import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { of } from "rxjs/observable/of";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { URLS } from "../../utils/URLS";

interface refreshResponse {
  data: any;
}
@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient,
    private router: Router
  ) { }
  isAuthenticated(isLoggedIn, email, refreshToken, expires_In): Observable<boolean> {
    if (!isLoggedIn) {
      // this.router.navigate(["/"]);
      return of(false);
    } else if (new Date(expires_In).getTime() < new Date().getTime()) {

      // return of(true)
      return this.httpClient
        .post<refreshResponse>(URLS.REFRESH_TOKEN, {
          email,
          refreshToken
        })
        .map(response => {
          this.cookieService.set(
            "access_token",
            response.data.accessToken,
            new Date().getDate() + 7,
            "/"
            , '', false, 'Lax'
          );
          this.cookieService.set(
            "refresh_token",
            response.data.refreshToken,
            new Date().getDate() + 7,
            "/", '', false, 'Lax'
          );
          this.cookieService.set(
            "expires_In",
            response.data.expiresIn,
            new Date().getDate() + 7,
            "/", '', false, 'Lax'
          );
          return true;
        })
        .catch(e => {
          return of(false);
        });
    }
    else return of(true)
  }
}
