/// <reference types="@types/googlemaps" />
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterContentInit,
  Renderer2,
  ChangeDetectorRef
} from "@angular/core";
import {
  TimelineMax,
  Power4,
  Power3,
  Linear,
  TweenMax,
  Elastic,
  ExpoScaleEase
} from "gsap/all";
// import {} from "googlemaps";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Http } from "@angular/http";
import { Store } from "@ngrx/store";
import * as fromAuth from "../store/Auth";
import * as fromPlanning from "../store/Planning";
import * as fromClients from "../store/Clients";
import * as fromPayments from "../store/Payments";
import * as fromUI from "../store/UI";
import * as fromIndex from "../../utils/main-const";
import Swiper from "swiper";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, AfterContentInit {
  public french: any;
  @ViewChild("myVideo") myVideo: any;
  deciplusLoginIframe = false;
  deciplusCalendarIframe = false;
  validateButtonDisabled = false;
  reserveBootcampStepVisitor;
  bootcampSelected;
  sessionImageUrl;
  loginErrorCode;
  events;
  isMobile;
  user;
  eventsOnMobile;
  clientsState;
  authState;
  paymentsState;
  bootcampPack;
  visitorInfos = {
    lname: "",
    fname: "",
    email: "",
    phoneNumber: "",
    address: ""
  };
  tlm;
  tlx;
  nosServices = "/assets/images/cours_particuliers.jpg";
  openSalle: boolean = false;
  isBecomeMember: Boolean;
  sidebarOpen: boolean = false;
  popupSide: boolean = false;
  navbarOpen = false;
  tlMember;
  swiepePop;
  menu = new TimelineMax({ paused: true, reversed: true });
  @ViewChild("home") home: ElementRef;
  @ViewChild("histoire") histoire: ElementRef;
  @ViewChild("equipe") equipe: ElementRef;
  @ViewChild("salle") salle: ElementRef;
  @ViewChild("services") services: ElementRef;
  @ViewChild("contactez") contactez: ElementRef;
  @ViewChild("bootcampCalendar") bootcampCalendar: ElementRef;
  @ViewChild("becomeMember") becomeMember: ElementRef;
  @ViewChild("lsLogo") lsLogo: ElementRef;
  @ViewChild("socialIcons") socialIcons: ElementRef;
  @ViewChild("logoImg") logoImg: ElementRef;
  @ViewChild("logo") logo: ElementRef;
  @ViewChild("loginForm") loginForm: ElementRef;
  @ViewChild("mainMenu") mainMenu: ElementRef;
  @ViewChild("pageInfo") pageInfo: ElementRef;
  @ViewChild("box") box: ElementRef;
  @ViewChild("boxContent") boxContent: ElementRef;
  @ViewChild("boxContentTitle") boxContentTitle: ElementRef;
  @ViewChild("boxDescription") boxDescription: ElementRef;
  @ViewChild("loginTitle") loginTitle: ElementRef;
  @ViewChild("form") form: ElementRef;
  @ViewChild("humberger") humberger: ElementRef;
  @ViewChild("mainNav") mainNav: ElementRef;
  @ViewChild("coachSportif") coachSportif: ElementRef;
  @ViewChild("rightBtn") rightBtn: ElementRef;
  @ViewChild("leftBtn") leftBtn: ElementRef;
  @ViewChild("pageContent") pageContent: ElementRef;
  @ViewChild("mainFooter") mainFooter: ElementRef;
  @ViewChild("blackPopup") blackPopup: ElementRef;
  @ViewChild("bootcampSide") bootcampSide: ElementRef;

  @ViewChild("coachOver") coachOver: ElementRef;
  @ViewChild("retourPageHome") retourPageHome: ElementRef;
  menuItems = [
    { name: "Accueil", step: "home" },
    { name: "Nos Services", step: "services" },
    { name: "La Salle", step: "salle" },
    { name: "Contactez-nous", step: "contactez" }
  ];
  selectedIndex = 0;

  openDeciplusLogin() {
    this.deciplusLoginIframe = true
  }

  closeDeciplusLogin() {
    this.deciplusLoginIframe = false
  }

  openDeciplusCalendar() {
    this.deciplusCalendarIframe = true
  }

  closeDeciplusCalendar() {
    this.deciplusCalendarIframe = false
  }



  toggleClass(item, el, i) {
    this.selectedIndex = i;
    if (el === this.home.nativeElement.id) {
      this.myVideo.nativeElement.style.opacity = 1;
      this.myVideo.nativeElement.play();
      this.navigation(this.home.nativeElement);
    } else if (el === this.salle.nativeElement.id) {
      this.myVideo.nativeElement.pause();
      this.myVideo.nativeElement.style.opacity = 0;
      this.navigation(this.salle.nativeElement);
    } else if (el === this.services.nativeElement.id) {
      this.myVideo.nativeElement.pause();
      this.myVideo.nativeElement.style.opacity = 0;
      this.navigation(this.services.nativeElement);
    } else if (el === this.contactez.nativeElement.id) {
      this.myVideo.nativeElement.pause();
      this.myVideo.nativeElement.style.opacity = 0;
      this.navigation(this.contactez.nativeElement);
    } else if (el === this.bootcampCalendar.nativeElement.id) {
      this.navigation(this.bootcampCalendar.nativeElement);
    } else if (el === this.becomeMember.nativeElement.id) {
      this.navigation(this.becomeMember.nativeElement);
    }
  }

  constructor(
    private elementRef: ElementRef,
    public http: Http,
    private store: Store<{ auth: fromAuth.State }>,
    private renderer: Renderer2,
    private modalService: NgbModal
  ) {
    this.isMobile = fromIndex.isMobileDevice();
  }
  ngOnInit() {
    // Starting location
    var location = new google.maps.LatLng(48.86166, 2.36938);

    const map = new google.maps.Map(document.getElementById("customMap"), {
      center: { lat: 48.86166, lng: 2.36938 },

      zoom: 12,
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#212121"
            }
          ]
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575"
            }
          ]
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#212121"
            }
          ]
        },
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              color: "#757575"
            }
          ]
        },
        {
          featureType: "administrative.country",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e"
            }
          ]
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd"
            }
          ]
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575"
            }
          ]
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#181818"
            }
          ]
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161"
            }
          ]
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#1b1b1b"
            }
          ]
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#2c2c2c"
            }
          ]
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#8a8a8a"
            }
          ]
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#373737"
            }
          ]
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#3c3c3c"
            }
          ]
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry",
          stylers: [
            {
              color: "#4e4e4e"
            }
          ]
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161"
            }
          ]
        },
        {
          featureType: "transit",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575"
            }
          ]
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000"
            }
          ]
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#3d3d3d"
            }
          ]
        }
      ]
    });
    new google.maps.Marker({
      position: { lat: 48.86166, lng: 2.36938 },
      map: map
    });

    // Set custom styles on map
    // map.setOptions({ styles });
    //
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.dispatch(
      new fromUI.changeReserveBootcampStepVisitor({
        reserveBootcampStepVisitor: "themeInfos"
      })
    );
    this.store.select("payments").subscribe(val => {
      this.paymentsState = val;
      this.bootcampPack = val.packsList.filter(
        pack => pack.sessionType.id == fromIndex.bootcampId
      );
    });
    this.store.select("UI").subscribe(val => {
      this.reserveBootcampStepVisitor = val.reserveBootcampStepVisitor;
    });
    this.store.dispatch(new fromClients.initializeErrorMessage());
    this.store.select("planning").subscribe(val => {
      this.events = val.slots;

      // this gives an object with dates as keys
      const groups = this.events.reduce((groups, game) => {
        const date = game.start.toISOString().split("T")[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      this.eventsOnMobile = Object.keys(groups)
        .map(date => {
          return {
            date,
            events: groups[date]
          };
        })
        .map(event => {
          return {
            ...event,
            events: event.events.reverse()
          };
        })
        .reverse();
    });

    this.store.select("clients").subscribe(val => (this.clientsState = val));
    this.store.dispatch(new fromAuth.ReinitializeErrorCode());
    this.store.select("auth").subscribe(val => {
      this.loginErrorCode = val.loginErrorCode;
      this.authState = val;
    });
  }

  moveToSelected(element) {
    if (element == "next" && document.querySelector(".selected").nextSibling) {
      var selected = document.querySelector(".selected").nextSibling;
    }
    if (
      element == "prev" &&
      document.querySelector(".selected").previousSibling
    ) {
      var selected = document.querySelector(".selected").previousSibling;
    }
    // else {
    //   var selected = element;
    // }
    if (selected.nextSibling) {
      var next = selected.nextSibling;
    }
    if (selected.previousSibling) {
      var prev = selected.previousSibling;
    }
    if (selected.previousSibling && selected.previousSibling.previousSibling) {
      var prevSecond = prev.previousSibling;
    }
    if (selected.nextSibling && selected.nextSibling.nextSibling) {
      var nextSecond = next.nextSibling;
    }
    if (selected) {
      this.renderer.removeAttribute(selected, "class");
      this.renderer.addClass(selected, "selected");
    }

    if (prev) {
      this.renderer.removeAttribute(prev, "class");
      this.renderer.addClass(prev, "prev");
    }
    if (next) {
      this.renderer.removeAttribute(next, "class");
      this.renderer.addClass(next, "next");
    }

    if (nextSecond) {
      this.renderer.removeAttribute(nextSecond, "class");
      this.renderer.addClass(nextSecond, "nextRightSecond");
    }

    if (prevSecond) {
      this.renderer.removeAttribute(prevSecond, "class");
      this.renderer.addClass(prevSecond, "prevLeftSecond");
    }
    if (nextSecond && nextSecond.nextSibling) {
      this.renderer.removeAttribute(nextSecond.nextSibling, "class");
      this.renderer.addClass(nextSecond.nextSibling, "hideRight");
    }
    if (prevSecond && prevSecond.previousSibling) {
      this.renderer.removeAttribute(prevSecond.previousSibling, "class");
      this.renderer.addClass(prevSecond.previousSibling, "hideLeft");
    }
    // document.querySelector()
    // nextSecond.nextAll().removeClass().addClass('hideRight');
    // prevSecond[0].prevAll().removeClass().addClass('hideLeft');
  }

  previousPicture() {
    this.moveToSelected("prev");
  }

  nextPicture() {
    this.moveToSelected("next");
  }

  /* It will be triggered on every slide*/
  openSide($event) {
    this.bootcampSelected = $event.bootcampSelected;
    this.sessionImageUrl = $event.sessionImageUrl;
    this.popupSide = true;
    let tl = new TimelineMax();
    tl.to(this.bootcampSide.nativeElement, 0.5, {
      marginLeft: 0,
      className: "+=active",
      ease: Power4.easeInOut
    });
    // tl.set(this.bootcampSide.nativeElement, { className: "+=active" });
  }
  opneBootcampCard(content, $event) {
    this.bootcampSelected = $event.bootcampSelected;
    this.sessionImageUrl = $event.sessionImageUrl;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        windowClass: "custom-modal"
      })
      .result.then(
        () => {
          this.store.dispatch(
            new fromUI.changeReserveBootcampStepVisitor({
              reserveBootcampStepVisitor: "themeInfos"
            })
          );
          this.store.dispatch(new fromClients.initializeErrorMessage());
        },
        () => {
          this.store.dispatch(
            new fromUI.changeReserveBootcampStepVisitor({
              reserveBootcampStepVisitor: "themeInfos"
            })
          );
          this.visitorInfos = {
            lname: "",
            fname: "",
            email: "",
            phoneNumber: "",
            address: ""
          };
          this.store.dispatch(new fromClients.initializeErrorMessage());
        }
      );
  }

  reserve() {
    this.store.dispatch(
      new fromUI.changeReserveBootcampStepVisitor({
        reserveBootcampStepVisitor: "information"
      })
    );
  }
  onSubmitInformation($event) {
    this.visitorInfos = $event.form.value;
    this.user = {
      fname: $event.form.value.fname,
      lname: $event.form.value.lname,
      email: $event.form.value.email,
      phoneNumber: $event.form.value.phoneNumber,
      ...($event.zipCode != "" ? { zipCode: $event.zipCode } : {}),
      ...($event.street != "" ? { street: $event.street } : {}),
      ...($event.city != "" ? { city: $event.city } : {})
    };
    this.store.dispatch(
      new fromAuth.checkEmailRequest({
        value: { email: $event.form.value.email }
      })
    );
    // this.store.dispatch(
    //   new fromClients.registerVisitorRequest({
    //     fname: $event.form.value.fname,
    //     lname: $event.form.value.lname,
    //     email: $event.form.value.email,
    //     phoneNumber: $event.form.value.phoneNumber,
    //     ...($event.zipCode != "" ? { zipCode: $event.zipCode } : {}),
    //     ...($event.street != "" ? { street: $event.street } : {}),
    //     ...($event.city != "" ? { city: $event.city } : {})
    //   })
    // );
  }
  back() {
    this.store.dispatch(
      new fromUI.changeReserveBootcampStepVisitor({
        reserveBootcampStepVisitor: "information"
      })
    );
  }
  informationBack() {
    this.store.dispatch(
      new fromUI.changeReserveBootcampStepVisitor({
        reserveBootcampStepVisitor: "themeInfos"
      })
    );
  }
  submitStripeForm(form) {
    this.validateButtonDisabled = true;
    (<any>window).Stripe.card.createToken(
      {
        number: form.value.cardNumber,
        exp_month: form.value.expirationDate.substr(0, 2),
        exp_year: form.value.expirationDate.substr(3, 2),
        cvc: form.value.CVV
      },
      (status: number, response: any) => {
        this.validateButtonDisabled = false;
        if (status === 200) {
          let token = response.id;
          this.store.dispatch(
            new fromPayments.createStripePaymentRequest({
              value: {
                packId: this.bootcampPack[0].id,
                slotId: this.bootcampSelected.id,
                stripeToken: response.id,
                user: this.user
              }
            })
          );
        } else {
          this.store.dispatch(
            new fromPayments.changeStripeTokenError({
              getTokenCardError: response.error
            })
          );
        }
      }
    );
  }
  ngAfterContentInit(): void {
    var galleryTop = new Swiper(".gallery-top", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      loop: true,
      loopedSlides: 4,
      autoplay: true
    });
    var galleryThumbs = new Swiper(".gallery-thumbs", {
      centeredSlides: true,
      slidesPerView: "auto",
      touchRatio: 0.2,
      slideToClickedSlide: true,
      loop: true,
      loopedSlides: 4,
      autoplay: true,
      grabCursor: true
    });
    galleryTop.controller.control = galleryThumbs;
    galleryThumbs.controller.control = galleryTop;
    // lasalle swiper
    // var tl = new TimelineMax();
    // tl.set([this.rightBtn, this.leftBtn], { autoAlpha: 0 });
    // tl.fromTo(
    //   this.mainBg.nativeElement,
    //   2,
    //   { scale: 2 },
    //   { scale: 1, ease: Power4.easeInOut },
    //   0
    // )
    //   .fromTo(
    //     this.lsLogo.nativeElement,
    //     2,
    //     { scale: 3, autoAlpha: 0 },
    //     { scale: 1, autoAlpha: 1, ease: Power4.easeInOut },
    //     0
    //   )
    //   .fromTo(
    //     this.coachSportif.nativeElement,
    //     2,
    //     { scale: 3, autoAlpha: 0 },
    //     { scale: 1, autoAlpha: 1, ease: Power4.easeInOut },
    //     0
    //   )
    //   .add("logoIn")
    //   .fromTo(
    //     this.rightBtn.nativeElement,
    //     0.5,
    //     { xPercent: 100, autoAlpha: 0 },
    //     { xPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
    //     "logoIn-=0.5"
    //   )
    //   .fromTo(
    //     this.leftBtn.nativeElement,
    //     0.5,
    //     { xPercent: -100, autoAlpha: 0 },
    //     { xPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
    //     "logoIn-=0.5"
    //   )
    //   .add("btnsIn")
    //   .to(
    //     this.mainBg.nativeElement,
    //     0.5,
    //     { yPercent: -5, ease: Power4.easeInOut },
    //     "btnsIn"
    //   )
    //   .fromTo(
    //     this.lsLogo.nativeElement,
    //     0.5,
    //     { yPercent: 15 },
    //     { yPercent: -5, ease: Power4.easeInOut },
    //     "btnsIn"
    //   )
    //   .fromTo(
    //     this.coachSportif.nativeElement,
    //     0.5,
    //     { yPercent: 15 },
    //     { yPercent: -5, ease: Power4.easeInOut },
    //     "btnsIn"
    //   )
    //   .fromTo(
    //     this.rightBtn.nativeElement,
    //     0.5,
    //     { yPercent: 15 },
    //     { yPercent: -5, ease: Power4.easeInOut },
    //     "btnsIn"
    //   )
    //   .fromTo(
    //     this.leftBtn.nativeElement,
    //     0.5,
    //     { yPercent: 15 },
    //     { yPercent: -5, ease: Power4.easeInOut },
    //     "btnsIn"
    //   )
    //   .fromTo(
    //     this.socialIcons.nativeElement,
    //     0.9,
    //     { y: 20, autoAlpha: 0 },
    //     { y: -30, autoAlpha: 1, ease: Power4.easeInOut },
    //     "btnsIn-=0.8"
    //   );
  }

  onTabChange(event) {
    if (event.nextId == "ngb-tab-0") {
      this.nosServices = "/assets/images/cours_particuliers.jpg";
    } else if (event.nextId == "ngb-tab-1") {
      this.nosServices = "/assets/images/cours_bootcamps.jpg";
    } else {
      this.nosServices = "/assets/images/services-3.jpg";
    }
  }
  onSubmit(form) {
    this.store.dispatch(
      new fromAuth.loginRequest({ ...form.value, role: "user" })
    );
  }
  togglePlanning() {
    var tl = new TimelineMax();

    tl.to(this.bootcampCalendar.nativeElement, 0.5, { autoAlpha: 1 });
  }
  memberLogin() {
    this.tlMember = new TimelineMax();
    this.tlMember
      .to(this.coachSportif.nativeElement, 0.5, {
        autoAlpha: 0,

        ease: Power4.easeOut
      })
      .fromTo(
        this.rightBtn.nativeElement,
        0.5,
        { xPercent: 0, autoAlpha: 1 },
        {
          xPercent: 100,
          autoAlpha: 0,
          padding: 0,
          yoyo: true,
          ease: Power4.easeInOut
        },
        0
      )
      .fromTo(
        this.leftBtn.nativeElement,
        0.5,
        { xPercent: 0, autoAlpha: 1 },
        {
          xPercent: -100,
          autoAlpha: 0,
          padding: 0,

          yoyo: true,
          ease: Power4.easeInOut
        },
        0
      )
      .to(this.lsLogo.nativeElement, 0.8, {
        yPercent: "-50",
        cursor: "pointer"
      })
      .add("logoUp")
      .fromTo(
        this.loginForm.nativeElement,
        1,
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: Power4.easeInOut },
        0.5
      )
      .to(
        this.loginTitle.nativeElement,
        0.5,
        { autoAlpha: 1, y: "-30", ease: Linear.None },
        "logoUp-=0.6"
      )
      .to(
        this.retourPageHome.nativeElement,
        0.5,
        { autoAlpha: 1, ease: Linear.None },
        "logoUp-=0.6"
      )
      .add("headUp")
      .to(
        this.form.nativeElement,
        0.5,
        { autoAlpha: 1, y: "-30", ease: Linear.None },
        "headUp-=0.4"
      );
  }

  accesVisiteurs() {
    var galleryTopHome = new Swiper(".gallery-top-home", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      loop: true,
      loopedSlides: 4
    });
    setTimeout(() => {
      var galleryThumbsHome = new Swiper(".gallery-thumbs-home", {
        spaceBetween: 0,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
        loop: true,
        loopedSlides: 4,
        autoplay: true,
        grabCursor: true
      });
      galleryTopHome.controller.control = galleryThumbsHome;
      galleryThumbsHome.controller.control = galleryTopHome;
    }, 3000);

    this.openSalle = true;
    document.querySelector(".step:first-child").classList.add("active");
    var boxContent = document.getElementsByClassName("box-content"),
      step = document.querySelector(".step"),
      firstImg = document.querySelector(".first-img"),
      mainButtons = this.elementRef.nativeElement.querySelectorAll(
        ".main-buttons"
      ),
      devenir = this.elementRef.nativeElement.querySelectorAll(
        ".main-buttons .devenir"
      ),
      inscription = this.elementRef.nativeElement.querySelectorAll(
        ".main-buttons .inscription"
      ),
      galleryThumbsHomeIn = this.elementRef.nativeElement.querySelectorAll(
        ".gallery-thumbs-home"
      ),
      galleryHome = this.elementRef.nativeElement.querySelectorAll(
        ".gallery-top-home"
      ),
      bgsOverlay = this.elementRef.nativeElement.querySelectorAll(
        ".bgs-overlay"
      ),
      menuLink = document.getElementsByClassName("nav-link");

    this.tlm = new TimelineMax();
    this.tlm
      .fromTo(
        this.rightBtn.nativeElement,
        1,
        { xPercent: 0, autoAlpha: 1 },
        { xPercent: 100, autoAlpha: 0, ease: Power4.easeOut },
        0
      )
      .fromTo(
        this.leftBtn.nativeElement,
        1,
        { xPercent: 0, autoAlpha: 1 },
        { xPercent: -100, autoAlpha: 0, ease: Power4.easeOut },
        0
      )
      .add("buttonsOut")

      .fromTo(
        this.lsLogo.nativeElement,
        2,
        { scale: 1, autoAlpha: 1 },
        { scale: 0, autoAlpha: 0, ease: Linear.easeInOut },
        1
      )
      .fromTo(
        this.coachSportif.nativeElement,
        1,
        { scale: 1, autoAlpha: 1 },
        { scale: 3, autoAlpha: 0, ease: Power4.easeInOut },
        "buttonsOut"
      )
      .fromTo(
        this.socialIcons.nativeElement,
        0.9,
        { yPercent: -20, autoAlpha: 1 },
        { yPercent: 20, autoAlpha: 0, ease: Power4.easeInOut },
        "buttonsOut"
      )

      .fromTo(
        galleryHome,
        1,
        { scale: 3 },
        {
          scale: 1,
          ease: ExpoScaleEase.config(1, 3, Power4.easeInOut)
        },
        "buttonsOut"
      )
      .fromTo(
        bgsOverlay,
        0.5,
        { background: "rgba(0, 0, 0, 0.88)" },
        { background: "rgba(0, 0, 0, 0.60)", ease: Power4.easeInOut },
        0.6
      )
      .add("bg-open")

      .fromTo(
        this.box.nativeElement,
        0.5,
        { autoAlpha: 0, yPercent: "100" },
        { yPercent: "0", autoAlpha: 1 },

        "bg-open-=0.8"
      )
      .to(this.mainMenu.nativeElement, 0.5, { width: "230px" }, "btnsOut-=0.8")
      .to(this.logo.nativeElement, 0.5, { yPercent: "-20", autoAlpha: 0 }, 0)
      .staggerFromTo(
        menuLink,
        0.5,
        { autoAlpha: 0, x: "-=10" },
        { autoAlpha: 1, x: "0", ease: Power4.easeInOut },
        0.09,
        "btnsOut-=0.8"
      )
      .add("linksIn")
      .to(this.mainFooter.nativeElement, 0.5, { autoAlpha: 1 }, "linksIn-=1")
      .to(this.humberger.nativeElement, 0.5, { autoAlpha: 1 }, "linksIn-=1")
      .to(this.pageInfo.nativeElement, 0.5, { autoAlpha: 0 })
      .to(boxContent, 0.1, { autoAlpha: 1 }, 0)
      .to(step, 0.1, { autoAlpha: 1 }, 0)

      .add("boxUp")
      .fromTo(
        this.boxContentTitle.nativeElement,
        1,
        { autoAlpha: 0, yPercent: "-20" },
        { autoAlpha: 1, yPercent: "0", ease: Power4.easeInOut },
        "btnsOut-=0.8"
      )
      .fromTo(
        this.boxDescription.nativeElement,
        1,
        { autoAlpha: 0, yPercent: "20" },
        { autoAlpha: 1, yPercent: "0", ease: Power4.easeInOut },
        "btnsOut-=0.8"
      )
      .fromTo(
        mainButtons,
        0.5,
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: Power4.easeInOut },
        "btnsOut-=0.8"
      )
      .add("mainBtnsIn")
      .to(
        devenir,
        0.5,

        { "background-position": "left bottom", ease: Power4.easeInOut },
        "btnsOut-=0.4"
      )
      .to(
        inscription,
        0.5,

        { "background-position": "left bottom", ease: Power4.easeInOut },
        "btnsOut-=0.4"
      )
      .add("btnss")
      .to(firstImg, 0.8, {
        className: "+=clipin",
        autoAlpha: 1,
        ease: Power4.easeInOut
      })
      .fromTo(
        galleryThumbsHomeIn,
        1,
        { yPercent: 50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
        1.8
      );
  }

  devenirMember() {
    let tl = new TimelineMax();
    tl.to(this.blackPopup.nativeElement, 0.5, {
      autoAlpha: 1,
      ease: Elastic.easeInOut
    });
    this.isBecomeMember = true;
  }
  closeSidebar() {
    let tl = new TimelineMax();
    // tl.to(this.blackPopup.nativeElement, 0.5, {
    //   autoAlpha: 0,
    //   ease: Elastic.easeInOut
    // });
    // this.isBecomeMember;
    this.popupSide = false;
    tl.to(this.bootcampSide.nativeElement, 0.5, {
      marginLeft: -491,
      className: "-=active",
      ease: Power4.easeInOut
    });
    this.store.dispatch(
      new fromUI.changeReserveBootcampStepVisitor({
        reserveBootcampStepVisitor: "themeInfos"
      })
    );
  }

  retourPopup() {
    let tl = new TimelineMax();
    tl.to(this.blackPopup.nativeElement, 0.5, {
      autoAlpha: 0,
      ease: Elastic.easeInOut
    });
    this.isBecomeMember;
  }
  bootcamp() {
    let tl = new TimelineMax();
    tl.to(this.blackPopup.nativeElement, 0.5, {
      autoAlpha: 1,
      ease: Elastic.easeInOut
    });
    this.isBecomeMember = false;
  }

  // Go To Next Slide
  goToNextSlide(slideOut, slideIn) {
    this.tlx = new TimelineMax();
    var slideOutH1 = slideOut.querySelectorAll(".title"),
      slideOutP = slideOut.querySelectorAll(".description"),
      slideInH1 = slideIn.querySelectorAll(".title"),
      slideInP = slideIn.querySelectorAll(".description"),
      slideInBox = slideIn.querySelectorAll(".box"),
      firstImgOut = slideOut.querySelectorAll(".first-img"),
      firstImgIn = slideIn.querySelectorAll(".first-img"),
      tabsetOut = slideOut.querySelectorAll(".tabset"),
      tabsetIn = slideIn.querySelectorAll(".tabset"),
      // Coch ino animation
      coachNameIn = slideIn.querySelectorAll(".coach-info h4"),
      coachNameOut = slideOut.querySelectorAll(".coach-info h4"),
      coachJobIn = slideIn.querySelectorAll(".coach-info span"),
      coachJobOut = slideOut.querySelectorAll(".coach-info span"),
      coachInfoIn = slideIn.querySelectorAll(".coach-info p"),
      coachInfoOut = slideOut.querySelectorAll(".coach-info p"),
      coachOverIn = slideIn.querySelectorAll(".coach-over"),
      coachOverOut = slideOut.querySelectorAll(".coach-over"),
      // Swipe animation
      galleryThumbsIn = slideIn.querySelectorAll(".gallery-thumbs"),
      galleryThumbsOut = slideOut.querySelectorAll(".gallery-thumbs"),
      galleryThumbsHomeIn = slideIn.querySelectorAll(".gallery-thumbs-home"),
      galleryThumbsHomeOut = slideOut.querySelectorAll(".gallery-thumbs-home"),
      // Swipe animation
      salleInfoIn = slideIn.querySelectorAll(".salle-info"),
      salleInfoOut = slideOut.querySelectorAll(".salle-info");

    this.tlx
      .set(slideOut, { className: "-=active" })
      .set(slideIn, { autoAlpha: 1, className: "+=active" })

      .fromTo(
        slideOutH1,
        0.3,
        { autoAlpha: 1, yPercent: "0" },
        { autoAlpha: 0, yPercent: "20", ease: Power4.easeInOut },
        0
      )
      .fromTo(
        slideOutP,
        0.3,
        { autoAlpha: 1, yPercent: "0" },
        { autoAlpha: 0, yPercent: "20", ease: Power4.easeInOut },
        0
      )

      .to(slideOut, 0.2, { autoAlpha: 0, ease: Power3.easeInOut })
      .to(slideIn, 0.3, { ease: Power3.easeInOut })
      .to(slideInBox, 0.3, { ease: Power3.easeInOut, autoAlpha: 1 }, 0)
      .add("contentOut")
      .fromTo(
        slideInH1,
        0.5,
        { autoAlpha: 0, yPercent: "20" },
        { autoAlpha: 1, yPercent: "0", ease: Power4.easeInOut },
        0.5
      )
      .fromTo(
        slideInP,
        0.5,
        { autoAlpha: 0, yPercent: "20" },
        { autoAlpha: 1, yPercent: "0", ease: Power4.easeInOut },
        0.5
      )
      .add("contentIn")
      .to(
        firstImgOut,
        0.6,
        {
          autoAlpha: 0,
          className: "+=clipout",

          ease: Power4.easeInOut,
          onComplete: function() {
            //TweenMax($("img"),{clearProps:"all"});
            TweenMax.set(firstImgOut, { className: "-=clipout" });
          }
        },
        0
      )
      .to(
        firstImgIn,
        0.8,
        {
          autoAlpha: 1,
          className: "+=clipin",
          ease: Power4.easeInOut,
          onComplete: function() {
            //TweenMax($("img"),{clearProps:"all"});
            TweenMax.set(firstImgOut, { className: "-=clipin" });
          }
        },
        0.6
      )
      .fromTo(
        tabsetIn,
        1.5,
        { yPercent: 50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
        "contentIn-=0.2"
      )

      .fromTo(
        tabsetOut,
        1.5,
        { yPercent: 0, autoAlpha: 1 },
        { yPercent: 50, autoAlpha: 0, ease: Power4.easeInOut },
        "contentIn-=0.8"
      )

      .fromTo(
        coachNameIn,
        1.5,
        { yPercent: 50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
        "contentIn-=0.2"
      )
      .fromTo(
        coachNameOut,
        1.5,
        { yPercent: 0, autoAlpha: 1 },
        { yPercent: 50, autoAlpha: 0, ease: Power4.easeInOut },
        "contentIn-=0.8"
      )
      .fromTo(
        coachJobIn,
        1.5,
        { yPercent: 50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
        "contentIn-=0.2"
      )
      .fromTo(
        coachJobOut,
        1.5,
        { yPercent: 0, autoAlpha: 1 },
        { yPercent: 50, autoAlpha: 0, ease: Power4.easeInOut },
        "contentIn-=0.8"
      )
      .fromTo(
        coachInfoIn,
        1.5,
        { yPercent: 50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
        "contentIn-=0.2"
      )
      .fromTo(
        coachInfoOut,
        1.5,
        { yPercent: 0, autoAlpha: 1 },
        { yPercent: 50, autoAlpha: 0, ease: Power4.easeInOut },
        "contentIn-=0.8"
      )
      .fromTo(
        coachOverIn,
        1.5,
        { yPercent: 50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
        "contentIn-=0.2"
      )
      .fromTo(
        coachOverOut,
        1.5,
        { yPercent: 0, autoAlpha: 1 },
        { yPercent: 50, autoAlpha: 0, ease: Power4.easeInOut },
        "contentIn-=0.8"
      )
      .fromTo(
        galleryThumbsIn,
        1.5,
        { yPercent: 50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
        "contentIn-=0.2"
      )
      .fromTo(
        galleryThumbsOut,
        1.5,
        { yPercent: 0, autoAlpha: 1 },
        { yPercent: 50, autoAlpha: 0, ease: Power4.easeInOut },
        "contentIn-=0.8"
      )
      .fromTo(
        galleryThumbsHomeIn,
        1.5,
        { yPercent: 50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
        "contentIn-=0.2"
      )
      .fromTo(
        galleryThumbsHomeOut,
        1.5,
        { yPercent: 0, autoAlpha: 1 },
        { yPercent: 50, autoAlpha: 0, ease: Power4.easeInOut },
        "contentIn-=0.8"
      )
      .fromTo(
        salleInfoIn,
        1.5,
        { yPercent: 50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: Power4.easeInOut },
        "contentIn-=0.2"
      )
      .fromTo(
        salleInfoOut,
        1.5,
        { yPercent: 0, autoAlpha: 1 },
        { yPercent: 50, autoAlpha: 0, ease: Power4.easeInOut },
        "contentIn-=0.8"
      );
  }

  // add active class to menu
  navigation(el) {
    // document.querySelector(".nav-link.active").classList.remove("active");
    // document.querySelector(".nav-link").classList.add("active");
    var slideOut = this.elementRef.nativeElement.querySelector(".step.active"),
      slideIn = el;
    if (slideIn.classList.contains("active")) {
      return false;
    }
    this.goToNextSlide(slideOut, slideIn);
  }

  // Humberger Icon

  closeAnimation() {
    this.myVideo.nativeElement.style.opacity = 1;
    this.myVideo.nativeElement.play();
    this.openSalle = false;
    this.selectedIndex = 0;

    document.querySelector(".step.active").classList.remove("active");
    var boxContent = document.getElementsByClassName("box-content"),
      step = document.querySelectorAll(".step"),
      firstImg = document.querySelector(".first-img"),
      box = document.querySelectorAll(".box"),
      mainButtons = this.elementRef.nativeElement.querySelectorAll(
        ".main-buttons"
      ),
      logoUp = this.elementRef.nativeElement.querySelectorAll(".logo"),
      mainLogo = this.elementRef.nativeElement.querySelectorAll(".main-logo"),
      devenir = this.elementRef.nativeElement.querySelectorAll(
        ".main-buttons .devenir"
      ),
      inscription = this.elementRef.nativeElement.querySelectorAll(
        ".main-buttons .inscription"
      ),
      bgsOverlay = this.elementRef.nativeElement.querySelectorAll(
        ".bgs-overlay"
      ),
      menuLink = document.getElementsByClassName("nav-link");

    TweenMax.set(
      [
        this.pageContent.nativeElement,
        logoUp,
        mainLogo,
        this.coachSportif.nativeElement,
        this.rightBtn.nativeElement,
        this.leftBtn.nativeElement,
        this.socialIcons.nativeElement,
        this.box.nativeElement,
        this.mainMenu.nativeElement,
        menuLink,
        this.mainFooter.nativeElement,
        this.humberger.nativeElement,
        this.pageInfo.nativeElement,
        boxContent,
        step,
        box,
        bgsOverlay,
        this.boxContentTitle.nativeElement,
        this.boxDescription.nativeElement,
        mainButtons,
        devenir,
        inscription,
        firstImg
      ],
      { clearProps: "all" }
    );
  }

  closeMemberLogin() {
    this.tlMember.reverse();
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;

    this.menu
      .to(
        document.getElementById("burger1"),
        0.5,
        { rotation: 45, transformOrigin: "left 50%", ease: Power4.easeInOut },
        "cross"
      )
      .to(document.getElementById("burger2"), 0.5, { autoAlpha: 0 }, "cross")
      .to(
        document.getElementById("burger3"),
        0.5,
        { rotation: -45, transformOrigin: "left 50%", ease: Power4.easeInOut },
        "cross"
      );
    this.menuIn();
  }
  menuIn() {
    this.menu.reversed() ? this.menu.play() : this.menu.reverse();
  }

  popImg(event, popimgModal) {
    let imgLink = event.target.style.backgroundImage
      .replace(/^url\(['"]?/, "")
      .replace(/['"]?\)$/, "");
    this.swiepePop = imgLink;
    this.modalService.open(popimgModal, { centered: true, size: "lg" });
  }
}
