import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Injectable
} from "@angular/core";
import {
  DateFormatterParams,
  CalendarNativeDateFormatter,
  CalendarDateFormatter,
  CalendarEventTitleFormatter
} from "angular-calendar";
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { CustomEventTitleFormatter } from "./custom-event-title-formatter.provider";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { Http } from "@angular/http";
import { parseFromTimeZone } from "date-fns-timezone";
import { startOfWeek, lastDayOfWeek, addDays } from "date-fns";
import * as fromApp from "../../store/reducers";
import { Store } from "@ngrx/store";
import * as fromPlanning from "../../store/Planning";
import * as fromClients from "../../store/Clients";
import * as fromIndex from "../../../utils/main-const";

registerLocaleData(localeFr);
@Injectable()
export class CustomDateFormatter extends CalendarNativeDateFormatter {
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: "short" })
      .format(date)
      .substr(0, 3);
  }
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat("fr", {
      hour: "numeric",
      minute: "numeric"
    }).format(date);
  }
  public weekViewColumnSubHeader({
    date,
    locale
  }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {
      day: "2-digit",
      month: "short"
    })
      .format(date)
      .substr(0, 6);
  }
}

@Component({
  selector: "app-planning",
  templateUrl: "./planning.component.html",
  styleUrls: ["./planning.component.scss"],
  animations: [
    trigger("paymentPropertySelect", [
      state(
        "an-off, void",
        style({
          // overflow: "visible",
          height: AUTO_STYLE
        })
      ),
      state(
        "an-animate",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      transition("an-off <=> an-animate", [animate("400ms ease-in-out")])
    ])
  ],
  providers: [
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ]
})
export class PlanningComponent implements OnInit {
  @Input() userType: string;
  @Input() adminPlanning: boolean;
  @Input() events;
  @Input() type;
  @Input() coachs;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  @Output() bootcampClick: EventEmitter<any> = new EventEmitter();
  @Output() coursParticularClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteSlot: EventEmitter<any> = new EventEmitter();
  @Output() clickChangeWeek: EventEmitter<any> = new EventEmitter()

  public french: any;
  selectedCoach;
  public selectProperty: string = "an-animate";
  startBefore = startOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }).toISOString()
  endBefore = addDays(lastDayOfWeek(parseFromTimeZone(new Date().toString(), { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }), 1).toISOString()
  planningMode;
  freeBootcamp;
  locale: string = "fr";
  width: number;
  viewDate: Date = new Date();
  view: string = "week";
  bootcampId;
  particularCourseId;
  constructor(
    public http: Http,
    private store: Store<{ auth: fromApp.AppState }>
  ) { }

  ngOnInit() {
    this.bootcampId = fromIndex.bootcampId;
    this.particularCourseId = fromIndex.particularCourse
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("planning").subscribe(val => {
      // this.events = val.planningEvents;
      this.planningMode = val.planningMode;
      this.freeBootcamp = val.freeBootcamp;
    });
    if (this.adminPlanning) {
      this.selectedCoach = this.coachs[0];
      this.store.dispatch(
        new fromPlanning.selectcoach({ selectedCoach: this.coachs[0].id })
      )
    }
  }
  toggleSelectProperty() {
    this.selectProperty =
      this.selectProperty === "an-off" ? "an-animate" : "an-off";
  }
  onClickOutside() {
    this.selectProperty = "an-animate";
  }
  adminPlanningChangeWeek(viewDate) {
    this.endBefore = addDays(lastDayOfWeek(parseFromTimeZone(viewDate, { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }), 1).toISOString()
    this.startBefore = startOfWeek(parseFromTimeZone(viewDate, { timeZone: "Europe/Paris" }), { weekStartsOn: 1 }).toISOString()

    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        coachId: this.selectedCoach.id,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
    this.store.dispatch(new fromPlanning.changeStartEndDates({
      startBefore: this.startBefore,
      endBefore: this.endBefore
    }))
  }
  selectCoach(coach, event) {
    this.selectedCoach = coach;
    this.store.dispatch(
      new fromPlanning.selectcoach({ selectedCoach: coach.id })
    )
    this.store.dispatch(
      new fromPlanning.getSlotsListRequest({
        coachId: coach.id,
        startBefore: this.startBefore,
        endBefore: this.endBefore
      })
    );
  }
  clickButton() {
    this.buttonClick.emit();
  }
  clickBootcamp(data) {
    this.bootcampClick.emit(data);
  }
  clickCoursParticular(data) {
    this.coursParticularClick.emit(data);
  }
  clickDeleteSlot(data) {
    this.deleteSlot.emit(data);
  }
  changeWeek(event) {
    let weekDates = {
      startBefore: startOfWeek(event, { weekStartsOn: 1 }),
      endBefore: addDays(lastDayOfWeek(event, { weekStartsOn: 1 }), 1)
    }
    this.clickChangeWeek.emit(weekDates)
  }
}
