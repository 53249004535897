import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { Http } from "@angular/http";

import { Store } from "@ngrx/store";
import * as fromApp from "../../store/reducers";
import * as fromAuth from "../../store/Auth";
import * as fromIndex from "../../../utils/main-const";

@Component({
  selector: "app-general-bar",
  templateUrl: "./general-bar.component.html",
  styleUrls: ["./general-bar.component.scss"],
  animations: [
    trigger("profilePopup", [
      state(
        "an-off, void",
        style({
          overflow: "visible",
          padding: "20px",
          height: AUTO_STYLE
        })
      ),
      state(
        "an-animate",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      transition("an-off <=> an-animate", [animate("400ms ease-in-out")])
    ]),
    trigger("notificationBottom", [
      state(
        "an-off, void",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      state(
        "an-animate",
        style({
          overflow: "visible",
          height: AUTO_STYLE
        })
      ),
      transition("an-off <=> an-animate", [animate("400ms ease-in-out")])
    ]),
    trigger("slideInOut", [
      state(
        "in",
        style({
          width: "280px"
          // transform: 'translate3d(0, 0, 0)'
        })
      ),
      state(
        "out",
        style({
          width: "0"
          // transform: 'translate3d(100%, 0, 0)'
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out"))
    ]),
    trigger("mobileHeaderNavRight", [
      state(
        "nav-off, void",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      state(
        "nav-on",
        style({
          height: AUTO_STYLE
        })
      ),
      transition("nav-off <=> nav-on", [animate("400ms ease-in-out")])
    ]),
    trigger("fadeInOutTranslate", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("400ms ease-in-out", style({ opacity: 1 }))
      ]),
      transition(":leave", [
        style({ transform: "translate(0)" }),
        animate("400ms ease-in-out", style({ opacity: 0 }))
      ])
    ]),
    trigger("mobileMenuTop", [
      state(
        "no-block, void",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      state(
        "yes-block",
        style({
          height: AUTO_STYLE
        })
      ),
      transition("no-block <=> yes-block", [animate("400ms ease-in-out")])
    ])
  ]
})
export class GeneralBarComponent implements OnInit, OnDestroy {
  public loggedInUser: any;
  public animateSidebar: string;
  public navType: string;
  public themeLayout: string;
  public verticalPlacement: string;
  public verticalLayout: string;
  public pcodedDeviceType: string;
  public verticalNavType: string;
  public verticalEffect: string;
  public vnavigationView: string;
  public freamType: string;
  public sidebarImg: string;
  public sidebarImgType: string;
  public layoutType: string;

  public headerTheme: string;
  public pcodedHeaderPosition: string;

  public liveNotification: string;
  public liveNotificationClass: string;

  public profileNotification: string;
  public profileNotificationClass: string;

  public chatSlideInOut: string;
  public innerChatSlideInOut: string;

  public searchWidth: number;
  public searchWidthString: string;

  public navRight: string;
  public windowWidth: number;
  public chatTopPosition: string;

  public toggleOn: boolean;
  public toggleIcon: string;
  public toggleIconNavigation: boolean;
  public navBarTheme: string;
  public activeItemTheme: string;
  public pcodedSidebarPosition: string;

  public headerFixedTop: string;

  public menuTitleTheme: string;
  public dropDownIcon: string;
  public subItemIcon: string;

  public configOpenRightBar: string;
  public displayBoxLayout: string;
  public isVerticalLayoutChecked: boolean;
  public isSidebarChecked: boolean;
  public isHeaderChecked: boolean;
  public headerFixedMargin: string;
  public sidebarFixedHeight: string;
  public sidebarFixedNavHeight: string;
  public itemBorderStyle: string;
  public subItemBorder: boolean;
  public itemBorder: boolean;

  public isCollapsedSideBar: string;
  public psDisabled: string;
  public perfectDisable: string;

  public config: any;
  public searchInterval: any;

  public display: string;
  // public topSwitchDisplay: string;

  @Input()
  menuItems: Array<object>;
  @Input()
  member: boolean;
  public profile: string = "an-animate";
  public french: any;

  scroll = (): void => {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 50) {
      if (this.isSidebarChecked === true) {
        this.pcodedSidebarPosition = "fixed";
      }
      if (this.pcodedDeviceType === "desktop") {
        this.headerFixedTop = "0";
      }
      this.sidebarFixedNavHeight = "100%";
    } else {
      if (this.pcodedDeviceType === "desktop") {
        this.headerFixedTop = "auto";
      }
      this.pcodedSidebarPosition = "absolute";
      this.sidebarFixedNavHeight = "";
    }
  };

  constructor(
    private store: Store<{ auth: fromApp.AppState }>,
    public http: Http
  ) {
    this.loggedInUser;
    this.animateSidebar = "";
    this.navType = "st2";
    this.themeLayout = "vertical";
    this.verticalPlacement = "left";
    this.verticalLayout = "wide";
    this.pcodedDeviceType = "desktop";
    this.verticalNavType = "expanded";
    this.verticalEffect = "shrink";
    this.vnavigationView = "view1";
    this.freamType = "theme1";
    this.sidebarImg = "false";
    this.sidebarImgType = "img1";
    this.layoutType = "light"; // light(default) dark(dark)

    this.headerTheme = "theme1"; // theme1(default)
    this.pcodedHeaderPosition = "fixed";

    this.headerFixedTop = "auto";

    this.liveNotification = "an-off";
    this.profileNotification = "an-off";

    this.chatSlideInOut = "out";
    this.innerChatSlideInOut = "out";

    this.searchWidth = 0;

    this.navRight = "nav-on";

    this.toggleOn = true;
    this.toggleIcon = "icon-toggle-right";
    this.toggleIconNavigation = true;
    this.navBarTheme = "themelight1"; // themelight1(default) theme1(dark)
    this.activeItemTheme = "theme1";
    this.pcodedSidebarPosition = "fixed";
    this.menuTitleTheme = "theme1"; // theme1(default) theme10(dark)
    this.dropDownIcon = "style1";
    this.subItemIcon = "style1";

    this.displayBoxLayout = "d-none";
    this.isVerticalLayoutChecked = false;
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;
    this.headerFixedMargin = "75px"; // 50px
    this.sidebarFixedHeight = "calc(100vh - 190px)"; // calc(100vh - 190px)
    this.itemBorderStyle = "none";
    this.subItemBorder = true;
    this.itemBorder = true;

    this.isCollapsedSideBar = "no-block";

    this.perfectDisable = "";

    this.windowWidth = window.innerWidth;
    // this.topSwitchDisplay = "none";
    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);

    // dark theme
    /*this.setLayoutType('dark');*/

    // light-dark
    /*this.setLayoutType('dark');
    this.setNavBarTheme('themelight1');*/

    // dark-light theme
    /*this.setNavBarTheme('theme1');*/

    // box layout
    /*this.setHeaderPosition();
    this.setSidebarPosition();
    this.setVerticalLayout();*/
  }

  ngOnInit() {
    this.setBackgroundPattern("theme1");
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("auth").subscribe(val => (this.loggedInUser = val));
  }
  getParticularSessionCredit(credits) {
    return credits.filter(
      credit => credit.sessionTypeId == fromIndex.particularCourse
    );
  }
  toggleProfile() {
    this.profile = this.profile === "an-off" ? "an-animate" : "an-off";
  }
  onClickedOutside() {
    this.profile = "an-animate";
  }
  logout() {
    this.store.dispatch(
      new fromAuth.Logout({ role: this.loggedInUser.loggedInUser.role })
    );
  }
  onClickedOutsideSidebar(e: Event) {}
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    let reSizeFlag = true;
    if (
      this.pcodedDeviceType === "tablet" &&
      this.windowWidth >= 768 &&
      this.windowWidth <= 1024
    ) {
      reSizeFlag = false;
    } else if (this.pcodedDeviceType === "phone" && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setHeaderAttributes(windowWidth) {
    if (windowWidth <= 992) {
      // this.navRight = "nav-off";
      // this.topSwitchDisplay = "inherit";
    } else {
      // this.navRight = "nav-on";
      // this.topSwitchDisplay = "none";
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.pcodedDeviceType = "tablet";
      // this.verticalNavType = "offcanvas";
      // this.verticalEffect = "overlay";
      this.verticalNavType = "collapsed";
      this.verticalEffect = "shrink";
      this.display = "none";
      this.toggleIcon = "icon-toggle-left";
      // this.headerFixedTop = "80px";
    } else if (windowWidth < 768) {
      this.pcodedDeviceType = "phone";
      // this.verticalNavType = "offcanvas";
      // this.verticalEffect = "overlay";
      this.verticalNavType = "collapsed";
      this.verticalEffect = "shrink";
      this.display = "none";
      this.toggleIcon = "icon-toggle-left";
      // this.headerFixedTop = "80px";
    } else {
      this.pcodedDeviceType = "desktop";
      this.verticalNavType = "expanded";
      this.verticalEffect = "shrink";
      this.toggleIcon = "icon-toggle-right";
      this.display = "";
    }

    /*else if (windowWidth >= 1024 && windowWidth < 1366) {
      this.pcodedDeviceType = 'desktop';
      this.verticalNavType = 'collapsed';
      this.verticalEffect = 'shrink';
      this.toggleIcon = 'icon-toggle-left';
      this.perfectDisable = 'disabled';
      this.sidebarFixedHeight = '100%';
    }*/
  }

  toggleHeaderNavRight() {
    this.navRight = this.navRight === "nav-on" ? "nav-off" : "nav-on";
    this.chatTopPosition = this.chatTopPosition === "nav-on" ? "112px" : "";
    if (this.navRight === "nav-off" && this.innerChatSlideInOut === "in") {
      this.toggleInnerChat();
    }
    if (this.navRight === "nav-off" && this.chatSlideInOut === "in") {
      this.toggleChat();
    }
  }

  toggleLiveNotification() {
    if (this.profileNotification === "an-animate") {
      this.toggleProfileNotification();
    }

    this.liveNotification =
      this.liveNotification === "an-off" ? "an-animate" : "an-off";
    this.liveNotificationClass =
      this.liveNotification === "an-animate" ? "show" : "";

    if (
      this.liveNotification === "an-animate" &&
      this.innerChatSlideInOut === "in"
    ) {
      this.toggleInnerChat();
    }
    if (
      this.liveNotification === "an-animate" &&
      this.chatSlideInOut === "in"
    ) {
      this.toggleChat();
    }
  }

  toggleProfileNotification() {
    if (this.liveNotification === "an-animate") {
      this.toggleLiveNotification();
    }

    this.profileNotification =
      this.profileNotification === "an-off" ? "an-animate" : "an-off";
    this.profileNotificationClass =
      this.profileNotification === "an-animate" ? "show" : "";

    if (
      this.profileNotification === "an-animate" &&
      this.innerChatSlideInOut === "in"
    ) {
      this.toggleInnerChat();
    }
    if (
      this.profileNotification === "an-animate" &&
      this.chatSlideInOut === "in"
    ) {
      this.toggleChat();
    }
  }

  notificationOutsideClick(ele: string) {
    if (ele === "live" && this.liveNotification === "an-animate") {
      this.toggleLiveNotification();
    } else if (ele === "profile" && this.profileNotification === "an-animate") {
      this.toggleProfileNotification();
    }
  }

  toggleChat() {
    if (this.innerChatSlideInOut === "in") {
      this.innerChatSlideInOut = "out";
    } else {
      this.chatSlideInOut = this.chatSlideInOut === "out" ? "in" : "out";
    }
  }

  toggleInnerChat() {
    this.innerChatSlideInOut =
      this.innerChatSlideInOut === "out" ? "in" : "out";
  }

  searchOn() {
    document.querySelector("#main-search").classList.add("open");
    this.searchInterval = setInterval(() => {
      if (this.searchWidth >= 200) {
        clearInterval(this.searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth + 15;
      this.searchWidthString = this.searchWidth + "px";
    }, 35);
  }

  searchOff() {
    this.searchInterval = setInterval(() => {
      if (this.searchWidth <= 0) {
        document.querySelector("#main-search").classList.remove("open");
        clearInterval(this.searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth - 15;
      this.searchWidthString = this.searchWidth + "px";
    }, 35);
  }

  ngOnDestroy() {
    if (this.searchInterval) {
      clearInterval(this.searchInterval);
    }
  }

  toggleOpened(e) {
    if (this.windowWidth <= 1024) {
      // this.topSwitchDisplay = "inherit";
      this.toggleOn =
        this.verticalNavType === "collapsed" ? true : this.toggleOn;
      if (this.navRight === "nav-on") {
        this.toggleHeaderNavRight();
      }
      this.verticalNavType =
        this.verticalNavType === "expanded" ? "collapsed" : "expanded";
      if (this.verticalNavType === "expanded") {
        this.perfectDisable = "disabled";
        this.sidebarFixedHeight = "100%";
        this.display = "";
      } else {
        this.perfectDisable = "";
        this.display = "none";
      }
    } else {
      this.verticalNavType =
        this.verticalNavType === "expanded" ? "collapsed" : "expanded";
      if (this.verticalNavType === "collapsed") {
        this.perfectDisable = "disabled";
        this.sidebarFixedHeight = "100%";
        this.display = "none";
        // this.topSwitchDisplay = "inherit";
      } else {
        this.perfectDisable = "";
        // this.topSwitchDisplay = "none";
        this.display = "";
      }
    }
    this.toggleIcon =
      this.verticalNavType === "expanded"
        ? "icon-toggle-right"
        : "icon-toggle-left";
    this.animateSidebar = "pcoded-toggle-animate";
    this.toggleIconNavigation =
      this.verticalNavType === "expanded" ? true : false;

    if (
      this.verticalNavType === "collapsed" &&
      this.isHeaderChecked === false
    ) {

      this.setSidebarPosition();
    }

    setTimeout(() => {
      this.animateSidebar = "";
    }, 500);
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === "open" ? "" : "open";
  }

  setNavBarTheme(theme: string) {
    if (theme === "themelight1") {
      this.navBarTheme = "themelight1";
      this.menuTitleTheme = "theme1";
      this.sidebarImg = "false";
    } else {
      this.menuTitleTheme = "theme9";
      this.navBarTheme = "theme1";
      this.sidebarImg = "false";
    }
  }

  setLayoutType(type: string) {
    if (type === "dark") {
      this.headerTheme = "theme1";
      this.navBarTheme = "theme1";
      this.activeItemTheme = "theme1";
      this.freamType = "theme1";
      document.querySelector("body").classList.add("dark");
      this.setBackgroundPattern("theme1");
      this.menuTitleTheme = "theme9";
      this.layoutType = type;
      this.sidebarImg = "false";
    } else if (type === "light") {
      this.headerTheme = "theme1";
      this.navBarTheme = "themelight1";
      this.menuTitleTheme = "theme1";
      this.activeItemTheme = "theme1";
      this.freamType = "theme1";
      document.querySelector("body").classList.remove("dark");
      this.setBackgroundPattern("theme1");
      this.layoutType = type;
      this.sidebarImg = "false";
    } else if (type === "img") {
      this.sidebarImg = "true";
      this.navBarTheme = "themelight1";
      this.menuTitleTheme = "theme1";
      this.freamType = "theme1";
      document.querySelector("body").classList.remove("dark");
      this.setBackgroundPattern("theme1");
      this.activeItemTheme = "theme1";
    }
  }

  setVerticalLayout() {
    this.isVerticalLayoutChecked = !this.isVerticalLayoutChecked;
    if (this.isVerticalLayoutChecked) {
      this.verticalLayout = "box";
      this.displayBoxLayout = "";
      this.pcodedHeaderPosition = "relative";
      this.pcodedSidebarPosition = "absolute";
      this.headerFixedMargin = "";
    } else {
      this.verticalLayout = "wide";
      this.displayBoxLayout = "d-none";
      this.pcodedHeaderPosition = "fixed";
      this.pcodedSidebarPosition = "fixed";
      this.headerFixedMargin = "75px";
    }
  }

  setBackgroundPattern(pattern: string) {
    document.querySelector("body").setAttribute("themebg-pattern", pattern);
    // this.menuTitleTheme = this.freamType = this.activeItemTheme = this.headerTheme = pattern;
  }

  setSidebarPosition() {
    if (this.verticalNavType !== "collapsed") {
      this.isSidebarChecked = !this.isSidebarChecked;
      this.pcodedSidebarPosition =
        this.isSidebarChecked === true ? "fixed" : "absolute";
      this.sidebarFixedHeight =
        this.isSidebarChecked === true ? "calc(100vh - 50px)" : "100%";
      if (this.isHeaderChecked === false) {
        window.addEventListener("scroll", this.scroll, true);
        window.scrollTo(0, 0);
      }
    }
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition =
      this.isHeaderChecked === true ? "fixed" : "relative";
    this.headerFixedMargin = this.isHeaderChecked === true ? "75px" : "";
    if (this.isHeaderChecked === false) {
      window.addEventListener("scroll", this.scroll, true);
      window.scrollTo(0, 0);
    } else {
      window.removeEventListener("scroll", this.scroll, true);
      if (this.pcodedDeviceType === "desktop") {
        this.headerFixedTop = "auto";
      }
      this.pcodedSidebarPosition = "fixed";
      if (this.verticalNavType !== "collapsed") {
        this.sidebarFixedHeight =
          this.isSidebarChecked === true
            ? "calc(100vh - 50px)"
            : "calc(100vh + 50px)";
      }
    }
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar =
      this.isCollapsedSideBar === "yes-block" ? "no-block" : "yes-block";
    if (this.verticalNavType !== "collapsed") {
      this.sidebarFixedHeight =
        this.isCollapsedSideBar === "yes-block"
          ? "calc(100vh - 50px)"
          : "calc(100vh - 50px)";
    }
  }

  hoverOutsideSidebar() {
    if (this.verticalNavType === "collapsed") {
      const mainEle = document.querySelectorAll(".pcoded-trigger");
      for (let i = 0; i < mainEle.length; i++) {
        mainEle[i].classList.remove("pcoded-trigger");
      }
    }
  }

  fireClick(e) {
    if (this.verticalNavType === "collapsed") {
      const parentEle = e.target.parentNode.parentNode;
      if (parentEle.classList.contains("pcoded-trigger")) {
        const subEle = parentEle.querySelectorAll(".pcoded-hasmenu");
        for (let i = 0; i < subEle.length; i++) {
          if (subEle[i].classList.contains("pcoded-trigger")) {
            subEle[i].classList.remove("pcoded-trigger");
          }
        }
      } else {
        e.target.click();
      }
    }
  }

  fireClickLeave(e) {
    if (this.verticalNavType === "collapsed") {
      const parentEle = <HTMLElement>e.target.parentNode.parentNode;
      const subEle = parentEle.querySelectorAll(".pcoded-hasmenu");
      for (let i = 0; i < subEle.length; i++) {
        if (subEle[i].classList.contains("pcoded-trigger")) {
          subEle[i].classList.remove("pcoded-trigger");
        }
      }
    }
  }
}
