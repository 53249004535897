import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Http } from "@angular/http";
import * as fromPayments from "../../store/Payments";
import { Store } from "@ngrx/store";
import * as fromIndex from "../../../utils/main-const";

@Component({
  selector: "app-assistant-payments",
  templateUrl: "./assistant-payments.component.html",
  styleUrls: ["./assistant-payments.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AssistantPaymentsComponent implements OnInit {
  public french: any;
  pageNumbers: number;
  currentPage: number = 1;
  fromIndex;
  payments;
  constructor(public http: Http, private store: Store<{}>) {
    this.store.dispatch(new fromPayments.GetPaymentsListRequest({ page: this.currentPage }));
  }

  ngOnInit() {
    this.fromIndex = {
      bootcampId: fromIndex.bootcampId,
      particularSession: fromIndex.particularCourse
    };
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    
    this.store.select("payments").subscribe(val => {
      this.payments = val.payments;
      this.pageNumbers = Math.ceil(val.payments.totalNbr / 20)
    });
  }
  nextPage() {
    this.currentPage += 1
    this.store.dispatch(new fromPayments.GetPaymentsListRequest({ page: this.currentPage }));
  }
  prevPage() {
    this.currentPage -= 1
    this.store.dispatch(new fromPayments.GetPaymentsListRequest({ page: this.currentPage }));
  }
}
