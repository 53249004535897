import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";
import { Http } from "@angular/http";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import * as datepickerFormat from "../../shared/datepicker-format";
import {
  NgbDatepickerI18n,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-visitor-form",
  templateUrl: "./visitor-form.component.html",
  styleUrls: ["./visitor-form.component.scss"],
  providers: [
    datepickerFormat.I18n,
    {
      provide: NgbDatepickerI18n,
      useClass: datepickerFormat.CustomDatepickerI18n
    },
    { provide: NgbDateParserFormatter, useClass: datepickerFormat.MyFormatter }
  ]
})
export class VisitorFormComponent implements OnInit {
  @Input() visitorInfos;
  @Input() becomeMember;
  @Output() onSubmitInformation: EventEmitter<any> = new EventEmitter();
  @Output() onClickBack: EventEmitter<any> = new EventEmitter();
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  cgv: boolean = false;
  french;
  clientsState;
  options = { types: ["address"], componentRestrictions: { country: "fr" } };
  address;
  zipCode;
  street;
  city;
  constructor(public http: Http, private store: Store<{}>) {}

  ngOnInit() {
    this.http.get(`assets/data/french.json`).subscribe(data => {
      this.french = data.json();
    });
    this.store.select("clients").subscribe(val => (this.clientsState = val));
  }
  handleAddressChange(address: Address) {
    let zipCode = address.address_components.filter(
      address => address.types[0] == "postal_code"
    );
    let streetNumber = address.address_components.filter(
      address => address.types[0] == "street_number"
    );
    let route = address.address_components.filter(
      address => address.types[0] == "route"
    );
    let city = address.address_components.filter(
      address => address.types[0] == "locality"
    );
    // this.address = address.formatted_address
    // this.zipCode = zipCode[0].long_name;
    // this.street = streetNumber[0].long_name + " " + route[0].long_name;
    // this.city = city[0].long_name

    this.zipCode =
      zipCode.length != 0 && zipCode[0].long_name ? zipCode[0].long_name : "";
    this.street =
      (streetNumber.length != 0 && streetNumber[0].long_name
        ? streetNumber[0].long_name
        : "") +
      " " +
      (route.length != 0 && route[0].long_name ? route[0].long_name : "");
    this.city = city.length != 0 && city[0].long_name ? city[0].long_name : "";
  }
  back() {
    this.onClickBack.emit();
  }
  onSubmit(f) {
    const data = Object.assign(
      {
        form: f,
        street: this.street !== undefined ? this.street : this.address
      },
      this.zipCode !== undefined && { zipCode: this.zipCode },
      this.city !== undefined && { city: this.city }
    );

    this.onSubmitInformation.emit(data);
  }
}
